import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import { Warning } from '@material-ui/icons';
import { useNetworks } from '../../../Contexts/Networks';
import { useAddChaincodeApiForm } from '../../../Hooks/addChaincodeApi';
import { useUpgradeChaincodeForm } from '../../../Hooks/UpgradeChaincode';
import GenericInput from '../../../AppComponents/Channel/GenericInput';
import { networkApi } from '../../../Common/axios';
import { OperationStateCard } from '../../../AppComponents/OperationStateCard';
import { useOperation } from '../../../Contexts/Operation';
import cancelWithDialog from '../../../utils/cancelRequestWithModal';
import { openDialog } from '../../../store/Dialog';
import { addOperation, removeOperation } from '../../../store/AppStatus';
import FileInput from '../../../AppComponents/FileInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRight: '1px solid #e0e0e0',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      height: 500,
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
  }),
);

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const AddChaincodeAPI = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [peerToEdit, setPeerToEdit] = useState<{
    orgName: string;
    peer: string;
  }>();

  const [isDefaultApiPort, setIsDefaultApiPort] = useState(true);
  const [isDefaultTLSPort, setIsDefaultTLSPort] = useState(true);
  const [isDefaultInterfacePort, setIsDefaultInterfacePort] = useState(true);
  const [shouldShowInterfacePort, setShouldShowInterfacePort] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [files, setFile] = useState<
    Record<string, Record<string, Record<string, Record<string, File | null>>>>
  >({});

  console.log('FILES: ', files);

  const { addCCAPIState } = useOperation();

  const { selectedNetwork, selectedChannel, fetchNetworkState } = useNetworks();

  useEffect(() => {
    if (selectedNetwork) {
      fetchNetworkState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNetwork]);

  const {
    selectedPeers,
    addPeer,
    removePeer,
    setSelectedPeers,
    setCACredentials,
  } = useAddChaincodeApiForm();

  const isFileType = useMemo(() => {
    if (!peerToEdit || !selectedNetwork) return false;

    const currentCCAPI = selectedPeers[peerToEdit.orgName].ccApis.find(
      (host) => host.host === selectedNetwork?.peers[peerToEdit.peer]?.host,
    );
    if (!currentCCAPI) return false;

    const type = selectedNetwork.chaincodes[currentCCAPI.chaincodeName]?.ccType;

    console.log('TYPE: ', type);

    return type === 'form';
  }, [peerToEdit, selectedNetwork, selectedPeers]);

  const peerIsChecked = useCallback(
    (peer: string, orgName: string) => {
      if (!selectedNetwork?.peers?.[peer]) return false;

      const isChecked = Object.entries(
        selectedPeers,
      ).some(([hostName, hostArray]) =>
        hostArray?.ccApis?.find?.(
          (hostObj) =>
            selectedNetwork?.peers[peer]?.host === hostObj.host &&
            hostName === orgName,
        ),
      );

      return isChecked;
    },
    [selectedNetwork, selectedPeers],
  );

  const handleSelect = useCallback(
    (orgName, peer: string) => () => {
      console.log('select: ', peer, orgName);
      const host = selectedNetwork?.peers[peer]?.host || '';

      if (peerIsChecked(peer, orgName)) {
        removePeer(orgName, host);
      } else addPeer(orgName, host);
    },
    [addPeer, peerIsChecked, removePeer, selectedNetwork],
  );

  const handleChangePort = useCallback(
    (key: 'restPort' | 'TLSPort' | 'interfacePort') => (number: number) => {
      if (!peerToEdit) return;

      selectedPeers[peerToEdit.orgName].ccApis = selectedPeers[
        peerToEdit.orgName
      ].ccApis.map((host) => {
        if (host.host === selectedNetwork?.peers[peerToEdit.peer]?.host) {
          return {
            ...host,
            ...(key === 'interfacePort'
              ? {
                  goinitus: {
                    port: number,
                  },
                }
              : { [key]: Number(number) }),
          };
        }
        return host;
      });

      console.log('selectedPeers input: ', selectedPeers);

      setSelectedPeers({ ...selectedPeers });
    },
    [peerToEdit, selectedNetwork, selectedPeers, setSelectedPeers],
  );

  const isInvalid = useMemo(
    () =>
      Object.keys(selectedPeers).length === 0 ||
      Object.entries(selectedPeers).some(
        ([orgName, orgInfo]) =>
          orgInfo.ccApis.some((ccapi) => !ccapi.chaincodeName) ||
          !orgInfo.ca.caUser ||
          !orgInfo.ca.caPassword,
      ),
    [selectedPeers],
  );

  const onSubmit = useCallback(async () => {
    if (!selectedNetwork || !selectedChannel) return;

    if (isInvalid) {
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: 'Please fill in all the required fields',
        }),
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const formData = new FormData();

      const payload = {
        chaincodes: Object.entries(selectedPeers).reduce(
          (acc, [orgName, orgInfo]) => {
            orgInfo.ccApis.forEach((ccapi) => {
              acc[ccapi.chaincodeName] = {
                type: selectedNetwork?.chaincodes[ccapi.chaincodeName].ccType,
                tarName:
                  selectedNetwork?.chaincodes[ccapi.chaincodeName].tarName,
                ccBaseName:
                  selectedNetwork?.chaincodes[ccapi.chaincodeName].ccBaseName,
              };

              // acc[ccapi.chaincodeName] =
              //   selectedNetwork?.chaincodes[ccapi.chaincodeName];
              // delete acc[ccapi.chaincodeName].peers;

              if (
                files?.[orgName]?.[ccapi.host]?.[ccapi.chaincodeName]?.[
                  selectedChannel
                ]
              )
                formData.append(
                  'ccFiles',
                  files[orgName][ccapi.host][ccapi.chaincodeName][
                    selectedChannel
                  ] as Blob,
                );
            });

            return acc;
          },
          {} as Record<
            string,
            { type: string; tarName: string; ccBaseName: string }
          >,
        ),
        organizations: selectedPeers,
      };

      console.log('PAYLOAD: ', payload);

      formData.append('payload', JSON.stringify(payload));
      formData.append('networkName', selectedNetwork?.networkName);

      networkApi
        .post('/addCCApi', formData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(c, 'Add Chaincode API', hasDialog);
            };

            cancelRequest = withDialogCancel;
            dispatch(
              addOperation({
                title: 'Add Chaincode API',
                pathname: window.location.pathname,
                name: 'addccapi',
                cancel: withDialogCancel,
              }),
            );
          }),
        })
        .then(async () => {
          fetchNetworkState();

          dispatch(
            openDialog({
              title: 'Success',
              type: 'success',
              content: 'Chaincode API added successfully',
            }),
          );
          dispatch(removeOperation('addccapi', true));
        })
        .catch((error) => {
          console.log('addccapi error: ', error);
          dispatch(removeOperation('addccapi', false));
          // dispatch(
          //   openDialog({
          //     title: t('common.words.error'),
          //     type: 'error',
          //     content: error?.message,
          //   }),
          // );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } catch (error) {
      console.log('error: ', error);
      dispatch(removeOperation('addccapi', false));
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error?.message,
        }),
      );
    }
  }, [
    dispatch,
    fetchNetworkState,
    files,
    isInvalid,
    selectedChannel,
    selectedNetwork,
    selectedPeers,
    t,
  ]);

  const handleChangeCC = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!peerToEdit) return;

      selectedPeers[peerToEdit.orgName].ccApis = selectedPeers[
        peerToEdit.orgName
      ].ccApis.map((host) => {
        if (host.host === selectedNetwork?.peers[peerToEdit.peer]?.host) {
          return {
            ...host,
            chaincodeName: event?.target?.value,
            channelName: selectedChannel || '',
          };
        }
        return host;
      });

      setSelectedPeers({ ...selectedPeers });
    },
    [
      peerToEdit,
      selectedChannel,
      selectedNetwork,
      selectedPeers,
      setSelectedPeers,
    ],
  );

  if (!selectedChannel)
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography style={{ fontSize: '32px' }} variant="overline">
          Add Chaincode API
        </Typography>

        <Typography style={{ fontSize: '20px' }}>
          Select a Network and a Channel to start
        </Typography>
      </Box>
    );

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography style={{ fontSize: '32px' }} variant="overline">
        Add Chaincode API
      </Typography>

      <Grid container justify="center">
        <Grid item xs={3}>
          <List className={classes.root} subheader={<li />}>
            {selectedNetwork?.channels &&
              Object.entries(
                selectedNetwork?.channels?.[selectedChannel]?.peers,
              ).map(([orgName, peersInChannel]) => {
                return (
                  <li
                    key={`section-${orgName}`}
                    className={classes.listSection}
                  >
                    <ul className={classes.ul}>
                      <ListSubheader>{`${orgName}`}</ListSubheader>

                      {peersInChannel.map((peer, key) => {
                        return (
                          <ListItem
                            key={`item-${orgName}-${selectedNetwork?.peers[peer]?.host}`}
                            role={undefined}
                            dense
                          >
                            <Checkbox
                              onChange={handleSelect(orgName, peer)}
                              checked={peerIsChecked(peer, orgName)}
                            />

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              width="100%"
                              style={{ gap: '0.5rem' }}
                            >
                              {peer}
                              <ArrowForwardIosIcon
                                onClick={() => {
                                  setPeerToEdit({
                                    orgName,
                                    peer,
                                  });
                                  addPeer(
                                    orgName,
                                    selectedNetwork?.peers[peer]?.host,
                                  );
                                }}
                                style={{ cursor: 'pointer' }}
                              />
                            </Box>
                          </ListItem>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
          </List>
        </Grid>

        {selectedChannel && (
          <Grid item xs={3} className={classes.root}>
            <Box display="flex" flexDirection="column" alignItems="center">
              {peerToEdit?.peer ? (
                <>
                  <Typography>{peerToEdit?.peer}</Typography>

                  <Typography>
                    {selectedNetwork?.peers?.[peerToEdit?.peer]?.host}
                  </Typography>

                  <Box display="flex" alignItems="center">
                    <GenericInput
                      type="number"
                      value={
                        selectedPeers?.[peerToEdit?.orgName]?.ccApis?.find?.(
                          (host) =>
                            host.host ===
                            selectedNetwork?.peers?.[peerToEdit?.peer]?.host,
                        )?.restPort
                      }
                      style={{ maxWidth: '256px' }}
                      label="API Port"
                      onChange={({ target: { value } }) =>
                        handleChangePort('restPort')(Number(value))
                      }
                      disabled={isDefaultApiPort}
                    />
                    <Checkbox
                      onChange={(e) => {
                        handleChangePort('restPort')(80);
                        setIsDefaultApiPort(e.target.checked);
                      }}
                      checked={isDefaultApiPort}
                    />
                    <Typography>Default</Typography>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <GenericInput
                      type="number"
                      value={
                        selectedPeers?.[peerToEdit?.orgName]?.ccApis?.find?.(
                          (host) =>
                            host.host ===
                            selectedNetwork?.peers?.[peerToEdit?.peer]?.host,
                        )?.TLSPort
                      }
                      style={{ maxWidth: '256px' }}
                      label="TLS Port"
                      onChange={({ target: { value } }) =>
                        handleChangePort('TLSPort')(Number(value))
                      }
                      disabled={isDefaultTLSPort}
                    />
                    <Checkbox
                      onChange={(e) => {
                        handleChangePort('TLSPort')(443);
                        setIsDefaultTLSPort(e.target.checked);
                      }}
                      checked={isDefaultTLSPort}
                    />
                    <Typography>Default</Typography>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <Checkbox
                      onChange={(e) => {
                        setShouldShowInterfacePort(e.target.checked);
                        // Remove goinutus key from selectedPeers
                        if (!e.target.checked) {
                          delete selectedPeers?.[
                            peerToEdit?.orgName
                          ]?.ccApis?.find?.(
                            (host) =>
                              host.host ===
                              selectedNetwork?.peers?.[peerToEdit?.peer]?.host,
                          )?.goinitus;

                          setSelectedPeers({ ...selectedPeers });
                        } else {
                          const currentPeer = selectedPeers?.[
                            peerToEdit?.orgName
                          ]?.ccApis?.find?.(
                            (host) =>
                              host.host ===
                              selectedNetwork?.peers?.[peerToEdit?.peer]?.host,
                          );

                          if (currentPeer) {
                            currentPeer.goinitus = {
                              port: 8080,
                            };
                            setSelectedPeers({ ...selectedPeers });
                          }
                        }
                        // setSelectedPeers({
                        //   ...selectedPeers,
                        //   [peerToEdit.orgName]: {
                        //     ...selectedPeers[peerToEdit.orgName],
                        //     ccApis: selectedPeers[
                        //       peerToEdit.orgName
                        //     ].ccApis.map((host) => {
                        //       if (
                        //         host.host ===
                        //         selectedNetwork?.peers?.[peerToEdit?.peer]?.host
                        //       ) {
                        //         // eslint-disable-next-line no-param-reassign
                        //         delete host.goinutus;
                        //         // return {
                        //         //   ...host,
                        //         //   goinutusPort: undefined,
                        //         // };
                        //       }
                        //       return host;
                        //     }),
                        //   },
                        // });
                      }}
                      checked={shouldShowInterfacePort}
                    />
                    <Typography variant="overline">Interface </Typography>
                  </Box>

                  {shouldShowInterfacePort && (
                    <Box display="flex" alignItems="center">
                      <GenericInput
                        type="number"
                        value={
                          selectedPeers?.[peerToEdit?.orgName]?.ccApis?.find?.(
                            (host) =>
                              host.host ===
                              selectedNetwork?.peers?.[peerToEdit?.peer]?.host,
                          )?.goinitus?.port || ''
                        }
                        style={{ maxWidth: '256px' }}
                        label="Interface Port"
                        onChange={({ target: { value } }) =>
                          handleChangePort('interfacePort')(Number(value))
                        }
                        disabled={isDefaultInterfacePort}
                      />
                      <Checkbox
                        onChange={(e) => {
                          handleChangePort('interfacePort')(8080);
                          setIsDefaultInterfacePort(e.target.checked);
                        }}
                        checked={isDefaultInterfacePort}
                      />
                      <Typography>Default</Typography>
                    </Box>
                  )}
                </>
              ) : (
                <Typography align="center">Select a peer to edit</Typography>
              )}
            </Box>
          </Grid>
        )}

        <Grid
          item
          xs={3}
          style={{
            padding: '0 2.5rem',
          }}
        >
          {selectedChannel && (
            <Box
              display="flex"
              flexDirection="column"
              style={{
                gap: '1rem',
              }}
              // justifyContent="center"
              // alignItems="center"
            >
              <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  style={{
                    display: 'flex',
                    alignItems: 'end',
                    height: '24px',
                  }}
                >
                  Chaincode
                  {peerToEdit?.orgName &&
                    !selectedPeers?.[peerToEdit.orgName]?.ccApis?.find?.(
                      (peer) =>
                        peer.host ===
                        selectedNetwork?.peers?.[peerToEdit?.peer]?.host,
                    )?.chaincodeName && (
                      <Warning style={{ color: 'var(--warning)' }} />
                    )}
                </FormLabel>
                {peerToEdit?.peer && (
                  <RadioGroup
                    aria-label="chaincode"
                    name="chaincode"
                    value={
                      selectedPeers?.[peerToEdit?.orgName]?.ccApis?.find?.(
                        (peer) =>
                          peer.host ===
                          selectedNetwork?.peers?.[peerToEdit?.peer]?.host,
                      )?.chaincodeName || ''
                    }
                    onChange={handleChangeCC}
                  >
                    {selectedNetwork?.channels &&
                      selectedNetwork?.channels?.[
                        selectedChannel
                      ]?.chaincodes.map((chaincode) => {
                        console.log('chaincode: ', chaincode);
                        return (
                          <FormControlLabel
                            value={chaincode}
                            control={<Radio />}
                            label={chaincode}
                            //   checked={}
                          />
                        );
                      })}
                  </RadioGroup>
                )}
              </FormControl>

              {peerToEdit?.orgName && (
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    gap: '1rem',
                  }}
                >
                  <FormLabel
                    style={{
                      marginBottom: '0.25rem',
                      display: 'flex',
                      alignItems: 'end',
                      height: '24px',
                    }}
                  >
                    {peerToEdit.orgName} CA authentication{' '}
                    {(!selectedPeers[peerToEdit?.orgName]?.ca?.caUser ||
                      !selectedPeers[peerToEdit?.orgName]?.ca?.caPassword) && (
                      <Warning style={{ color: 'var(--warning)' }} />
                    )}
                  </FormLabel>
                  <GenericInput
                    value={selectedPeers[peerToEdit?.orgName]?.ca?.caUser || ''}
                    variant="standard"
                    disabled={isSubmitting}
                    onChange={(e) =>
                      setCACredentials(peerToEdit?.orgName, {
                        username: e.target.value,
                      })
                    }
                    label={<Trans>common.forms.CAUser</Trans>}
                    style={{
                      margin: '0',
                    }}
                  />
                  <GenericInput
                    type="password"
                    value={
                      selectedPeers[peerToEdit?.orgName]?.ca?.caPassword || ''
                    }
                    variant="standard"
                    disabled={isSubmitting}
                    onChange={(e) =>
                      setCACredentials(peerToEdit?.orgName, {
                        password: e.target.value,
                      })
                    }
                    label={<Trans>common.forms.CAPassword</Trans>}
                    style={{
                      margin: '0',
                    }}
                  />
                </Box>
              )}

              {isFileType && peerToEdit && selectedNetwork && (
                <>
                  <Typography
                    variant="overline"
                    style={{
                      marginTop: '1rem',
                    }}
                  >
                    File upload
                  </Typography>
                  <FileInput
                    file={
                      files?.[peerToEdit.orgName]?.[
                        selectedNetwork.peers[peerToEdit.peer].host
                      ]?.[
                        selectedPeers[peerToEdit.orgName].ccApis.find(
                          (host) =>
                            host.host ===
                            selectedNetwork?.peers[peerToEdit.peer]?.host,
                        )?.chaincodeName || 'chaincode'
                      ]?.[selectedChannel] || null
                    }
                    onChange={(filelist) => {
                      setFile((previousFiles) => ({
                        ...previousFiles,
                        [peerToEdit.orgName]: {
                          ...(previousFiles?.[peerToEdit.orgName] || {}),
                          [selectedNetwork.peers[peerToEdit.peer].host]: {
                            ...(previousFiles?.[peerToEdit.orgName]?.[
                              selectedNetwork.peers[peerToEdit.peer].host
                            ] || {}),
                            [selectedPeers?.[peerToEdit.orgName]?.ccApis?.find(
                              (host) =>
                                host.host ===
                                selectedNetwork?.peers[peerToEdit.peer]?.host,
                            )?.chaincodeName || 'chaincode']: {
                              ...(previousFiles?.[peerToEdit.orgName]?.[
                                selectedNetwork.peers[peerToEdit.peer].host
                              ]?.[
                                selectedPeers?.[
                                  peerToEdit.orgName
                                ]?.ccApis?.find(
                                  (host) =>
                                    host.host ===
                                    selectedNetwork?.peers[peerToEdit.peer]
                                      ?.host,
                                )?.chaincodeName || 'chaincode'
                              ] || {}),
                              [selectedChannel]: filelist?.[0] || null,
                            },
                          },
                        },
                      }));
                    }}
                    containerStyle={{
                      padding: 0,
                    }}
                  />
                </>
              )}
            </Box>
          )}
        </Grid>
      </Grid>

      {isSubmitting && (
        <Button
          color="secondary"
          variant="contained"
          style={{ width: '45%', margin: '0 auto', pointerEvents: 'all' }}
          onClick={() => cancelRequest()}
        >
          <Trans>button.cancel</Trans>
        </Button>
      )}

      <Button
        color="primary"
        variant="contained"
        className="deploy-start-btn"
        disabled={isSubmitting || !selectedChannel || isInvalid}
        style={{ width: '45%', margin: '1rem auto', pointerEvents: 'all' }}
        onClick={onSubmit}
      >
        <Trans>button.start</Trans>
      </Button>

      <OperationStateCard taskOperation={addCCAPIState} />
    </Box>
  );
};

export default AddChaincodeAPI;
