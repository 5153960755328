import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { clearDeployData } from '../store/DeployStart';
import { changeDeployStepOnTour } from '../store/InitialTour';
import { clearDeployNotifications } from '../store/DeployNotifications';

import { StoreState } from '../store/types';
import { ITemplate } from '../store/TemplateCC';
import { ElementType } from '../AppComponents/Endorsement/types';

export interface IChaincode {
  exclusive: boolean;
  chaincodeName: string;
  chaincodeFile: File | null;
}

export interface DeployContextData {
  // When we decide to use the advanced again this should be reviewed
  // json: string; // object stringified
  orgs: IOrg[];
  channels: Channel[];
  chaincodes: IChaincodes[];
  init: string;
  started: boolean;
  activeStep: number;
  channelName: string;
  chaincodeIndex: number;
  setChaincodeIndex: Dispatch<SetStateAction<number>>;
  networkName: string;
  networkType: string;
  grpcTimeout: number;
  fabricVersion: string;
  chaincode: IChaincode;
  activeCCOption: string;
  usingTemplate: boolean;
  cloudChaincode: string;
  chaincodeTimeout: number;
  prevActiveCCOption: string;
  template: ITemplate | null;
  usingCloudChaincode: boolean;
  endorsement: IEndorsement | null;
  customTimeoutModalOpened: boolean;
  endorsementGUI: ElementType[] | null;
  clearFormData(): void;
  updateCCTypeControlVars(option: string): void;
  // When we decide to use the advanced again this should be reviewed
  // setJson: Dispatch<SetStateAction<string>>;
  setOrgs: Dispatch<SetStateAction<IOrg[]>>;
  setChannels: Dispatch<SetStateAction<Channel[]>>;
  setInit: Dispatch<SetStateAction<string>>;
  setActiveCCOption(newValue: string): void;
  setStarted: Dispatch<SetStateAction<boolean>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  setChannelName: Dispatch<SetStateAction<string>>;
  setNetworkName: Dispatch<SetStateAction<string>>;
  setNetworkType: Dispatch<SetStateAction<string>>;
  setGrpcTimeout: Dispatch<SetStateAction<number>>;
  setChaincode: Dispatch<SetStateAction<IChaincode>>;
  setChaincodes: Dispatch<SetStateAction<IChaincodes[]>>;
  setFabricVersion: Dispatch<SetStateAction<string>>;
  setUsingTemplate: Dispatch<SetStateAction<boolean>>;
  setCloudChaincode: Dispatch<SetStateAction<string>>;
  setChaincodeTimeout: Dispatch<SetStateAction<number>>;
  setTemplate: Dispatch<SetStateAction<ITemplate | null>>;
  setUsingCloudChaincode: Dispatch<SetStateAction<boolean>>;
  setEndorsement: Dispatch<SetStateAction<IEndorsement | null>>;
  setCustomTimeoutModalOpened: Dispatch<SetStateAction<boolean>>;
  setEndorsementGUI: Dispatch<SetStateAction<ElementType[] | null>>;
  isValid: boolean;
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const DeployContext = createContext<DeployContextData>({} as DeployContextData);

const DeployProvider: React.FC = ({ children }) => {
  // When we decide to use the advanced again this should be reviewed
  // const [json, setJson] = useState('');
  const [init, setInit] = useState('');
  const [started, setStarted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [grpcTimeout, setGrpcTimeout] = useState(0);
  const [channelName, setChannelName] = useState('');
  const [chaincodeIndex, setChaincodeIndex] = useState(0);
  const [networkName, setNetworkName] = useState('');
  const [cloudChaincode, setCloudChaincode] = useState('');
  const [usingTemplate, setUsingTemplate] = useState(true);
  const [fabricVersion, setFabricVersion] = useState('1.4');
  const [chaincodeTimeout, setChaincodeTimeout] = useState(0);
  const [networkType, setNetworkType] = useState('regulator');
  const [prevActiveCCOption, setPrevActiveCCOption] = useState('');
  const [activeCCOption, setActiveCCOption] = useState('template');
  const [template, setTemplate] = useState<ITemplate | null>(null);
  const [usingCloudChaincode, setUsingCloudChaincode] = useState(false);
  const [endorsement, setEndorsement] = useState<IEndorsement | null>(null);
  const [customTimeoutModalOpened, setCustomTimeoutModalOpened] = useState(
    false,
  );
  const [endorsementGUI, setEndorsementGUI] = useState<ElementType[] | null>(
    null,
  );

  const [isValid, setIsValid] = useState(true);
  const defaultOrgsValue = [
    {
      id: 0,
      orgDomainName: '',
      orgName: '',
      authServer: {
        authMethod: 'none',
        oauthURL: '',
        oauthOpenID: '',
        oauthClientID: '',
        oauthClientSecret: '',
      },
      peers: [],
      caIP: '',
      ccapi: [],
      user: '',
      passwd: '',
      confirmPasswd: '',
      caPort: undefined,
      caOperationPort: undefined,
    },
  ];
  const [orgs, setOrgs] = useState<IOrg[]>(defaultOrgsValue);

  const [channels, setChannels] = useState<Channel[]>([
    {
      channelName: '',
      chaincodes: [
        {
          id: '01',
          chaincodeFile: null,
          chaincodeName: '',
          tarName: '',
          ccType: 'template',
        },
      ],
      peers: {},
    },
  ]);

  const [chaincodes, setChaincodes] = useState<IChaincodes[]>([]);

  const [chaincode, setChaincode] = useState<IChaincode>({
    chaincodeFile: null,
    chaincodeName: '',
    exclusive: false,
  });

  const dispatch = useDispatch();

  const { mustClearForm }: { mustClearForm: boolean } = useSelector(
    (state: StoreState) => state.deployStartState,
  );

  const {
    setDeployTemplateTo,
    mustChangeDeployStepTo,
    setDeployActiveCCOptTo,
  }: {
    setDeployActiveCCOptTo: string;
    mustChangeDeployStepTo: number | null;
    setDeployTemplateTo: ITemplate | null;
  } = useSelector((state: StoreState) => state.initialTourState);

  const clearFormData = useCallback(() => {
    // When we decide to use the advanced again this should be reviewed
    // setJson('');
    setInit('');
    setStarted(false);
    setOrgs(defaultOrgsValue);
    setStarted(false);
    setActiveStep(0);
    setChannelName('');
    setNetworkName('');
    setTemplate(null);
    setGrpcTimeout(0);
    setEndorsement(null);
    setCloudChaincode('');
    setChannels([
      {
        channelName: '',
        chaincodes: [
          {
            id: '01',
            chaincodeFile: null,
            chaincodeName: '',
            tarName: '',
            ccType: 'template',
          },
        ],
        peers: {},
      },
    ]);
    setChaincodes([]);

    dispatch(clearDeployNotifications());
  }, [defaultOrgsValue, dispatch]);

  const updateCCTypeControlVars = (option: string) => {
    switch (option) {
      case 'template':
        // setUsingTemplate(true);
        // setUsingCloudChaincode(false);
        break;
      case 'preload':
        // setUsingTemplate(false);
        // setUsingCloudChaincode(true);
        break;
      case 'custom':
        // setUsingTemplate(false);
        // setUsingCloudChaincode(false);
        break;
      default:
        break;
    }
  };

  // used to update the previous value of activeCCOption
  const changeActiveCCOptionAndPrev = (newValue: string) => {
    setActiveCCOption((prev) => {
      setPrevActiveCCOption(prev);
      return newValue;
    });
  };

  // when the mustClearForm change to true on deployStart
  // reducer it will clear the deploy form data
  useEffect(() => {
    if (mustClearForm) {
      clearFormData();
      dispatch(clearDeployData(false));
    }
  }, [dispatch, mustClearForm, clearFormData]);

  // when the mustChangeStep change on initial tour
  // reducer it will move to the deploy to the given step
  useEffect(() => {
    if (mustChangeDeployStepTo || mustChangeDeployStepTo === 0) {
      setActiveStep(mustChangeDeployStepTo);
      dispatch(changeDeployStepOnTour(null));
    }
  }, [dispatch, mustChangeDeployStepTo]);

  // when the setDeployTemplateTo change on initial tour
  // reducer it will change the deploy template to it
  useEffect(() => {
    if (setDeployTemplateTo || setDeployTemplateTo === null)
      setTemplate(setDeployTemplateTo);
  }, [dispatch, setDeployTemplateTo]);

  // when the setDeployTemplateTo change on initial tour
  // reducer it will change the deploy template to it
  useEffect(() => {
    if (setDeployActiveCCOptTo) setActiveCCOption(setDeployActiveCCOptTo);
  }, [dispatch, setDeployActiveCCOptTo]);

  // When we decide to use the advanced again this should be reviewed
  // update deploy data when advanced JSON is provided
  // useEffect(() => {
  //   if (json && json !== '{}' && previousJsons.current.deployJson !== json) {
  //     updateDeployData(json);
  //     previousJsons.current.deployJson = json;
  //   }
  // }, [json, updateDeployData]);

  // This side effect will update template state with available orgs
  useEffect(() => {
    if (orgs.length && template && template.assets && template.assets.length) {
      const { assets } = template;

      const orgExists = (organization: string) =>
        orgs.some((org) => org.orgName === organization);

      const newAssets = assets.map((asset) => {
        const newAsset = { ...asset };

        if (newAsset.readers) {
          newAsset.readers = newAsset.readers.filter((reader) =>
            orgExists(reader.replace('MSP', '')),
          );
        }

        if (newAsset.props) {
          newAsset.props = newAsset.props.map((prop) => {
            const newWriters = prop.writers?.filter((writer) =>
              orgExists(writer.replace('MSP', '')),
            );

            return { ...prop, writers: newWriters };
          });
        }

        return newAsset;
      });

      setTemplate({ ...template, assets: newAssets });
    }
    // Will only be necessary to run when orgs length is changed
    // eslint-disable-next-line
  }, [orgs.length]);

  return (
    <DeployContext.Provider
      value={{
        // When we decide to use the advanced again this should be reviewed
        // json,
        // setJson,
        init,
        setInit,
        orgs,
        setOrgs,
        channels,
        setChannels,
        started,
        setStarted,
        activeStep,
        setActiveStep,
        channelName,
        setChannelName,
        chaincodeIndex,
        setChaincodeIndex,
        networkName,
        setNetworkName,
        endorsement,
        setEndorsement,
        cloudChaincode,
        setCloudChaincode,
        usingTemplate,
        setUsingTemplate,
        activeCCOption,
        // used to save the previous value
        setActiveCCOption: changeActiveCCOptionAndPrev,
        prevActiveCCOption,
        fabricVersion,
        setFabricVersion,
        endorsementGUI,
        setEndorsementGUI,
        networkType,
        setNetworkType,
        template,
        setTemplate,
        usingCloudChaincode,
        setUsingCloudChaincode,
        chaincodes,
        chaincode,
        setChaincode,
        setChaincodes,
        customTimeoutModalOpened,
        setCustomTimeoutModalOpened,
        grpcTimeout,
        setGrpcTimeout,
        chaincodeTimeout,
        setChaincodeTimeout,
        updateCCTypeControlVars,
        clearFormData,
        isValid,
        setIsValid,
      }}
    >
      {children}
    </DeployContext.Provider>
  );
};

function useDeployForm(): DeployContextData {
  const context = useContext(DeployContext);

  if (!context) {
    throw new Error('Hook must be used within a Provider');
  }

  return context;
}

export { DeployProvider, useDeployForm };
