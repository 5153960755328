import styled from 'styled-components';
import { Paper, Button, Typography } from '@material-ui/core';

export const Container = styled.div`
  width: 650px;
  display: flex;
  margin: 0 auto;
  padding-top: 100px;
  align-items: center;
  justify-content: center;
`;

export const LoginCard = styled(Paper).attrs({ elevation: 8 })`
  && {
    width: 650px;
    display: flex;
    padding-top: 50px;
    padding-left: 150px;
    border-radius: 20px;
    padding-right: 150px;
    padding-bottom: 50px;
    flex-direction: column;
    background-color: var(--backgroundSecondary);
  }
`;

export const LoginMessage = styled(Typography)`
  && {
    word-wrap: break-word;
    text-align: center;
    margin: 30px 0;
  }
`;

export const LanguagesContainer = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`;

export const LoginBtn = styled(Button).attrs({
  variant: 'contained',
})`
  && {
    font-size: 16px;
    color: var(--white);
    background: var(--primary);
  }
`;

export const FooterImgContainer = styled.div`
  margin-top: 10px;
  text-align: center;
`;
