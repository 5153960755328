import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Icon,
  List,
  Checkbox,
  Typography,
  ListItemText,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import {
  OptionsCard,
  AtLeastOneMsg,
  CardsContainer,
  SelectContainer,
  SelectedListItem,
  ComponentContainer,
} from '../styles';
import { IAssetProp, IAsset } from '../../../../store/TemplateCC';

import Tooltip from '../../../Tooltip';

interface IDefinePermissionsProps {
  orgs: string[];
  prop: IAssetProp;
  disabled?: boolean;
  parentAsset: IAsset;
  placeholder?: string;
  changeWritersAllEnabled: () => void;
  changeOpen: (prev: boolean) => void;
  onChange: (value: string[]) => void;
}

const DefinePermissions: React.FC<IDefinePermissionsProps> = ({
  orgs,
  prop,
  disabled,
  onChange,
  changeOpen,
  placeholder,
  parentAsset,
  changeWritersAllEnabled,
}) => {
  const { writers, selectOpened, writersAllEnabled } = prop;

  const { t } = useTranslation();
  const [label, setLabel] = useState('');
  const ref = useRef<HTMLDivElement>(null);
  const [showAtLeastOneOrgMsg, setShowAtLeastOneOrgMsg] = useState(false);
  const [labelMaxLength, setLabelMaxLabel] = useState(
    window.innerWidth >= 1200 ? 60 : 40,
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectOpened &&
      ref.current &&
      !ref.current.contains(event.target as Node)
    )
      changeOpen(false);
  };

  const handleScreenResize = () => {
    setLabelMaxLabel(window.innerWidth >= 1200 ? 60 : 40);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  });

  const cutLabel = useCallback(() => {
    if (label.length >= labelMaxLength) {
      return `${label.substring(0, labelMaxLength)}...`;
    }

    return label;
  }, [label, labelMaxLength]);

  const changeLabel = useCallback(() => {
    if (writers === undefined && !writersAllEnabled) {
      setLabel(placeholder || '');
    } else {
      let writersPart = writersAllEnabled ? t('common.words.all') : '';

      if (!writersAllEnabled) {
        (writers || []).forEach((org, index) => {
          writersPart = `${writersPart}${index === 0 ? '' : ', '}${org.replace(
            'MSP',
            '',
          )}`;
        });
      }

      setLabel(writersPart);
    }
  }, [t, writers, placeholder, writersAllEnabled]);

  useEffect(() => {
    changeLabel();
  }, [changeLabel, writers, writersAllEnabled]);

  const removeOrAddArray = (array: string[], element: string) => {
    const newArray = [...array];
    const elIndex = newArray.findIndex((itm) => itm === element);

    if (elIndex < 0) newArray.push(element);
    else newArray.splice(elIndex, 1);

    return newArray;
  };

  const isOrgDisabled = useCallback(
    (org: string) => {
      return parentAsset.privateData
        ? writersAllEnabled ||
            !parentAsset.readers?.some((item) => item === `${org}MSP`)
        : writersAllEnabled;
    },
    [writersAllEnabled, parentAsset.privateData, parentAsset.readers],
  );

  const mustOrgHaveLineThrough = useCallback(
    (org: string) => {
      return (
        parentAsset.privateData &&
        writersAllEnabled &&
        !parentAsset.readers?.some((item) => item === `${org}MSP`)
      );
    },
    [writersAllEnabled, parentAsset.privateData, parentAsset.readers],
  );

  const handleSelection = useCallback(
    (org: string) => {
      if (selectOpened && !disabled && !isOrgDisabled(org)) {
        const newWriters = removeOrAddArray(writers || [], `${org}MSP`);

        if (newWriters.length > 0) {
          onChange(newWriters);
        } else {
          setShowAtLeastOneOrgMsg(true);

          setTimeout(() => setShowAtLeastOneOrgMsg(false), 10000);
        }
      }
    },
    [disabled, onChange, selectOpened, writers, isOrgDisabled],
  );

  const isOrgSelected = useCallback(
    (org: string) => writers?.some((itm) => itm === `${org}MSP`),
    [writers],
  );

  return (
    <ComponentContainer ref={ref} disabled={disabled || false}>
      <Tooltip message={label} canShow={label.length >= labelMaxLength}>
        <SelectContainer
          open={selectOpened}
          onClick={() => {
            if (!disabled) changeOpen(!selectOpened);
          }}
        >
          <Typography>{cutLabel()}</Typography>

          <Icon style={{ margin: ' 5px 0 0 10px' }}>keyboard_arrow_down</Icon>
        </SelectContainer>
      </Tooltip>

      {selectOpened ? (
        <CardsContainer>
          <div style={{ width: '100%' }}>
            <OptionsCard>
              <List
                disablePadding
                component="div"
                style={{ width: '100%' }}
                subheader={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                      style={{ margin: '0 auto' }}
                      label={t('common.chaincode.allEnabled')}
                      onChange={() => changeWritersAllEnabled()}
                      control={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            component="span"
                            style={{ fontWeight: 'bold' }}
                          >
                            <Trans>common.words.writers</Trans>
                          </Typography>
                          <Switch checked={writersAllEnabled} />
                        </div>
                      }
                    />

                    {showAtLeastOneOrgMsg ? (
                      <AtLeastOneMsg>
                        <Warning style={{ marginRight: '5px' }} />
                        <Trans>common.chaincode.atLeastOneOrg</Trans>
                      </AtLeastOneMsg>
                    ) : null}
                  </div>
                }
              >
                {orgs.map((org: string) => (
                  <Tooltip
                    key={`prop-writers-${org}`}
                    canShow={
                      writersAllEnabled
                        ? mustOrgHaveLineThrough(org)
                        : isOrgDisabled(org)
                    }
                    message={t('common.chaincode.noAccessToPrivateData')}
                  >
                    <SelectedListItem
                      prop
                      selected={isOrgSelected(org) as boolean}
                      isDisabled={isOrgDisabled(org) as boolean}
                      lineThrough={mustOrgHaveLineThrough(org)}
                      onClick={() => handleSelection(org)}
                    >
                      <Checkbox
                        style={{ pointerEvents: 'none' }}
                        checked={
                          // this is a workaround for the problem with the checkbox
                          // that was not being checked (I couldn't find the cause)
                          // but for some reason this is working
                          isOrgSelected(org) ? isOrgSelected(org) : false
                        }
                      />
                      <ListItemText
                        primary={org}
                        style={{ wordBreak: 'break-word' }}
                      />
                    </SelectedListItem>
                  </Tooltip>
                ))}
              </List>
            </OptionsCard>
          </div>
        </CardsContainer>
      ) : null}
    </ComponentContainer>
  );
};

DefinePermissions.defaultProps = {
  disabled: false,
};

export default DefinePermissions;
