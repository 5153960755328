import React from 'react';
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Container,
} from '@material-ui/core';
import * as S from '../styles';
import { useNetworks } from '../../../Contexts/Networks';
import SelectNetwork from '../../../AppComponents/SelectNetwork';
import { peersInChannelCounter } from '../utils';

export const GeneralDashboard: React.FC = () => {
  const {
    networkState,
    selectedNetwork,
    loadingNetState,
    setSelectedChannel,
    setCurrentDashboardView,
  } = useNetworks();

  const countOrgOrderers = (org: any) => {
    let ordCount = 0;
    org?.orderers?.forEach((ord: any) => {
      const orgName: string = org.mspid.replace('MSP', '');
      if (ord.indexOf(`${orgName}.${org.domain}`) >= 0) ordCount++;
    });

    return ordCount;
  };

  const getJoinedChannels = (
    channels: NetWorkState['channels'],
    orgName: string,
  ) => {
    const joinedChannels = Object.entries(channels).filter(
      ([channelName, channelData]) => channelData.peers?.[orgName]?.length > 0,
    );

    return joinedChannels.map((ch) => ch[0]);
  };

  if (!selectedNetwork?.networkName)
    return (
      <SelectNetwork isNetSelected={selectedNetwork?.networkName !== ''} />
    );

  if (!networkState) return <></>;

  return (
    <S.DashboardContainer>
      <Typography variant="overline" style={{ fontSize: '25px' }}>
        {networkState?.networkName}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{ gap: '1rem' }}
      >
        <Typography variant="overline" style={{ fontSize: '18px' }}>
          Channels
        </Typography>

        <Grid container xs="auto" spacing={10} justify="center">
          {networkState?.channels &&
            Object.entries(networkState.channels).map(
              ([channelName, channelData]) => (
                <Grid item>
                  <S.CustomPaper
                    hasHover
                    onMouseEnter={() => setSelectedChannel(channelName)}
                    onClick={() => setCurrentDashboardView('channelDashboard')}
                  >
                    <S.InfoBoxHead>
                      <Typography>{channelName}</Typography>
                    </S.InfoBoxHead>
                    <Box
                      textAlign="center"
                      width="100%"
                      display="flex"
                      flexDirection="column"
                    >
                      <S.InfoGrid>
                        <S.InfoBox>
                          <span>Orgs</span>
                          {Object?.keys(channelData?.peers)?.length}
                        </S.InfoBox>
                        <S.InfoBox>
                          <span>Peers</span>
                          {peersInChannelCounter(channelData?.peers)}
                        </S.InfoBox>
                        <S.InfoBox>
                          <span>Orderers</span>
                          {Object?.keys(networkState?.orderers)?.length}
                        </S.InfoBox>
                      </S.InfoGrid>
                    </Box>
                  </S.CustomPaper>
                </Grid>
              ),
            )}
        </Grid>
        <Typography variant="overline" style={{ fontSize: '18px' }}>
          Organizations
        </Typography>

        <Grid container xs="auto" spacing={10} justify="center">
          {networkState?.organizations &&
            Object.entries(networkState?.organizations)?.map(
              ([orgName, org]) => (
                <Grid item>
                  <S.CustomPaper>
                    <S.InfoBoxHead>
                      <Typography>{`${orgName}.${org.domain}`}</Typography>
                    </S.InfoBoxHead>
                    <Box
                      textAlign="center"
                      width="100%"
                      display="flex"
                      flexDirection="column"
                    >
                      <S.InfoGrid>
                        <S.InfoBox>
                          <span>Peers</span>
                          {(Boolean(org?.peers) &&
                            Object.keys(org?.peers)?.length) ||
                            0}
                        </S.InfoBox>
                        <S.InfoBox>
                          <span>Orderers</span>
                          {countOrgOrderers(org)}
                        </S.InfoBox>
                        <S.InfoBox>
                          <span>APIs</span>
                          {org?.ccapi?.length || 0}
                        </S.InfoBox>
                      </S.InfoGrid>
                    </Box>
                    {getJoinedChannels(networkState?.channels, orgName)
                      ?.length > 0 && (
                      <S.JoinedChannels>
                        <Typography style={{ fontWeight: 'bold' }}>
                          Joined Channels
                        </Typography>
                        {getJoinedChannels(networkState?.channels, orgName).map(
                          (channel) => (
                            <li>{channel}</li>
                          ),
                        )}
                      </S.JoinedChannels>
                    )}
                  </S.CustomPaper>
                </Grid>
              ),
            )}
        </Grid>
      </Box>
    </S.DashboardContainer>
  );
};
