import React from 'react';
import i18n from 'i18next';
import { store } from '../store';
import history from '../history';
import loginAPI from '../Common/fetch';

import { openDialog } from '../store/Dialog';
import { changeIsLogged } from '../store/AppStatus';

import { StoreState } from '../store/types';

import PlanExpiredMessage from './planExpiredMessage';

const clearCoockies = async () => {
  const { keepOnPage } = store.getState().appStatusState;

  window.removeEventListener('beforeunload', keepOnPage);

  const res: { url?: string } = await loginAPI('/signout', {
    redirect: 'manual',
  });

  window.location.href = res.url ? res.url : window.location.href;
};

export default (
  logged: boolean,
  openModal = true,
  errorStatus?: number,
  canRedirect: boolean = window.location.pathname !== '/',
) => {
  const state: StoreState = store.getState();
  const { translationFunction } = state.appStatusState;

  if (errorStatus === 403) {
    store.dispatch(changeIsLogged(false));

    sessionStorage.setItem('@GoFabric:isLogged', 'false');

    if (window.location.pathname !== '/') history.push('/');

    store.dispatch(
      openDialog({
        type: 'info',
        typographyVariant: 'body1',
        content: React.createElement(PlanExpiredMessage),
        title: translationFunction?.('common.messages.planExpired'),
        unmountCallback: clearCoockies,
        refuseAction: clearCoockies,
        useRefuseOnClose: true,
      }),
    );
  } else {
    if (!logged) {
      if (openModal) {
        store.dispatch(
          openDialog({
            type: 'error',
            title: i18n.t('common.messages.sessionExpired'),
            content: i18n.t('common.messages.sessionExpiredExplanation'),
          }),
        );
      }
      if (canRedirect) history.push('/');
    }

    store.dispatch(changeIsLogged(logged));

    sessionStorage.setItem('@GoFabric:isLogged', `${logged}`);
  }
};
