import { Typography } from '@material-ui/core';
import React, { ChangeEventHandler, useCallback, useMemo } from 'react';
import { useDeployForm } from '../../Hooks/deploy';
import { PeersCheckbox } from './componentes/peersCheckbox';
import { CustomGrid } from './styles';

type CheckboxName = ['interface' | 'api', string, string];

interface ChannelPeerAdderProps {
  currentChaincode: IChaincodes;
  channelIndex: number;
}

export const AvailableNodePeers: React.FC<ChannelPeerAdderProps> = ({
  currentChaincode,
  channelIndex,
}) => {
  const { orgs, channels, setOrgs } = useDeployForm();

  const channel = useMemo(() => channels?.[channelIndex], [
    channelIndex,
    channels,
  ]);

  const removeCCAPI = useCallback(
    (peer: string, currentOrgIndex: number) => {
      const orgWithoutCCapi = orgs[currentOrgIndex].ccapi.filter((cc) => {
        if (cc.host !== peer) return true;

        return !(cc.chaincodeName === currentChaincode.chaincodeName);
      });

      orgs[currentOrgIndex].ccapi = orgWithoutCCapi;
      setOrgs([...orgs]);
    },
    [currentChaincode, orgs, setOrgs],
  );

  const addCCAPI = useCallback(
    (peer: string, currentOrgIndex: number, goinitus = false) => {
      if (!currentChaincode?.chaincodeName) return;
      const ccapiExistsIndex = orgs[currentOrgIndex].ccapi.findIndex(
        (cc) =>
          cc.host === peer &&
          cc.channelName === channel.channelName &&
          cc.chaincodeName === currentChaincode.chaincodeName,
      );
      const newCCapi = {
        host: peer,
        chaincodeName: currentChaincode.chaincodeName,
        channelName: channel.channelName,
      } as IChaincodeIP;
      if (goinitus) newCCapi.goinitus = {};

      if (currentOrgIndex === -1) return;

      if (ccapiExistsIndex !== -1) {
        orgs[currentOrgIndex].ccapi[ccapiExistsIndex] = newCCapi;
      } else {
        const ccApiIndex = orgs[currentOrgIndex].ccapi.findIndex(
          (ccapi) =>
            ccapi.channelName === newCCapi.channelName &&
            ccapi.chaincodeName === newCCapi.chaincodeName &&
            ccapi.host === peer,
        );
        if (ccApiIndex === -1) {
          orgs[currentOrgIndex].ccapi.push(newCCapi);
        } else {
          orgs[currentOrgIndex].ccapi[ccApiIndex] = newCCapi;
        }
      }
      setOrgs([...orgs]);
    },
    [channel, currentChaincode, orgs, setOrgs],
  );

  const handleApiClick: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, checked } = event.target;
      const [type, orgName, peer] = name.split('_') as CheckboxName; // _ is the delimiter defined in checkbox name

      if (!currentChaincode || !orgName || !peer) return;

      const currentOrgIndex = orgs.findIndex((org) => org.orgName === orgName);

      if (checked) {
        addCCAPI(peer, currentOrgIndex);
      } else {
        removeCCAPI(peer, currentOrgIndex);
      }
    },
    [addCCAPI, currentChaincode, orgs, removeCCAPI],
  );

  const handleInterfaceClick: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, checked } = event.target;
      const [type, orgName, peer] = name.split('_') as CheckboxName; // _ is the delimiter defined in checkbox name
      if (!currentChaincode) return;
      const currentOrgIndex = orgs.findIndex((org) => org.orgName === orgName);

      const ccapiExistsIndex = orgs?.[currentOrgIndex]?.ccapi?.findIndex(
        (cc) =>
          cc.host === peer &&
          currentChaincode?.chaincodeName === cc?.chaincodeName &&
          cc.channelName === channel.channelName,
      );
      if (ccapiExistsIndex === -1) {
        addCCAPI(peer, currentOrgIndex, true);
      } else {
        if (checked)
          orgs[currentOrgIndex].ccapi[ccapiExistsIndex].goinitus = {};
        else delete orgs[currentOrgIndex].ccapi[ccapiExistsIndex].goinitus;
        setOrgs([...orgs]);
      }
    },
    [addCCAPI, channel.channelName, currentChaincode, orgs, setOrgs],
  );

  const handleInterfacePortChange = useCallback(
    (port: number, peer: string, orgName: string) => {
      const currentOrgIndex = orgs.findIndex((org) => org.orgName === orgName);

      orgs[currentOrgIndex].ccapi = orgs[currentOrgIndex].ccapi.map((ccapi) => {
        if (
          ccapi.host === peer &&
          ccapi.channelName === channel.channelName &&
          ccapi.chaincodeName === currentChaincode?.chaincodeName
        ) {
          const newccapi = { ...ccapi, goinitus: { ...ccapi.goinitus, port } };
          return newccapi;
        }
        return ccapi;
      });

      setOrgs([...orgs]);
    },
    [channel.channelName, currentChaincode, orgs, setOrgs],
  );

  const handlePortChange = useCallback(
    (port: string, value: number, peer: string, orgName: string) => {
      const currentOrgIndex = orgs.findIndex((org) => org.orgName === orgName);

      orgs[currentOrgIndex].ccapi = orgs[currentOrgIndex].ccapi.map((ccapi) => {
        if (
          ccapi.host === peer &&
          ccapi.channelName === channel.channelName &&
          ccapi.chaincodeName === currentChaincode?.chaincodeName
        ) {
          const newccapi = { ...ccapi, [port]: value };
          return newccapi;
        }
        return ccapi;
      });

      setOrgs([...orgs]);
    },
    [channel.channelName, currentChaincode, orgs, setOrgs],
  );

  return (
    <div style={{ width: '100%' }}>
      <Typography variant="subtitle2">Node available for API</Typography>
      <CustomGrid>
        <span style={{ marginBottom: '0.5rem' }}>Node</span>
        <span style={{ marginBottom: '0.5rem' }}>API</span>
        <span style={{ marginBottom: '0.5rem' }}>INTERFACE</span>
        {channel?.peers
          ? Object.entries(channel.peers).map(([orgName, peers]) =>
              peers.map((peer, key) => {
                return (
                  <PeersCheckbox
                    peer={peer}
                    orgName={orgName}
                    chaincode={currentChaincode}
                    channel={channel}
                    handleApiClick={handleApiClick}
                    handleInterfaceClick={handleInterfaceClick}
                    handleInterfacePortChange={handleInterfacePortChange}
                    handlePortChange={handlePortChange}
                    key={key}
                  />
                );
              }),
            )
          : null}
      </CustomGrid>
    </div>
  );
};
