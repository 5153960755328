import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { openDialog } from '../../store/Dialog';
import Logo from '../../assets/goledger_login.svg';
import Poweredby from '../../assets/poweredby_goledger.svg';
import loginAPI from '../../Common/fetch';
import { changeLanguageCallback } from '../../store/AppStatus';
import { openLoading, closeLoading } from '../../store/GlobalLoading';
import { StoreState } from '../../store/types';

import {
  LoginBtn,
  Container,
  LoginCard,
  LoginMessage,
  FooterImgContainer,
  LanguagesContainer,
} from './styles';
import { useLicense } from '../../Contexts/License';
import { LicenseModal } from '../../AppComponents/LicenseModal';

const Login = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { supportedLanguages, supportedLanguagesName } = useSelector(
    (state: StoreState) => state.appStatusState,
  );

  const { licenseInfo } = useLicense();

  const [open, setOpen] = useState(false);

  const changeLanguage = (index: number) => {
    const language: string = supportedLanguages[index];

    i18next.changeLanguage(
      language,
      (error: object, trans: (arg0: string) => string) => {
        dispatch(changeLanguageCallback(language, error, trans));
      },
    );
  };

  const login = () => {
    try {
      dispatch(openLoading());

      loginAPI('/signin', { redirect: 'manual' })
        .then((res: { url?: string }) => {
          window.location.href = res.url ? res.url : window.location.href;

          sessionStorage.setItem(
            '@GoFabric:lastCheck',
            `${new Date().toJSON()}`,
          );
        })
        .catch(() => {
          dispatch(
            openDialog({
              title: t('common.words.error'),
              type: 'error',
              content: t('asset.login.loginError'),
            }),
          );
        })
        .finally(() => dispatch(closeLoading()));
    } catch (error) {
      dispatch(closeLoading());
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error.message,
        }),
      );
    }
  };

  return (
    <>
      <Container>
        <LoginCard>
          <img src={Logo} alt="Goledger logo" />

          <LoginMessage>
            <Trans>asset.login.loginMessage</Trans>
          </LoginMessage>

          <LoginBtn onClick={login}>
            <Trans>common.words.login</Trans>
          </LoginBtn>

          <LanguagesContainer>
            {supportedLanguagesName.map((lng: string, idx: number) => (
              <Button
                key={lng}
                color="primary"
                onClick={() => changeLanguage(idx)}
              >
                {lng}
              </Button>
            ))}
          </LanguagesContainer>
        </LoginCard>
      </Container>

      <FooterImgContainer>
        <img src={Poweredby} alt={t('asset.login.poweredByGoledger')} />
      </FooterImgContainer>

      {process.env?.REACT_APP_SHOW_LICENSE?.toLowerCase?.() !== 'false' && (
        <FooterImgContainer>
          <Box
            style={{
              margin: '1rem auto',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
            >
              License details
            </Button>
          </Box>
        </FooterImgContainer>
      )}

      <LicenseModal
        license={licenseInfo}
        isOpen={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default Login;
