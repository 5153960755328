import styled from 'styled-components';
import { Info } from '@material-ui/icons';
import { Button, Typography } from '@material-ui/core';

interface IChaincodeOptBtnProps {
  variant: string;
}

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  /* @media (min-width: 1800px) {
    flex-direction: row;
  } */
`;

export const StepperContainer = styled.div`
  width: 60%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DefineOrgsFormVertical = styled.div`
  margin: 20px;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: start;
  justify-content: flex-start;
`;

export const NetDefsFormVertical = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
`;

export const CAContainer = styled.div`
  padding: 15px;
  border-radius: 5px;
  border: 1px solid rgb(149, 165, 166);
`;

export const MustSaveThis = styled(Typography).attrs({ component: 'span' })`
  && {
    font-size: 12px;
    font-weight: 700;
    color: var(--warning);
  }
`;

export const PreloadedCCContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`;

export const CCOptsContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: space-around;
`;

export const ChaincodeOptBtn = styled(Button).attrs(
  (props: IChaincodeOptBtnProps) => ({
    color: 'primary',
    variant: props.variant,
  }),
)`
  && {
    width: 100%;
    flex-grow: 1;
    margin: 0 5px;
  }
`;

export const fileInputCustomBtnStyle = {
  height: '45px',
  width: '250px',
  marginRight: '10px',
};

export const TabLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DefOrgsStepContainer = styled.div`
  width: 85%;
  max-width: 1085px;
  margin: 30px auto 10px auto;
  padding-left: ${(props: { hasCards: boolean }) =>
    props.hasCards ? '30px' : '0'};
`;

export const NetDefsStepContainer = styled.div`
  width: 80%;
  max-width: 950px;
  margin: 50px auto 20px auto;
  padding-left: ${(props: { hasCards: boolean }) =>
    props.hasCards ? '30px' : '0'};

  @media (max-width: 1400px) {
    width: 95%;
  }
`;

export const NoPermissionMsg = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 15px 10px;
  }
`;

export const NetAndChannelNameContainer = styled.div`
  display: 'flex';
  width: fit-content;
  align-items: flex-start;
`;

export const CCInfoIcon = styled(Info).attrs({
  color: 'primary',
  fontSize: 'large',
})`
  && {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
`;
