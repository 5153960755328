import React from 'react';
import { Route } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Root from './Screens/Root';
import APIMain from './Screens/API';
import Deploy from './Screens/Deploy';
import Report from './Screens/Report';
import Profile from './Screens/Profile';
import Login from './Screens/Login/Login';
import Dashboard from './Screens/Dashboard';
import AddPeer from './Screens/Node/AddPeer';
import NodeMain from './Screens/Node/NodeMain';
import ReportSteps from './Screens/Report/Steps';
import JoinChannel from './Screens/Channel/Join';
import UpgradeAPI from './Screens/API/UpgradeAPI';
import AddOrderer from './Screens/Node/AddOrderer';
import RemovePeer from './Screens/Node/RemovePeer';
import SetupMachines from './Screens/SetupMachines';
import BackupMain from './Screens/Backup/BackupMain';
import AddWebClient from './Screens/API/AddWebClient';
import NetworkMain from './Screens/Network/NetworkMain';
import BackupCreate from './Screens/Backup/BackupCreate';
import BackupRestore from './Screens/Backup/BackupRestore';
import AddChaincodeAPI from './Screens/API/AddChaincodeAPI';
import OperationsHistory from './Screens/OperationsHistory';
import ChaincodeMain from './Screens/Chaincode/ChaincodeMain';
import NetworkRestore from './Screens/NetworkRestore/NetworkRestore';
import TemplateCCMainPage from './Screens/Chaincode/Templates';
import UpgradeChaincode from './Screens/Chaincode/UpgradeChaincode';
import AddOrganization from './Screens/Organization/AddOrganization/index';
import AddExternalOrganization from './Screens/AddExternalOrganization/index';
import OrganizationMain from './Screens/Organization/OrganizationMain';
import TemplatesPage from './Screens/Chaincode/Templates/TemplatesPage';
import TemplateExport from './Screens/Chaincode/Templates/TemplateExport';
import TemplateImport from './Screens/Chaincode/Templates/TemplateImport';
import AddChannel from './Screens/AddChannel';
import AddChaincode from './Screens/AddChaincode';

import { AddPeerProvider } from './Hooks/addPeer';
import { AddOrdererProvider } from './Hooks/addOrderer';
import { RemovePeerProvider } from './Hooks/removePeer';
import { JoinChannelProvider } from './Hooks/joinChannel';
import { BackupCreateProvider } from './Hooks/backupCreate';
import { AddWebClientProvider } from './Hooks/addWebClient';
import { SetupMachinesProvider } from './Hooks/setupMachines';
import { BackupRestoreProvider } from './Hooks/backupRestore';
import { AddOrganizationProvider } from './Hooks/addOrganization';

import { AddChaincodeApiProvider } from './Hooks/addChaincodeApi';
import { UpgradeChaincodeProvider } from './Hooks/UpgradeChaincode';
import { AddChannelProvider } from './Contexts/AddChannel';
import { AddChaincodeProvider } from './Contexts/AddChaincode';
import Certificate from './Screens/API/Certificate';

import { AddExternalOrganizationProvider } from './Contexts/AddExternalOrganization';
import { UpgradeAPIProvider } from './Contexts/UpgradeAPI';
import { LicenseInfoProvider } from './Contexts/License';

export const Routes = () => (
  <>
    <Route exact path="/" component={Root} />

    <Route exact path="/operations-history" component={OperationsHistory} />
    <Route exact path="/report/:reportType/:reportId" component={Report} />
    <Route
      exact
      path="/report/:reportType/:reportId/:orgName/steps"
      component={ReportSteps}
    />

    <Route exact path="/network" component={NetworkMain} />
    <Route exact path="/restore" component={NetworkRestore} />
    <Route exact path="/organization" component={OrganizationMain} />
    <Route exact path="/node" component={NodeMain} />
    <Route exact path="/chaincode" component={ChaincodeMain} />
    <Route exact path="/api" component={APIMain} />
    <Route exact path="/backup" component={BackupMain} />
    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/profile" component={Profile} />

    <Route exact path="/chaincode/templates" component={TemplateCCMainPage} />
    <DndProvider backend={HTML5Backend}>
      <Route
        exact
        path="/chaincode/templates/manage"
        component={TemplatesPage}
      />
    </DndProvider>

    <Route
      exact
      path="/chaincode/templates/export"
      component={TemplateExport}
    />

    <Route
      exact
      path="/chaincode/templates/import"
      component={TemplateImport}
    />
    <Route exact path="/network/deploy" component={Deploy} />

    <AddChannelProvider>
      <Route exact path="/network/channel/create" component={AddChannel} />
    </AddChannelProvider>

    <AddChaincodeProvider>
      <Route exact path="/chaincode/create" component={AddChaincode} />
    </AddChaincodeProvider>

    <AddChaincodeApiProvider>
      <Route exact path="/api/add" component={AddChaincodeAPI} />
    </AddChaincodeApiProvider>

    <AddOrganizationProvider>
      <Route exact path="/organization/add" component={AddOrganization} />
    </AddOrganizationProvider>
    <AddExternalOrganizationProvider>
      <Route
        exact
        path="/organization/addExt"
        component={AddExternalOrganization}
      />
    </AddExternalOrganizationProvider>

    <RemovePeerProvider>
      <Route exact path="/node/peer/remove" component={RemovePeer} />
    </RemovePeerProvider>

    <AddPeerProvider>
      <Route exact path="/node/peer/add" component={AddPeer} />
    </AddPeerProvider>

    <AddOrdererProvider>
      <Route exact path="/node/orderer/add" component={AddOrderer} />
    </AddOrdererProvider>

    <JoinChannelProvider>
      <Route exact path="/node/channel/join" component={JoinChannel} />
    </JoinChannelProvider>

    <UpgradeChaincodeProvider>
      <Route
        exact
        path="/chaincode/upgrade/:network?"
        component={UpgradeChaincode}
      />
    </UpgradeChaincodeProvider>

    <AddWebClientProvider>
      <Route exact path="/api/add/webclient" component={AddWebClient} />
    </AddWebClientProvider>

    <UpgradeAPIProvider>
      <Route exact path="/api/upgrade" component={UpgradeAPI} />
    </UpgradeAPIProvider>

    <Route exact path="/api/certificate" component={Certificate} />

    <SetupMachinesProvider>
      <Route exact path="/setupmachines" component={SetupMachines} />
    </SetupMachinesProvider>

    <BackupCreateProvider>
      <Route exact path="/backup/create" component={BackupCreate} />
    </BackupCreateProvider>

    <BackupRestoreProvider>
      <Route exact path="/backup/restore" component={BackupRestore} />
    </BackupRestoreProvider>
  </>
);

export const BeforeLoginRoutes = () => (
  <>
    <LicenseInfoProvider>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
    </LicenseInfoProvider>
  </>
);
