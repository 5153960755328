import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDeployForm } from '../../../Hooks/deploy';
import GenericInput from '../../../Screens/Deploy/Components/GenericInput';
import { useValidatePort } from '../../../Hooks/useValidatePort';

interface PeersCheckboxProps {
  peer: string;
  orgName: string;
  chaincode: IChaincodes;
  channel: Channel;
  handleApiClick: (event: ChangeEvent<HTMLInputElement>) => void;
  handleInterfaceClick: (event: ChangeEvent<HTMLInputElement>) => void;
  handleInterfacePortChange: (
    port: number,
    peer: string,
    orgName: string,
  ) => void;
  handlePortChange: (
    port: string,
    value: number,
    peer: string,
    orgName: string,
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(12),
      flexBasis: '30%',
      flexShrink: 0,
      textTransform: 'uppercase',
      alignSelf: 'center',
      letterSpacing: '0.08333em',
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.text.secondary,
      alignSelf: 'center',
    },
  }),
);

export const PeersCheckbox = ({
  peer,
  orgName,
  chaincode,
  channel,
  handleApiClick,
  handleInterfaceClick,
  handleInterfacePortChange,
  handlePortChange,
}: PeersCheckboxProps) => {
  const styles = useStyles();

  const { orgs } = useDeployForm();

  console.log('CCAPI PEERS:', orgs);

  const [isDefaultTLSPort, setIsDefaultTLSPort] = useState(true);
  const [isDefaultRestPort, setIsDefaultRestPort] = useState(true);
  const [isDefaultInitusPort, setIsDefaultInitusPort] = useState(true);

  const validatePort = useValidatePort();

  const isChecked = useMemo(() => {
    const orgIndex = orgs?.findIndex((o) => o?.orgName === orgName);

    const ccapiIndex = orgs?.[orgIndex]?.ccapi.findIndex(
      (cc) => cc.host === peer && cc.chaincodeName === chaincode?.chaincodeName,
    );
    const interfaceChecked = Boolean(
      orgs?.[orgIndex]?.ccapi?.[ccapiIndex]?.goinitus,
    );

    return {
      apiChecked: ccapiIndex !== -1,
      interfaceChecked,
    };
  }, [chaincode, orgName, orgs, peer]);

  return (
    <>
      <span>
        {orgName}@{peer}
      </span>
      <div>
        <Tooltip
          title={!chaincode?.chaincodeName ? 'Chaincode name is required' : ''}
        >
          <span>
            <Checkbox
              id={`api_${orgName}_${peer}`}
              name={`api_${orgName}_${peer}`}
              onChange={handleApiClick}
              defaultChecked={isChecked.apiChecked}
              checked={isChecked.apiChecked}
              disabled={!chaincode?.chaincodeName}
            />
          </span>
        </Tooltip>
      </div>
      <div>
        <Tooltip
          title={!chaincode?.chaincodeName ? 'Chaincode name is required' : ''}
        >
          <span>
            <Checkbox
              id={`interface_${orgName}_${peer}`}
              name={`interface_${orgName}_${peer}`}
              onChange={handleInterfaceClick}
              defaultChecked={isChecked.interfaceChecked}
              checked={isChecked.interfaceChecked}
              disabled={!chaincode?.chaincodeName}
            />
          </span>
        </Tooltip>
      </div>

      <div
        style={{
          gridColumn: '1/-1',
          paddingBottom: '1rem',
          borderBottom: '1px solid #e6e6e6',
        }}
      >
        <Accordion
          style={{
            boxShadow: 'none',
            background: 'none',
            border: '1px solid #ddd',
          }}
          disabled={!isChecked.apiChecked && !isChecked.interfaceChecked}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={styles.heading}>Advanced</Typography>
            <Typography className={styles.secondaryHeading}>
              Configure Instance ports
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column', gap: '1.5rem' }}>
            {isChecked.apiChecked && (
              <Box display="flex" style={{ gap: '1.5rem' }}>
                <Box display="flex" flexDirection="column">
                  <GenericInput
                    type="number"
                    value={
                      orgs
                        .find((o) => o.orgName === orgName)
                        ?.ccapi.find(
                          (cc) =>
                            cc.host === peer &&
                            cc.channelName === channel.channelName &&
                            cc.chaincodeName === chaincode?.chaincodeName,
                        )?.restPort
                    }
                    style={{ maxWidth: '256px' }}
                    label="Rest Port"
                    onChange={(e) =>
                      handlePortChange(
                        'restPort',
                        Number(e.target.value),
                        peer,
                        orgName,
                      )
                    }
                    disabled={isDefaultRestPort}
                    helperText={(() => {
                      const isValid = validatePort(
                        orgs
                          .find((o) => o.orgName === orgName)
                          ?.ccapi.find(
                            (cc) =>
                              cc.host === peer &&
                              cc.channelName === channel.channelName &&
                              cc.chaincodeName === chaincode?.chaincodeName,
                          )?.restPort as number,
                        orgName,
                        'restPort',
                        peer,
                      );
                      return isValid?.portInUse
                        ? `Port already in use by ${isValid.info?.org}${
                            isValid.info?.peer ? `@${isValid.info?.peer}` : ''
                          }'s ${isValid.info?.portName}`
                        : '';
                    })()}
                    error={
                      validatePort(
                        orgs
                          .find((o) => o.orgName === orgName)
                          ?.ccapi.find(
                            (cc) =>
                              cc.host === peer &&
                              cc.channelName === channel.channelName &&
                              cc.chaincodeName === chaincode?.chaincodeName,
                          )?.restPort as number,
                        orgName,
                        'restPort',
                        peer,
                      )?.portInUse
                    }
                  />
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      onChange={(e) => {
                        handlePortChange(
                          'restPort',
                          ('' as unknown) as number,
                          peer,
                          orgName,
                        );
                        setIsDefaultRestPort(e.target.checked);
                      }}
                      checked={isDefaultRestPort}
                    />
                    <Typography>Default Port</Typography>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column">
                  <GenericInput
                    type="number"
                    value={
                      orgs
                        .find((o) => o.orgName === orgName)
                        ?.ccapi.find(
                          (cc) =>
                            cc.host === peer &&
                            cc.channelName === channel.channelName &&
                            cc.chaincodeName === chaincode?.chaincodeName,
                        )?.TLSPort
                    }
                    style={{ maxWidth: '256px' }}
                    label="TLS Port"
                    onChange={(e) =>
                      handlePortChange(
                        'TLSPort',
                        Number(e.target.value),
                        peer,
                        orgName,
                      )
                    }
                    disabled={isDefaultTLSPort}
                    helperText={(() => {
                      const isValid = validatePort(
                        orgs
                          .find((o) => o.orgName === orgName)
                          ?.ccapi.find(
                            (cc) =>
                              cc.host === peer &&
                              cc.channelName === channel.channelName &&
                              cc.chaincodeName === chaincode?.chaincodeName,
                          )?.TLSPort as number,
                        orgName,
                        'TLSPort',
                        peer,
                      );
                      return isValid?.portInUse
                        ? `Port already in use by ${isValid.info?.org}${
                            isValid.info?.peer ? `@${isValid.info?.peer}` : ''
                          }'s ${isValid.info?.portName}`
                        : '';
                    })()}
                    error={
                      validatePort(
                        orgs
                          .find((o) => o.orgName === orgName)
                          ?.ccapi.find(
                            (cc) =>
                              cc.host === peer &&
                              cc.channelName === channel.channelName &&
                              cc.chaincodeName === chaincode?.chaincodeName,
                          )?.TLSPort as number,
                        orgName,
                        'TLSPort',
                        peer,
                      )?.portInUse
                    }
                  />
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      onChange={(e) => {
                        handlePortChange(
                          'TLSPort',
                          ('' as unknown) as number,
                          peer,
                          orgName,
                        );
                        setIsDefaultTLSPort(e.target.checked);
                        return e;
                      }}
                      checked={isDefaultTLSPort}
                    />
                    <Typography>Default Operation Port</Typography>
                  </Box>
                </Box>
              </Box>
            )}

            {isChecked.interfaceChecked && (
              <Box display="flex" style={{ gap: '1.5rem' }}>
                <Box display="flex" flexDirection="column">
                  <GenericInput
                    type="number"
                    value={
                      orgs
                        .find((o) => o.orgName === orgName)
                        ?.ccapi.find(
                          (cc) =>
                            cc.host === peer &&
                            cc.channelName === channel.channelName &&
                            cc.chaincodeName === chaincode?.chaincodeName,
                        )?.goinitus?.port
                    }
                    style={{ maxWidth: '256px' }}
                    label="Interface Port"
                    onChange={(e) =>
                      handleInterfacePortChange(
                        Number(e.target.value),
                        peer,
                        orgName,
                      )
                    }
                    disabled={isDefaultInitusPort}
                    helperText={(() => {
                      const isValid = validatePort(
                        orgs
                          .find((o) => o.orgName === orgName)
                          ?.ccapi.find(
                            (cc) =>
                              cc.host === peer &&
                              cc.channelName === channel.channelName &&
                              cc.chaincodeName === chaincode?.chaincodeName,
                          )?.goinitus?.port as number,
                        orgName,
                        'goinitus',
                        peer,
                      );
                      return isValid?.portInUse
                        ? `Port already in use by ${isValid.info?.org}${
                            isValid.info?.peer ? `@${isValid.info?.peer}` : ''
                          }'s ${isValid.info?.portName}`
                        : '';
                    })()}
                    error={
                      validatePort(
                        orgs
                          .find((o) => o.orgName === orgName)
                          ?.ccapi.find(
                            (cc) =>
                              cc.host === peer &&
                              cc.channelName === channel.channelName &&
                              cc.chaincodeName === chaincode?.chaincodeName,
                          )?.goinitus?.port as number,
                        orgName,
                        'goinitus',
                        peer,
                      )?.portInUse
                    }
                  />
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      onChange={(e) => {
                        handleInterfacePortChange(
                          ('' as unknown) as number,
                          peer,
                          orgName,
                        );
                        setIsDefaultInitusPort(e.target.checked);
                        return e;
                      }}
                      checked={isDefaultInitusPort}
                    />
                    <Typography>Default Port</Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
