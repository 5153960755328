import styled, { CSSObject } from 'styled-components';

interface IContainerProps {
  styles?: CSSObject;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${({ styles }) => styles};
`;
