import styled from 'styled-components';
import {
  Paper,
  Typography,
  Avatar,
  TextField,
  DialogContentText,
} from '@material-ui/core';

export const PageTitle = styled(Typography).attrs({
  variant: 'overline',
})`
  && {
    display: flex;
    margin: 20px 0;
    font-size: 25px;
    justify-content: center;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0;
  width: 100%;
`;

export const Card = styled(Paper).attrs({
  elevation: 3,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px;
  width: 45%;
  max-width: 500px;

  strong {
    font-size: 14px;
    color: var(--primary);
  }
`;

export const UserAvatar = styled(Avatar)`
  && {
    width: 100px;
    height: 100px;
    font-size: 50px;
    margin-bottom: 20px;
    background: var(--primary);
  }
`;

export const Field = styled(TextField)`
  && {
    width: 300px;
    max-width: 300px;
    margin-top: 15px;
  }
`;

export const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentText = styled(DialogContentText)`
  && {
    margin: 0;
    display: flex;
    font-size: 16px;
    align-items: center;
  }
`;

export const PlanButton = styled.button`
  display: flex;
  align-items: flex-start;

  background: none;
  border: 0;
  padding: 0;
  outline: 0;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
`;

export const TopicPoint = styled.div`
  width: 5px;
  height: 5px;
  margin-right: 5px;
  border-radius: 50%;
  background: var(--primary);
`;
