import React from 'react';
import { List, ListItem, Typography, ListItemText } from '@material-ui/core';

import getColor from './Colors';

const colors = new Array(50).fill(null).map(() => getColor());

const RenderEndorsement: React.FC<{ endorsement: IEndorsement }> = ({
  endorsement,
}) => {
  const { identities, policy } = endorsement || {};

  // I've tried to type the function return as JSX.Element or JSX.Element[]
  const policyRecursion = (item: object | object[], counter: number): any => {
    const getText = (itm: object | object[]) =>
      Object.keys(itm)[0] !== 'signed-by'
        ? `${Object.keys(itm)[0]} ${Object.values(itm)[0].length}`
        : Object.values(itm)[0];

    if (!Array.isArray(item))
      return (
        <div
          key={Math.random()}
          style={{
            margin: `3px 3px 3px ${counter * 15}px`,
            backgroundColor: colors[counter],
            borderRadius: '5px',
            padding: '5px 10px',
            width: 'fit-content',
            fontSize: '14px',
          }}
        >
          <Typography style={{ color: 'var(--white)' }} variant="overline">
            {getText(item)}
          </Typography>
          {Object.keys(item)[0] !== 'signed-by' &&
            policyRecursion(Object.values(item)[0], counter + 1)}
        </div>
      );
    return item.map((i: object | object[]) => policyRecursion(i, counter + 1));
  };

  return (
    <div
      style={{
        display: 'flex',
        marginTop: '10px',
        alignItems: 'center',
      }}
    >
      <div style={{ width: 'fit-content' }}>
        {identities.map((id, idx) => (
          <List key={idx}>
            <ListItem divider>
              <ListItemText
                primary={`${id.role.name} - id: ${idx}`}
                secondary={id.role.mspId}
              />
            </ListItem>
          </List>
        ))}
      </div>
      <div style={{ width: 'fit-content', marginLeft: '10px' }}>
        {policyRecursion(policy, 0)}
      </div>
    </div>
  );
};

export default RenderEndorsement;
