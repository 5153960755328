import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, Button, Switch, ListItem, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import {
  IAsset,
  ITemplate,
  getTemplateList,
  changeTemplateOption,
} from '../../../store/TemplateCC';

import { StoreState } from '../../../store/types';

import Tooltip from '../../Tooltip';
import DefineAssetPermissions from '../DefinePermissionSelect/Asset';
import DefinePropWritersSelect from '../DefinePermissionSelect/Prop';

import {
  Collapse,
  FormLabel,
  ListItemText,
  FillPropSpace,
  assetItemStyle,
  SelectContainer,
  ListItemContainer,
} from './styles';
import PropDataType from '../../../utils/template/PropDataType';
import { updateTemplateWithDatabase } from '../../../utils/template';
import { changeMountedComponents } from '../../../store/InitialTour';
import { useNetworks } from '../../../Contexts/Networks';
import { useUpgradeChaincodeForm } from '../../../Hooks/UpgradeChaincode';

export type Operation =
  | 'startnetwork'
  | 'upgradechaincode'
  | 'addccapi'
  | 'upgradeapi';

interface IDefineWritersProps {
  operation: Operation;
  orgs: { orgName: string }[];
  deployTemplate?: ITemplate | null;
  netDefTemplate?: ITemplate | null;
  changeDeployTemplate?: (assets: IAsset[]) => void;
}

const DefineWriters: React.FC<IDefineWritersProps> = ({
  orgs,
  operation,
  deployTemplate,
  changeDeployTemplate,
}) => {
  console.log('deployTemplate', deployTemplate);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);

  const { selectedNetwork, setNetworks } = useNetworks();
  const { netDefTemplate, setNetDefTemplate } = useUpgradeChaincodeForm();
  const { templateOption, templateList } = useSelector(
    (state: StoreState) => state.templateCCState,
  );

  const { selectedTemplate }: { selectedTemplate: ITemplate } = templateOption;
  const [assets, setAssets] = useState<IAsset[]>([]);

  useEffect(() => {
    dispatch(getTemplateList());
  }, [dispatch]);

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode && deployTemplate)
      dispatch(changeMountedComponents('deployTemplatePermissions'));
    // eslint-disable-next-line
  }, [deployTemplate]);

  useEffect(() => {
    if (operation === 'startnetwork') {
      if (deployTemplate) {
        const updatedTemplate = updateTemplateWithDatabase(
          templateList,
          deployTemplate,
        );
        setAssets(updatedTemplate.assets);
      }
    }
  }, [deployTemplate, operation, templateList]);

  useEffect(() => {
    console.log('assets', selectedTemplate);
    if (operation) {
      if (operation === 'startnetwork') {
        setAssets(deployTemplate?.assets || []);
      } else {
        setAssets(selectedTemplate.assets);
      }
    }
  }, [operation, deployTemplate, selectedTemplate]);

  useEffect(() => {
    if (netDefTemplate) {
      let newNetDefCC: ITemplate | null = null;

      console.log('entrou');
      newNetDefCC = updateTemplateWithDatabase(templateList, netDefTemplate);

      dispatch(
        changeTemplateOption({
          type: 'selectedTemplate',
          value: newNetDefCC,
        }),
      );
    }
  }, [dispatch, netDefTemplate, setNetDefTemplate, templateList]);

  if (!assets || !assets.length) return null;

  return (
    <List className="template-assets-permissions">
      {assets.map((asset: IAsset, idx) => (
        <SingleAsset
          key={idx}
          asset={asset}
          deployTemplate={deployTemplate}
          operation={operation}
          orgs={orgs}
          changeDeployTemplate={changeDeployTemplate}
        />
      ))}
    </List>
  );
};

const SingleAsset: React.FC<{
  asset: IAsset;
  deployTemplate?: ITemplate | null;
  operation: Operation;
  orgs: { orgName: string }[];
  changeDeployTemplate?: (assets: IAsset[]) => void;
}> = ({ asset, orgs, operation, deployTemplate, changeDeployTemplate }) => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);

  const { selectedNetwork, setNetworks } = useNetworks();
  const { netDefTemplate, setNetDefTemplate } = useUpgradeChaincodeForm();
  const { templateOption, templateList } = useSelector(
    (state: StoreState) => state.templateCCState,
  );

  const { selectedTemplate }: { selectedTemplate: ITemplate } = templateOption;
  const [assets, setAssets] = useState<IAsset[]>([]);

  useEffect(() => {
    dispatch(getTemplateList());
  }, [dispatch]);

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode && deployTemplate)
      dispatch(changeMountedComponents('deployTemplatePermissions'));
    // eslint-disable-next-line
  }, [deployTemplate]);

  useEffect(() => {
    if (operation === 'startnetwork') {
      if (deployTemplate) {
        const updatedTemplate = updateTemplateWithDatabase(
          templateList,
          deployTemplate,
        );
        setAssets(updatedTemplate.assets);
      }
    }
  }, [deployTemplate, operation, templateList]);

  useEffect(() => {
    console.log('assets', selectedTemplate);
    if (operation) {
      if (operation === 'startnetwork') {
        setAssets(deployTemplate?.assets || []);
      } else {
        setAssets(selectedTemplate.assets);
      }
    }
  }, [operation, deployTemplate, selectedTemplate]);

  useEffect(() => {
    if (netDefTemplate) {
      let newNetDefCC: ITemplate | null = null;

      console.log('entrou');
      newNetDefCC = updateTemplateWithDatabase(templateList, netDefTemplate);

      dispatch(
        changeTemplateOption({
          type: 'selectedTemplate',
          value: newNetDefCC,
        }),
      );
    }
  }, [dispatch, netDefTemplate, setNetDefTemplate, templateList]);

  const changeDispatch = useCallback(
    (value: IAsset[]) => {
      console.log('new', value);
      if (operation === 'startnetwork') {
        if (changeDeployTemplate) changeDeployTemplate(value);
      } else {
        dispatch(
          changeTemplateOption({
            type: 'selectedTemplate',
            value: { ...selectedTemplate, assets: value },
          }),
        );
      }
    },
    [dispatch, operation, selectedTemplate, changeDeployTemplate],
  );

  const changeAssetReaders = useCallback(
    (assetTag: string, readers: string[] | 'all' | 'none') => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag)
          return {
            ...asset,
            readers:
              readers === 'none'
                ? []
                : readers === 'all'
                ? orgs.map((org) => `${org.orgName}MSP`)
                : readers,
          };

        return asset;
      });

      changeDispatch(newAssets);
    },
    [orgs, assets, changeDispatch],
  );

  const changePropWriters = useCallback(
    (assetTag: string, propTag: string, writers: string[]) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag) {
          if (!asset.props) return asset;

          const newProps = asset.props.map((prop) => {
            if (prop.tag === propTag) return { ...prop, writers };

            return prop;
          });

          return { ...asset, props: newProps };
        }

        return asset;
      });

      changeDispatch(newAssets);
    },
    [assets, changeDispatch],
  );

  const changeAssetPrivateData = useCallback(
    (assetTag: string, value: boolean) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag) return { ...asset, privateData: value };

        return asset;
      });

      changeDispatch(newAssets);
    },
    [assets, changeDispatch],
  );

  const changePropAllEnabled = useCallback(
    (assetTag: string, propTag: string) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag) {
          if (!asset.props) return asset;

          const newProps = asset.props.map((prop) => {
            if (prop.tag === propTag)
              return { ...prop, writersAllEnabled: !prop.writersAllEnabled };

            return prop;
          });

          return { ...asset, props: newProps };
        }

        return asset;
      });

      changeDispatch(newAssets);
    },
    [assets, changeDispatch],
  );

  const changeAssetSelectOpened = useCallback(
    (assetTag: string, value: boolean) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag) return { ...asset, selectOpened: value };

        return asset;
      });

      changeDispatch(newAssets);
    },
    [assets, changeDispatch],
  );

  const changePropSelectOpened = useCallback(
    (assetTag: string, propTag: string, value: boolean) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag) {
          if (!asset.props) return asset;

          const newProps = asset.props.map((prop) => {
            if (prop.tag === propTag) return { ...prop, selectOpened: value };

            return prop;
          });

          return { ...asset, props: newProps };
        }

        return asset;
      });

      changeDispatch(newAssets);
    },
    [assets, changeDispatch],
  );

  const handleAssetPrivateDataChange = useCallback(
    (assetTag: string) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag)
          return {
            ...asset,
            privateData: !asset.privateData,
            selectOpened: !asset.privateData,
            readers: !asset.privateData
              ? orgs.map((org) => `${org.orgName}MSP`)
              : [],
          };

        return asset;
      });

      changeDispatch(newAssets);
    },
    [orgs, assets, changeDispatch],
  );

  const getOrgNamesList = useCallback(() => {
    return orgs.map((org) => org.orgName);
  }, [orgs]);

  return (
    <>
      <ListItem divider style={assetItemStyle}>
        <ListItemContainer>
          <ListItemText>
            <Tooltip message={t('common.words.label')}>
              <Typography style={{ fontSize: '16px', wordBreak: 'break-word' }}>
                {asset.label}
              </Typography>
            </Tooltip>
            <br />
            <Typography style={{ fontSize: '14px', color: 'var(--disabled)' }}>
              <Trans>common.words.asset</Trans>
            </Typography>
          </ListItemText>

          <SelectContainer>
            <DefineAssetPermissions
              asset={asset}
              orgs={getOrgNamesList()}
              placeholder={t('common.chaincode.selectPermissions')}
              changeOpen={(value) => {
                changeAssetSelectOpened(asset.tag, value);
              }}
              changePrivateData={(value: boolean) => {
                changeAssetPrivateData(asset.tag, value);
              }}
              onChange={(value: string[]) => {
                changeAssetReaders(asset.tag, value);
              }}
            />
          </SelectContainer>

          <div style={{ width: 'fit-content' }}>
            <FormLabel
              language={i18n.language}
              className="asset-private-data"
              label={t('common.chaincode.privateData')}
              onChange={() => handleAssetPrivateDataChange(asset.tag)}
              control={<Switch checked={asset.privateData} />}
            />

            {open ? (
              <Button onClick={() => setOpen(false)} style={{ padding: 0 }}>
                <ExpandLess />
              </Button>
            ) : (
              <Button onClick={() => setOpen(true)} style={{ padding: 0 }}>
                <ExpandMore />
              </Button>
            )}
          </div>
        </ListItemContainer>
      </ListItem>
      <Collapse open={open}>
        <List component="div" disablePadding>
          {asset.props?.map((prop, idx) => (
            <ListItem key={idx} style={{ width: '100%' }}>
              <ListItemContainer>
                <ListItemText>
                  <Tooltip message={t('common.chaincode.labelAndDataType')}>
                    <Typography
                      style={{ fontSize: '16px', wordBreak: 'break-word' }}
                    >
                      {prop.label}
                      <br />
                      <PropDataType dataType={prop.dataType} assets={assets} />
                    </Typography>
                  </Tooltip>
                  <br />
                  <Typography
                    style={{ fontSize: '14px', color: 'var(--disabled)' }}
                  >
                    <Trans>common.words.prop</Trans>
                  </Typography>
                </ListItemText>

                <SelectContainer className="prop-writers-select">
                  <DefinePropWritersSelect
                    prop={prop}
                    parentAsset={asset}
                    orgs={getOrgNamesList()}
                    placeholder={t('common.chaincode.selectPermissions')}
                    changeOpen={(value) => {
                      changePropSelectOpened(asset.tag, prop.tag, value);
                    }}
                    changeWritersAllEnabled={() => {
                      changePropAllEnabled(asset.tag, prop.tag);
                    }}
                    onChange={(newWriters: string[]) => {
                      changePropWriters(asset.tag, prop.tag, newWriters);
                    }}
                  />
                </SelectContainer>

                <div style={{ width: 'fit-content' }}>
                  <FormLabel
                    language={i18n.language}
                    label={t('common.chaincode.allEnabled')}
                    onChange={() => {
                      changePropAllEnabled(asset.tag, prop.tag);
                    }}
                    control={<Switch checked={prop.writersAllEnabled} />}
                  />

                  <FillPropSpace />
                </div>
              </ListItemContainer>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default DefineWriters;
