import axios, { AxiosInstance } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import {
  requestErrorHandler,
  responseErrorHandler,
  requestSuccessHandler,
  responseSuccessHandler,
} from '../utils/axios';

export const baseURL = (): string => {
  const URL = process.env.REACT_APP_API_URL || '';
  // if not set then call api on same url as root page
  const ssl_enable = process.env.REACT_APP_SSL_ENABLE === 'TRUE';
  let requestURL = `http${ssl_enable ? 's' : ''}://`;
  if (URL === '') {
    requestURL += window.location.hostname;
  } else {
    requestURL += URL;
  }

  return requestURL;
};

const uuid = uuidv4();

const networkApi: AxiosInstance = axios.create({
  baseURL: baseURL(),
  headers: { uuid },
  withCredentials: true,
});

networkApi.interceptors.request.use(requestSuccessHandler, requestErrorHandler);

networkApi.interceptors.response.use(
  responseSuccessHandler,
  responseErrorHandler,
);

export { networkApi, uuid };
