import styled from 'styled-components';
import {
  Button,
  FormControlLabel,
  Collapse as MaterialCollapse,
} from '@material-ui/core';

interface ICollapseProps {
  open: boolean;
}

export const SelectContainer = styled.div`
  flex: 1;
  text-align: center;
`;

export const Collapse = styled(MaterialCollapse).attrs(
  (props: ICollapseProps) => ({
    in: props.open,
    timeout: 'auto',
    unmountOnExit: true,
  }),
)<ICollapseProps>`
  && {
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

export const FormLabel = styled(FormControlLabel).attrs({})`
  && {
    margin: 0;
    word-break: break-word;
    max-width: ${(props: { language: string }) =>
      props.language === 'ptbr' ? '140px' : '130px'};
  }
`;

export const ListItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ListItemText = styled.div`
  width: 16%;
  margin-right: 20px;
`;

export const FillPropSpace = styled(Button)`
  padding: 0;
  opacity: 0;
  pointer-events: none;
`;

export const assetItemStyle = {
  width: '100%',
  borderRadius: '5px',
  backgroundColor: 'rgba(39, 60, 117, 0.2)',
};
