import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Button } from '@material-ui/core';
import { Help, Close } from '@material-ui/icons';
import { networkApi } from '../../Common/axios';

import { Container, Toast, HelpIcon } from './styles';
import { startTour } from '../../store/InitialTour';
import { toggleNetworkFetch } from '../../store/Network';

import { getSteps } from '../../utils/tour/FirstTimeUsers';
import { StoreState } from '../../store/types';

const HelpTour: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { networks } = useSelector((state: StoreState) => state.networkState);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (networks === null) {
      networkApi
        .get('/allnetworks')
        .then((res) => {
          dispatch(toggleNetworkFetch('success'));

          if (res.data.length <= 0) setOpen(true);
        })
        .catch(() => {
          dispatch(toggleNetworkFetch('failure'));
        });
    } else if (networks.length <= 0) setOpen(true);

    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <HelpIcon
        color="secondary"
        onClick={() => setOpen((prev) => !prev)}
        className="app-header-help-tour"
      >
        <Icon>
          <Help />
        </Icon>
      </HelpIcon>

      {open ? (
        <Container>
          <Toast>
            <strong style={{ margin: '10px 0' }}>
              {t('tour.tourToast.welcomeToGoFabric')}
            </strong>
            <strong style={{ marginBottom: '18px' }}>
              {t('tour.tourToast.wantToStartTour')}
            </strong>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setOpen(false);
                dispatch(startTour(getSteps(t)));
              }}
            >
              <Trans>button.startTour</Trans>
            </Button>
            <button onClick={() => setOpen((prev) => !prev)} type="button">
              <Close />
            </button>
          </Toast>
        </Container>
      ) : null}
    </div>
  );
};

export default HelpTour;
