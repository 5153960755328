import React, { useEffect, useState } from 'react';

import { CircularProgress, Container } from '@material-ui/core';

import SelectNetwork from '../../AppComponents/SelectNetwork';

import * as S from './styles';
import { useNetworks } from '../../Contexts/Networks';
import { GeneralDashboard } from './GeneralDashboard';
import { ChannelDashboard } from './ChannelDashboard';

const Dashboard: React.FC = () => {
  const {
    selectedNetwork,
    networkState,
    loadingNetState,
    currentDashboardView,
    fetchNetworkState,
  } = useNetworks();

  useEffect(() => {
    if (selectedNetwork) {
      fetchNetworkState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNetwork]);

  if (!selectedNetwork?.networkName || !networkState)
    return (
      <SelectNetwork isNetSelected={selectedNetwork?.networkName !== ''} />
    );

  if (loadingNetState && !networkState)
    return (
      <Container style={{ textAlign: 'center' }}>
        <CircularProgress style={{ marginTop: '32px' }} />
      </Container>
    );

  return {
    generalDashboard: <GeneralDashboard />,
    channelDashboard: <ChannelDashboard />,
  }[currentDashboardView];
};

export default Dashboard;
