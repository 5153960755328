import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { withStyles, Theme } from '@material-ui/core/styles';

import { changeMountedComponents } from '../../store/InitialTour';

import { StoreState } from '../../store/types';

import MenuPage from '../MenuPage';
import GoledgerHome from '../../assets/gofabric_home.png';

import { ImgContainer, GoledgerHomeImg } from './styles';

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  fabGreen: {
    marginLeft: '10%',
    color: theme.palette.common.white,
    backgroundColor: green[500],
    boxShadow: 'none',
  },
});

const Root: React.FC<{ classes: any }> = ({ classes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);

  const [imgLoaded, setImgLoaded] = useState(false);

  const options = [
    {
      icon: 'timeline',
      text: t('title.dashboard.header'),
      link: '/dashboard',
    },
    {
      icon: 'public',
      text: t('title.network.header'),
      link: '/network',
    },
    {
      icon: 'library_add',
      text: t('title.organizations.header'),
      link: '/organization',
    },
    {
      icon: 'share',
      text: t('title.nodes.header'),
      link: '/node',
    },
    {
      icon: 'link',
      text: t('title.chaincodes.header'),
      link: '/chaincode',
    },
    {
      icon: 'api',
      text: t('title.api.header'),
      link: '/api',
    },
    // {
    //   icon: 'backup',
    //   text: t('title.backup.header'),
    //   link: '/backup',
    // },
    {
      icon: 'dns',
      target: 'setupmachines',
      text: t('title.setupMachines.header'),
      link: '/setupmachines',
    },
  ];

  useEffect(() => {
    const goledgerHome = new Image();
    goledgerHome.src = GoledgerHome;

    goledgerHome.onload = () => {
      setImgLoaded(true);
    };
  }, []);

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode) dispatch(changeMountedComponents('homePage'));
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      justify="center"
      className={[classes.root, classes.centerContainer].join(' ')}
    >
      <ImgContainer imgLoaded={imgLoaded}>
        <GoledgerHomeImg src={GoledgerHome} imgLoaded={imgLoaded} />
      </ImgContainer>

      <Grid item xs={12}>
        <MenuPage options={options} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(Root);
