import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Toolbar,
  ButtonBase,
  Typography,
  Box,
  Avatar,
  Tooltip,
  IconButton,
  Icon,
  Popper,
  Paper,
  Link,
  ClickAwayListener,
  Divider,
  Button,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { useDispatch, useSelector } from 'react-redux';

import { changeOnLogout, cancelAllOperations } from '../../store/AppStatus';
import authTokenHandler from '../../utils/authTokenHandler';
import loginAPI from '../../Common/fetch';
import HelpTour from '../HelpTour';
import history from '../../history';
import Languages from '../Languages';
import MenuButtons from '../MenuButtons';

import { Container, LogoImg } from './styles';
import keepOnPage from '../../utils/keepOnPage';
import { openDialog } from '../../store/Dialog';
import { StoreState } from '../../store/types';
import { Reports } from './Reports';
import { useLicense } from '../../Contexts/License';
import { LicenseModal } from '../LicenseModal';

const headerHeight = '65px';

const AppHeader: React.FC<{
  pageOnTop: boolean;
}> = ({ pageOnTop }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userName } = useSelector((state: StoreState) => state.userState);
  const { isOperationRunning, operations } = useSelector(
    (state: StoreState) => state.appStatusState,
  );

  const { licenseInfo } = useLicense();

  const [isModalOpen, setOpen] = useState(false);

  const logout = () => {
    const signOut = () => {
      window.removeEventListener('beforeunload', keepOnPage);

      dispatch(changeOnLogout(true));

      loginAPI('/signout', { redirect: 'manual' })
        .then((res: { url?: string }) => {
          window.location.href = res.url ? res.url : window.location.href;

          authTokenHandler(false, false);
          history.push('/');
        })
        .catch(() => {
          dispatch(
            openDialog({
              type: 'error',
              title: t('common.messages.logoutFail'),
              content: t('common.messages.logoutFailMsg'),
            }),
          );
        })
        .finally(() => dispatch(changeOnLogout(false)));
    };

    dispatch(
      openDialog({
        title: t('sidebar.wantToLeave'),
        type: 'question',
        content: t('sidebar.sureWantToLeave'),
        confirmAction: () => {
          if (isOperationRunning) dispatch(cancelAllOperations());
          signOut();
        },
      }),
    );
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <Container headerHeight={headerHeight} pageOnTop={pageOnTop}>
      <ButtonBase onClick={() => history.push('/')}>
        <LogoImg />

        <Typography
          variant="overline"
          style={{
            color: 'var(--white)',
            padding: '10px',
            fontWeight: 'bold',
          }}
        >
          GoFabric
        </Typography>
      </ButtonBase>

      <MenuButtons />

      <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
        <HelpTour />
        <Reports />
        <Languages />

        <Box
          onClick={handleClick}
          display="flex"
          alignItems="center"
          style={{ gap: '1rem', paddingRight: '1rem' }}
        >
          <Typography variant="overline" style={{ color: 'var(--white)' }}>
            {userName}
          </Typography>
          <Avatar>{userName?.toUpperCase()[0]}</Avatar>
        </Box>

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom"
          disablePortal={false}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
          }}
          style={{ zIndex: 9999 }}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper
              elevation={4}
              style={{
                padding: '1.5rem',
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                style={{ gap: '1rem', minWidth: '175px' }}
              >
                <Box display="flex" flexDirection="column">
                  <Typography variant="overline">{userName}</Typography>
                  {process.env?.REACT_APP_SHOW_LICENSE?.toLowerCase?.() !==
                    'false' && (
                    <Button
                      size="small"
                      variant="text"
                      color="primary"
                      onClick={() => setOpen(true)}
                    >
                      License details
                    </Button>
                  )}
                </Box>
                <Divider style={{ width: '100%' }} />

                <Box textAlign="center">
                  <Link
                    href="https://discord.gg/GndkYHxNyQ"
                    target="_blank"
                    rel="noopener"
                  >
                    <Typography>Forum</Typography>
                  </Link>
                  <Link
                    href="https://youtube.com/goledger"
                    target="_blank"
                    rel="noopener"
                  >
                    <Typography>Tutorial videos</Typography>
                  </Link>
                </Box>
                <Divider style={{ width: '100%' }} />

                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: '100%' }}
                >
                  <Tooltip title={<Trans>Settings</Trans>} arrow>
                    <IconButton>
                      <SettingsIcon onClick={() => history.push('/profile')} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={<Trans>common.words.logout</Trans>} arrow>
                    <IconButton onClick={logout}>
                      <Icon>exit_to_app</Icon>
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>

      <LicenseModal
        license={licenseInfo}
        isOpen={isModalOpen}
        onClose={() => setOpen(false)}
      />
    </Container>
  );
};

export default AppHeader;
