import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ChangeEventHandler,
} from 'react';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Typography,
  Tooltip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { BackspaceOutlined, Clear, Add } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';

import { Trans, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import Axios from 'axios';
import { useDeployForm } from '../../Hooks/deploy';

import CustomTooltip from '../../AppComponents/Tooltip';
import canRunOperation from '../../utils/canRunOperation';
import DefineEndorsement from '../../AppComponents/Endorsement/DefineEndorsement';

import { StoreState } from '../../store/types';
import {
  addOperation,
  Operation,
  removeOperation,
} from '../../store/AppStatus';
import { IEndorsementEvent } from '../../AppComponents/Endorsement/types';
import { AvailableNodePeers } from '../../AppComponents/AvailableNodePeers';
import { StyledTab, StyledTabs } from '../../AppComponents/StyledTabs';
import { CCTypes } from '../../AppComponents/CCTypes';
import { ChaincodeNameInput } from '../../AppComponents/ChaincodeNameInput';
import { ITemplate } from '../../store/TemplateCC';
import { useNetworks } from '../../Contexts/Networks';
import { useAddChaincodeForm } from '../../Contexts/AddChaincode';
import { networkApi } from '../../Common/axios';
import cancelWithDialog from '../../utils/cancelRequestWithModal';
import { openDialog } from '../../store/Dialog';
import { OperationStateCard } from '../../AppComponents/OperationStateCard';
import { useOperation } from '../../Contexts/Operation';

interface IOption {
  name: string;
  option: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #e0e0e0',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      height: 240,
      minWidth: 350,
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
  }),
);

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const AddChaincode = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const addCCForm = useAddChaincodeForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { addChaincodeState } = useOperation();

  const {
    channels,
    setChannels,
    chaincodes,
    setChaincodes,
    setChaincodeIndex,
    peersInChaincode,
    setPeersInChaincode,
    started,
    template,
    activeCCOption,
    chaincodeIndex,
    setEndorsement,
    setActiveCCOption,
    setEndorsementGUI,
    updateCCTypeControlVars,
    setCustomTimeoutModalOpened,
  } = addCCForm;

  const {
    selectedNetwork,
    selectedChannel,
    networks,
    setSelectedNetwork,
    setSelectedChannel,
    fetchNetworkState,
    getAllNetworks,
  } = useNetworks();

  if (!selectedNetwork && networks.length > 0) {
    setSelectedNetwork(networks?.[0]);
    if (networks?.[0]?.channels?.[0])
      setSelectedChannel(Object.keys(networks?.[0]?.channels)?.[0]);
  }

  const orgs = useMemo(() => {
    if (selectedNetwork) {
      return selectedNetwork.organizations;
    }
    return [];
  }, [selectedNetwork]);

  useEffect(() => {
    if (selectedNetwork?.channels) {
      setChannels(
        Object.entries(selectedNetwork.channels).map(
          ([channelName, channel]) => ({
            channelName,
            chaincodes: [] as IChaincodes[],
            peers: channel.peers,
          }),
        ),
      );
    }
  }, [selectedNetwork, setChannels]);

  const { operations }: { operations: Operation[] } = useSelector(
    (state: StoreState) => state.appStatusState,
  );

  const handleChaincodeOptChange = useCallback(
    (option: string) => {
      setActiveCCOption(option);
      updateCCTypeControlVars(option);
    },
    [setActiveCCOption, updateCCTypeControlVars],
  );

  const cantRunOperation = useCallback(() => !canRunOperation(operations), [
    operations,
  ]);

  useEffect(() => {
    if (activeCCOption) handleChaincodeOptChange(activeCCOption);
  }, [activeCCOption, handleChaincodeOptChange]);

  useEffect(() => {
    if (template?.assets.some((asset) => asset.privateData === true)) {
      setEndorsement(null);
    }
  }, [template, setEndorsement]);

  const chaincode = useMemo(() => chaincodes[chaincodeIndex], [
    chaincodeIndex,
    chaincodes,
  ]);

  const addChaincode = useCallback(() => {
    const id = `${chaincodes.length + 1}`;
    const name = '';
    const newChaincode = {
      id,
      chaincodeName: name,
      ccType: 'template' as ChaincodeType,
      chaincodeFile: null,
      tarName: '',
    };

    setChaincodes([...chaincodes, newChaincode]);
  }, [setChaincodes, chaincodes]);

  const removeChaincode = useCallback(
    (chaincodeName) => {
      setChaincodes(
        chaincodes.filter((c) => c.chaincodeName !== chaincodeName),
      );
    },
    [chaincodes, setChaincodes],
  );

  const renderChaincodeTabLabel = useCallback(
    (chaincodeName: string) => (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>{chaincodeName || 'unnamedChaincode'}</Typography>
        {chaincodes.length > 1 && (
          <Tooltip
            disableHoverListener={chaincodes.length === 1}
            title={<Trans>asset.network.deploy.deleteOrgTooltip</Trans>}
          >
            <Clear
              onDoubleClick={() => {
                if (!started) {
                  removeChaincode(chaincodeName);
                  if (chaincodeIndex >= 1)
                    setChaincodeIndex(chaincodeIndex - 1);
                }
              }}
              style={{ fontSize: '15px', opacity: '0.5', marginLeft: '15px' }}
            />
          </Tooltip>
        )}
      </Box>
    ),
    [
      chaincodeIndex,
      chaincodes.length,
      removeChaincode,
      setChaincodeIndex,
      started,
    ],
  );

  const onChaincodeNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { value } = event.target;

      peersInChaincode[value] = peersInChaincode[chaincode.chaincodeName];
      delete peersInChaincode[chaincode.chaincodeName];

      chaincodes[chaincodeIndex].chaincodeName = value;

      setChaincodes([...chaincodes]);
    },
    [chaincode, chaincodeIndex, chaincodes, peersInChaincode, setChaincodes],
  );

  const handleCCTypeChange = useCallback(
    (opt: ChaincodeType) => {
      if (!chaincode || chaincodeIndex === -1) return;
      chaincode.ccType = opt;

      console.log('chaincode.ccType', chaincode.ccType);

      setChaincodes([...chaincodes]);
    },
    [chaincode, chaincodeIndex, setChaincodes, chaincodes],
  );

  const handleFileUpload = useCallback(
    (files: FileList | null) => {
      if (files && files.length > 0) {
        chaincode.chaincodeFile = files?.[0];
        chaincode.tarName = files[0].name;
      }

      setChaincodes([...chaincodes]);
    },
    [chaincode, chaincodes, setChaincodes],
  );

  const handleSelectTemplate = (ccName: string) => {
    if (chaincode?.ccType === 'template') {
      chaincode.ccBaseName = ccName;
    }

    setChaincodes([...chaincodes]);
  };

  const handleSelectFromCloud = (ccName: string) => {
    if (chaincode?.ccType === 'aws-bucket') {
      peersInChaincode[ccName] = peersInChaincode[chaincode.chaincodeName];
      delete peersInChaincode[chaincode.chaincodeName];

      chaincode.ccBaseName = ccName;
      // handle user cloud options to get only ccName and remove userID
      chaincode.chaincodeName = ccName.includes('/')
        ? ccName?.split('/')?.[1]
        : ccName;

      console.log('chaincode ????', chaincode);
    }

    setChaincodes([...chaincodes]);
  };

  const handleSelectTemplateDefinition = (templateDefinition: {
    value: ITemplate;
    label: string;
  }) => {
    peersInChaincode[templateDefinition.value.name] =
      peersInChaincode[chaincode.chaincodeName];
    delete peersInChaincode[chaincode.chaincodeName];

    // handle user templates to get only ccName and remove userID
    chaincode.chaincodeName = templateDefinition.value.name.includes('/')
      ? templateDefinition.value.name?.split('/')?.[1]
      : templateDefinition.value.name;

    chaincode.templateDefinition = templateDefinition?.value;

    setChaincodes([...chaincodes]);
  };

  const handleAddPeer = useCallback(
    (orgName: string, peer: string) => () => {
      setPeersInChaincode({
        ...peersInChaincode,
        [chaincode.chaincodeName]: {
          ...peersInChaincode[chaincode.chaincodeName],
          [orgName]: [
            ...(peersInChaincode[chaincode.chaincodeName]?.[orgName] || []),
            peer,
          ],
        },
      });
    },
    [chaincode, peersInChaincode, setPeersInChaincode],
  );

  const handleRemovePeer = useCallback(
    (orgName: string, peer: string) => () => {
      const newPeersInChaincode = {
        ...peersInChaincode,
        [chaincode.chaincodeName]: {
          ...peersInChaincode[chaincode.chaincodeName],
          [orgName]: peersInChaincode[chaincode.chaincodeName]?.[
            orgName
          ]?.filter((p) => p !== peer),
        },
      };

      // remove org if no peers
      if (newPeersInChaincode[chaincode.chaincodeName][orgName].length === 0) {
        delete newPeersInChaincode[chaincode.chaincodeName][orgName];
      }

      setPeersInChaincode(newPeersInChaincode);
    },
    [chaincode, peersInChaincode, setPeersInChaincode],
  );

  const dispatch = useDispatch();

  const sendData = useCallback(() => {
    console.log('sendData: ', peersInChaincode);

    const formData = new FormData();

    const payload: Record<
      string,
      Omit<IChaincodes, 'id' | 'chaincodeFile' | 'chaincodeName'> & {
        peers: Record<string, string[]>;
        label: string;
        version: string;
        sequence: number;
      }
    > = {};

    chaincodes.forEach((cc) => {
      if (cc.ccType === 'form')
        formData.append('ccFiles', cc.chaincodeFile as Blob);

      payload[cc.chaincodeName] = {
        ...(cc.tarName ? { tarName: cc.tarName } : {}),
        ccType: cc.ccType,
        ...(cc.templateDefinition
          ? { templateDef: cc.templateDefinition }
          : {}),
        ...(cc.ccBaseName ? { ccBaseName: cc.ccBaseName } : {}),
        ...(cc.ccBaseName ? { tarName: `${cc?.chaincodeName}.tar.gz` } : {}),
        label: `${cc.chaincodeName}_1.0`,
        version: '1.0',
        sequence: 1,
        peers: peersInChaincode[cc.chaincodeName],
      };
    });

    formData.append('payload', JSON.stringify(payload));
    formData.append('networkName', selectedNetwork?.networkName || '');
    formData.append('channelName', selectedChannel);

    setIsSubmitting(true);
    try {
      networkApi
        .post('/addCCtoChannel', formData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(c, 'Add Chaincode API', hasDialog);
            };

            cancelRequest = withDialogCancel;
            dispatch(
              addOperation({
                title: 'Add Chaincode to Channel',
                pathname: window.location.pathname,
                name: 'addchaincode',
                cancel: withDialogCancel,
              }),
            );
          }),
        })
        .then(async () => {
          await getAllNetworks();
          await fetchNetworkState();

          dispatch(
            openDialog({
              title: 'Success',
              type: 'success',
              content: 'Chaincode added to channel successfully',
            }),
          );
          dispatch(removeOperation('addchaincode', true));
        })
        .catch((error) => {
          console.log('addchaincode error: ', error);
          dispatch(removeOperation('addchaincode', false));
          dispatch(
            openDialog({
              title: t('common.words.error'),
              type: 'error',
              content: error,
            }),
          );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } catch (error) {
      console.log('error: ', error);
      dispatch(removeOperation('addchaincode', false));
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error?.message,
        }),
      );
    }
  }, [
    chaincodes,
    dispatch,
    fetchNetworkState,
    getAllNetworks,
    peersInChaincode,
    selectedChannel,
    selectedNetwork,
    t,
  ]);

  return (
    <Box maxWidth="950px" margin="0 auto">
      <Typography variant="overline" style={{ fontSize: '25px' }}>
        Add Chaincodes to <strong>{selectedChannel}</strong>
      </Typography>

      <AppBar
        style={{ backgroundColor: 'var(--primary)', marginBottom: '1rem' }}
        position="static"
      >
        <StyledTabs
          value={chaincodeIndex}
          scrollButtons="on"
          variant="scrollable"
          onChange={(_: any, v: any) => setChaincodeIndex(v)}
        >
          {chaincodes.map(({ chaincodeName }, index) => (
            <StyledTab
              key={index}
              label={renderChaincodeTabLabel(chaincodeName)}
            />
          ))}
        </StyledTabs>
        <Button
          variant="outlined"
          onClick={addChaincode}
          className="add-new-org-tab"
          disabled={addCCForm.started}
          style={{ color: 'var(--white)' }}
        >
          <Trans>button.addChaincode</Trans>
        </Button>
      </AppBar>

      <ChaincodeNameInput
        // channelIndex={channelIndex}
        channelIndex={0}
        onNameChange={onChaincodeNameChange}
        value={chaincode?.chaincodeName}
        disabled={
          chaincode?.ccType === 'template' || chaincode?.ccType === 'aws-bucket'
        }
      />
      <CCTypes
        chaincode={chaincode}
        orgs={
          selectedNetwork?.channels?.[selectedChannel]?.peers
            ? (Object.keys(
                selectedNetwork?.channels?.[selectedChannel]?.peers,
              ).map((orgName) => ({ orgName })) as IOrg[])
            : []
        }
        // channelIndex={channelIndex}
        currentChaincode={chaincode?.chaincodeName}
        file={chaincode?.chaincodeFile}
        handleCCTypeChange={handleCCTypeChange}
        handleFileUpload={handleFileUpload}
        operation="startnetwork"
        handleSelectTemplate={handleSelectTemplate}
        handleSelectFromCloud={handleSelectFromCloud}
        handleSelectTemplateDefinition={handleSelectTemplateDefinition}
      />

      <Divider style={{ marginBottom: '2rem' }} />

      <Grid container direction="row" spacing={10}>
        <Grid direction="column" container item xs={5}>
          <Typography variant="subtitle2">Peers Available</Typography>
          <List className={classes.root} subheader={<li />}>
            {orgs &&
              Object.entries(orgs).map(([orgName, orgInfo]) => {
                return (
                  <li className={classes.listSection}>
                    <ul className={classes.ul}>
                      <ListSubheader>{orgName}</ListSubheader>
                      {Boolean(orgInfo?.peers) &&
                        orgInfo?.peers.map((peer, index) => {
                          const peerInfo = selectedNetwork?.peers?.[peer];

                          if (!peerInfo) return null;
                          console.log('peerInfo: ', peerInfo);

                          const labelId = `checkbox-list-label-${index}`;

                          return (
                            <ListItem key={index} role={undefined} dense>
                              <ListItemText
                                id={labelId}
                                primary={`${peerInfo.host}`}
                              />

                              <ListItemSecondaryAction>
                                <IconButton
                                  size="small"
                                  color="primary"
                                  edge="end"
                                  onClick={handleAddPeer(orgName, peer)}
                                  disabled={peersInChaincode[
                                    chaincode.chaincodeName
                                  ]?.[orgName]?.includes(peer)}
                                >
                                  <AddIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                    </ul>
                  </li>
                );
              })}
          </List>
        </Grid>

        <Grid direction="column" container item xs={5}>
          <Typography variant="subtitle2">Peers added</Typography>
          <List className={classes.root} subheader={<li />}>
            {peersInChaincode &&
              peersInChaincode[chaincode?.chaincodeName] &&
              Object.entries(peersInChaincode[chaincode?.chaincodeName]).map(
                ([orgName, peersInOrg]) => {
                  return (
                    <li className={classes.listSection}>
                      <ul className={classes.ul}>
                        <ListSubheader>{orgName}</ListSubheader>
                        {peersInOrg
                          // .filter(
                          //   (peer) =>
                          //     channel?.peers &&
                          //     !Object.values(channel.peers)
                          //       .flat()
                          //       .includes(peer.value),
                          // )
                          .map((peer, index) => {
                            const peerInfo = selectedNetwork?.peers?.[peer];

                            if (!peerInfo) return null;
                            console.log('peerInfo: ', peerInfo);

                            const labelId = `checkbox-list-label-${index}`;

                            return (
                              <ListItem key={index} role={undefined} dense>
                                <ListItemText
                                  id={labelId}
                                  primary={`${peerInfo.host}`}
                                />

                                <ListItemSecondaryAction>
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    edge="end"
                                    onClick={handleRemovePeer(orgName, peer)}
                                  >
                                    <BackspaceOutlined />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                      </ul>
                    </li>
                  );
                },
              )}
          </List>
        </Grid>
      </Grid>

      <div style={{ display: 'flex', marginBottom: '2rem' }}>
        <CustomTooltip
          canShow={cantRunOperation()}
          message={t('common.messages.onlyOneOperation')}
        >
          <Button
            color="primary"
            variant="contained"
            className="deploy-start-btn"
            disabled={addCCForm.started || isSubmitting || cantRunOperation()}
            style={{ width: '45%', margin: '50px auto', pointerEvents: 'all' }}
            onClick={() => {
              if (!addCCForm.started || !cantRunOperation()) sendData();
            }}
          >
            <Trans>button.start</Trans>
          </Button>
        </CustomTooltip>
      </div>

      <OperationStateCard taskOperation={addChaincodeState} />
    </Box>
  );
};

export default AddChaincode;
