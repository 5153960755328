import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Axios, { AxiosError } from 'axios';
import {
  Button,
  Tooltip,
  TextField,
  Typography,
  InputAdornment,
  Box,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { Add, AccountCircle } from '@material-ui/icons';
import RemovePeerButton from './RemovePeerButton';
import { networkApi } from '../../Common/axios';
import { openDialog } from '../../store/Dialog';
import {
  removeMachine,
  clearSetupMachinesNotifications,
} from '../../store/SetupMachinesNotifications';
import { addOperation, removeOperation } from '../../store/AppStatus';
import FileInput from '../../AppComponents/FileInput';
import LoadingContainer from '../../AppComponents/Notifications';

import { Container, Inputs, Peers, Paper, Actions } from './styles';

import { ipPatt, fqdnPatt } from '../../utils/regexPatterns';
import cancelWithDialog from '../../utils/cancelRequestWithModal';
import { useSetupMachinesForm } from '../../Hooks/setupMachines';

import { StoreState } from '../../store/types';
import { changeMountedComponents } from '../../store/InitialTour';
import { OperationStateCard } from '../../AppComponents/OperationStateCard';
import { useOperation } from '../../Contexts/Operation';

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const SetupMachines = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    userName,
    setUserName,
    sending,
    setSending,
    machines,
    setMachines,
    file,
    setFile,
    cleanFormData,
  } = useSetupMachinesForm();

  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);
  const { chapterToChange } = useSelector(
    (state: StoreState) => state.initialTourState,
  );

  const { setupMachinesState } = useOperation();
  const [duplicatedIP, setDuplicatedIP] = useState(false);

  const validHost = (machine: string) =>
    ipPatt.test(machine) || fqdnPatt.test(machine);

  const sendData = () => {
    try {
      dispatch(clearSetupMachinesNotifications());

      if (!userName) throw Error(t('common.messages.invalidUserName'));
      if (!file) throw Error(t('asset.setupMachines.noSSHImage'));
      if (machines.length <= 0 || machines.some((p) => !p))
        throw Error(t('asset.setupMachines.noMachineIP'));
      if (machines.some((p) => !validHost(p))) {
        throw Error(t('asset.setupMachines.wrongIP'));
      }
      if (duplicatedIP) throw Error(t('asset.setupMachines.hasDuplicatedIPs'));

      // global FormData
      const formData = new FormData();
      formData.append('key', file as Blob);

      const config = {
        ips: machines,
        userName,
      };

      config.ips.forEach((ip) => {
        formData.append('ip', ip);
      });

      formData.append('username', config.userName);

      setSending(true);

      networkApi
        .post('/setupmachines', formData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(c, t('title.setupMachines.header'), hasDialog);
            };

            cancelRequest = withDialogCancel;
            dispatch(
              addOperation({
                title: t('title.setupMachines.header'),
                cancel: withDialogCancel,
                name: 'setup',
                pathname: window.location.pathname,
              }),
            );
          }),
        })
        .then(() => {
          dispatch(removeOperation('setup', true));
          dispatch(
            openDialog({
              title: t('common.words.success'),
              type: 'success',
              content: t('common.requestResponses.success.setup'),
            }),
          );
          cleanFormData();
        })
        .catch((err: AxiosError) => {
          dispatch(removeOperation('setup', false));
          dispatch(
            openDialog({
              title: t('common.words.error'),
              type: 'error',
              content: err?.response?.data?.error,
            }),
          );
        })
        .finally(() => setSending(false));
    } catch (err) {
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: err.message,
        }),
      );
    }
  };

  const handleRemove = (IP: string, idx: number) => {
    if (idx === 0) {
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: t('asset.setupMachines.lastIpRemoveError'),
        }),
      );
    }
    if (!sending && idx !== 0) {
      machines.splice(idx, 1);
      setMachines([...machines]);

      dispatch(removeMachine(IP));
    }
  };

  const handleMachinesChange = (value: string, index: number) => {
    const newMachines = [...machines];
    dispatch(removeMachine(newMachines[index]));
    newMachines[index] = value;
    setMachines(newMachines);
  };

  useEffect(() => {
    const checkedIPs: string[] = [];
    let hasDuplicatedIP = false;

    for (let i = 0; i < machines.length; i++) {
      const p = machines[i];

      if (p && checkedIPs.some((peer) => peer === p)) {
        hasDuplicatedIP = true;
        break;
      }

      checkedIPs.push(p);
    }

    setDuplicatedIP(hasDuplicatedIP);
  }, [machines]);

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode) dispatch(changeMountedComponents('setupMachines'));
    // eslint-disable-next-line
  }, [chapterToChange]);

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <Typography variant="overline" style={{ fontSize: '35px' }}>
        <Trans>title.setupMachines.header</Trans>
      </Typography>

      <Container>
        <Paper>
          <Inputs>
            <TextField
              value={userName}
              variant="outlined"
              disabled={sending}
              className="username-input"
              label={t('common.forms.userName')}
              placeholder={t('common.forms.userName')}
              style={{ width: '250px', minWidth: '250px' }}
              onChange={(e) => setUserName(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle style={{ color: 'var(--primary)' }} />
                  </InputAdornment>
                ),
              }}
            />

            <div className="ssh-image-input" style={{ width: 'fit-content' }}>
              <FileInput
                cloudIcon
                file={file}
                accept=".cer, .pem"
                customMargin="10px"
                message={t('asset.setupMachines.sshKeyRequired')}
                customBtnStyle={{
                  margin: '0 20px',
                  height: '56px',
                  width: '250px',
                  maxWidth: '250px',
                }}
                containerStyle={{
                  padding: 0,
                  maxWidth: '400px',
                }}
                disabled={sending}
                onChange={(files) => {
                  if (files && files[0]) setFile(files[0]);
                  else setFile(null);
                }}
              />
            </div>
          </Inputs>

          <Peers>
            {machines.map((machine, idx) => (
              <Tooltip
                key={idx}
                title={
                  !duplicatedIP ? (
                    ''
                  ) : (
                    <Typography
                      color="inherit"
                      style={{ fontSize: '18px', textAlign: 'center' }}
                    >
                      <Trans>asset.setupMachines.hasDuplicatedIPs</Trans>
                    </Typography>
                  )
                }
              >
                <TextField
                  value={machine}
                  disabled={sending}
                  variant="outlined"
                  className="address-input"
                  label={t('common.forms.instanceAddress')}
                  style={{ margin: '10px 0 0 16px', width: '250px' }}
                  // the double "not" is to the machine be used as boolean and not string
                  error={(!validHost(machine) && !!machine) || duplicatedIP}
                  onChange={(e) => handleMachinesChange(e.target.value, idx)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className="remove-address-btn"
                      >
                        <RemovePeerButton
                          onClick={() => handleRemove(machine, idx)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
            ))}
          </Peers>

          <Actions>
            <Button
              fullWidth
              color="primary"
              disabled={sending}
              startIcon={<Add />}
              className="add-more-addresses"
              style={{ marginBottom: '20px' }}
              onClick={() => setMachines([...machines, ''])}
            >
              <Trans>button.newMachine</Trans>
            </Button>

            {sending ? (
              <Box>
                <OperationStateCard taskOperation={setupMachinesState} />
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  onClick={() => cancelRequest()}
                >
                  <Trans>button.cancel</Trans>
                </Button>
              </Box>
            ) : (
              <Button
                fullWidth
                color="primary"
                disabled={sending}
                variant="contained"
                className="start-setup"
                onClick={() => sendData()}
              >
                <Trans>button.start</Trans>
              </Button>
            )}
          </Actions>
        </Paper>

        {userName ? (
          <div style={{ margin: '0 auto' }}>
            <LoadingContainer
              cardInfo={{
                type: 'setup',
                running: sending,
                title: 'Setup Machines',
                canShow: userName !== '',
                attributes: {
                  username: userName,
                  sshImage: file
                    ? file.name
                    : t('asset.setupMachines.insertSSHImage'),
                },
              }}
            />
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export default SetupMachines;
