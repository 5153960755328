import styled, { css } from 'styled-components';
import { Paper, Container, ListItem, Typography } from '@material-ui/core';

interface IListItemProps {
  prop?: boolean;
  selected: boolean;
  isDisabled: boolean;
  lineThrough?: boolean;
}

interface ISelectContainerProps {
  open: boolean;
}

export const ComponentContainer = styled.div`
  margin: 0;
  position: relative;
  height: fit-content;
  width: 100% !important;
  background-color: ${(props: { disabled: boolean }) =>
    props.disabled ? 'rgb(239, 239, 239, 0.6)' : ''};
`;

export const SelectContainer = styled(Container)`
  && {
    margin: 0;
    width: 100%;
    height: 39px;
    display: flex;
    padding: 18px;
    border-radius: 4px;
    align-items: center;
    min-width: fit-content;
    justify-content: space-between;
    border: ${(props: ISelectContainerProps) =>
      props.open ? '2px solid #2384FF' : '1px solid rgba(0, 0, 0, 0.23)'};
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  width: 100% !important;
`;

export const OptionsCard = styled(Paper).attrs({
  elevation: 4,
})`
  color: var(--black);
  display: flex;
  width: '100%';
  max-height: 450px;
  align-items: center;
  height: fit-content;
  padding-bottom: 15px;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ListSubheader = styled.div`
  display: flex;
  padding: 0 10px;
  align-items: center;
  flex-flow: column wrap;
  justify-content: center;
`;

export const AtLeastOneMsg = styled(Typography).attrs({
  component: 'span',
})`
  && {
    color: #ffcc32;
    display: flex;
    font-weight: bold;
    align-items: center;
    margin: 0 auto 10px auto;
  }
`;

export const SelectedListItem = styled(ListItem).attrs({
  button: true,
})<IListItemProps>`
  && {
    margin: 0;
    height: 39px;
    /* Disabled CSS */
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        color: var(--disabled);
        cursor: default;
        background-color: transparent;
        pointer-events: ${(props: IListItemProps) =>
          props.prop ? 'auto' : 'none'};
        text-decoration: ${(props: IListItemProps) =>
          props.lineThrough ? 'line-through' : 'none !imporant'};
      `}
    /* Selected CSS */
    ${({ selected }) =>
      selected &&
      css`
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.08);
      `}
  }
`;
