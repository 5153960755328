import { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import i18n from 'i18next';
import { store } from '../../store';
import { openDialog, DialogInfoType } from '../../store/Dialog';
import { changeUserName } from '../../store/User';

import { changeSelectedNetInfo } from '../../store/Network';
import { StoreState } from '../../store/types';
import authTokenHandler from '../authTokenHandler';

const openModal = (type: DialogInfoType, title: string, content: string) => {
  store.dispatch(openDialog({ type, title, content }));
};

const successOperationAction = (
  operation: string,
  state: StoreState,
  response: AxiosResponse,
) => {
  if (operation === 'ping') {
    console.log('USER: ', response.headers, response.data);
    store.dispatch(changeUserName(response.headers.username));
  } else if (operation === 'networkstate') {
    const newNetJson = JSON.stringify(response.data);

    if (state.networkState.selectedNetwork.json !== newNetJson) {
      store.dispatch(
        changeSelectedNetInfo({
          type: 'net',
          jsonError: null,
          json: newNetJson,
          name: response.data.states[0].networkDefs.networkName,
        }),
      );
    }
  }
};

export const responseSuccessHandler = (response: AxiosResponse) => {
  const state: StoreState = store.getState();
  const { operationsWithSuccessModal } = state.appStatusState;
  const operation = response.config.url?.replace('/', '') || '';

  successOperationAction(operation, state, response);

  const hasModal = operationsWithSuccessModal.some(
    (op) => op === operation && operation !== 'startnetwork',
  );

  if (hasModal) {
    openModal(
      'success',
      i18n.t('common.words.success'),
      i18n.t(`common.requestResponses.success.${operation}`),
    );
  }

  return Promise.resolve(response);
};

export const responseErrorHandler = (
  error: AxiosError,
): Promise<AxiosError> => {
  const { response, message } = error;
  const state: StoreState = store.getState();
  const operation = response?.config.url?.replace('/', '');
  const { isLogged, operationsWithErrorModal } = state.appStatusState;

  if (error.toString() === 'Cancel') {
    openModal(
      'error',
      i18n.t('common.words.error'),
      i18n.t('common.messages.requestCancelledByUser'),
    );
  }

  if (response && isLogged && response.status === 401) {
    const canRedirect =
      window.location.pathname !== '/' || response.config.url !== '/ping';

    authTokenHandler(false, true, 401, canRedirect);
  }

  if (
    operationsWithErrorModal.some(
      (op) => op === operation || op === operation?.toLowerCase(),
    )
  ) {
    if (error?.response?.data?.error) {
      openModal(
        'error',
        i18n.t('common.words.error'),
        error?.response?.data?.error,
      );

      return Promise.reject(
        error?.response?.data?.error || error?.response?.data || error,
      );
    }

    if (response && response?.status >= 500 && response?.status <= 599) {
      openModal(
        'error',
        i18n.t('common.words.error'),
        `${i18n.t(
          `common.reports.error.${operation?.toLowerCase()}`,
        )}. ${i18n.t('common.requestResponses.error.general')}`,
      );
    } else {
      const errorMessage = message.includes('Request failed with status code')
        ? response?.request.response
        : message;

      openModal('error', i18n.t('common.words.error'), errorMessage);
    }
  }

  return Promise.reject(
    error?.response?.data?.error || error?.response?.data || error,
  );
};

export const requestSuccessHandler = (config: AxiosRequestConfig) => {
  let newConfig: AxiosRequestConfig = {};
  const state: StoreState = store.getState();
  const { selectedNetwork } = state.networkState;
  const { language, operationsSendNetNameParam } = state.appStatusState;

  const sendNetName = operationsSendNetNameParam.some(
    (op) => op === config.url?.replace('/', ''),
  );

  newConfig = {
    ...config,
    params: {
      ...config.params,
      language: language === 'ptbr' ? 'pt' : language,
      ...(sendNetName && { networkName: selectedNetwork.name }),
    },
  };

  return newConfig;
};

export const requestErrorHandler = (error: object) => {
  return Promise.reject(error);
};
