import React, { useCallback, useState } from 'react';
import {
  Card,
  Typography,
  CardContent,
  InputAdornment,
  Box,
  makeStyles,
  Theme,
  createStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Error as Warning, TrendingUpOutlined } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import GenericInput from '../Screens/Deploy/Components/GenericInput';
import PeerAdder from '../Screens/Deploy/Components/PeerAdder';
import {
  Form,
  CAContainer,
  MustSaveThis,
  DefineOrgsFormVertical,
} from '../Screens/Deploy/styles';
import { ipPatt, fqdnPatt, orgNamePatt } from '../utils/regexPatterns';
import { useNetworks } from '../Contexts/Networks';
import { useValidatePort } from '../Hooks/useValidatePort';

interface IOrganizationFormProps {
  org: IOrg;
  hasCCRest?: boolean;
  disableInputs: boolean;
  handleChange: (val: any, field: string) => void;
  isAddOrg?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(12),
      flexBasis: '30%',
      flexShrink: 0,
      textTransform: 'uppercase',
      alignSelf: 'center',
      letterSpacing: '0.08333em',
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.text.secondary,
      alignSelf: 'center',
    },
  }),
);

const OrganizationForm: React.FC<IOrganizationFormProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { org, handleChange, disableInputs, hasCCRest, isAddOrg } = props;

  const [isNameValid, setIsNameValid] = useState<boolean>(true);

  const [isDefaultPort, setIsDefaultPort] = useState(!org?.caPort);
  const [isDefaultOperationPort, setIsDefaultOperationPort] = useState(
    !org?.caOperationPort,
  );
  const [isDefaultNetAPIPort, setIsDefaultNetAPIPort] = useState(
    !org?.netAPIPort,
  );
  const [isDefaultPrometheusPort, setIsDefaultPrometheusPort] = useState(
    !org?.prometheusPort,
  );
  const [isDefaultGrafanaPort, setIsDefaultGrafanaPort] = useState(
    !org?.grafanaPort,
  );

  const { selectedNetwork } = useNetworks();

  const validatePort = useValidatePort();

  const onChange = useCallback(
    (e) => {
      // if the field is the name, check if the name is already in use
      if (isAddOrg && selectedNetwork) {
        const isNameInUse = Object.keys(selectedNetwork?.organizations).some(
          (o) => o === e.target.value,
        );
        setIsNameValid(!isNameInUse);
      }

      handleChange(e.target.value, 'orgName');
    },
    [handleChange, isAddOrg, selectedNetwork],
  );

  return (
    <Card style={{ background: 'linear-gradient(45deg, whitesmoke, snow)' }}>
      <CardContent style={{ display: 'flex', margin: '20px' }}>
        <Form>
          <Typography variant="overline">
            <Trans>asset.organizations.addOrg.formTitle</Trans>
          </Typography>
          <div className="org-name-and-domain">
            <DefineOrgsFormVertical>
              <Box display="flex">
                <GenericInput
                  variant="standard"
                  value={org.orgName}
                  disabled={disableInputs}
                  onChange={onChange}
                  label={t('asset.organizations.addOrg.orgName')}
                  error={!orgNamePatt.test(org.orgName) || !isNameValid}
                  helperText={
                    !isNameValid && 'Organization name already in use'
                  }
                />
              </Box>
              <GenericInput
                variant="standard"
                value={org.orgDomainName}
                disabled={disableInputs}
                onChange={(e) => handleChange(e.target.value, 'orgDomainName')}
                label={t('asset.organizations.addOrg.domainName')}
                error={org.orgDomainName.indexOf(' ') >= 0}
              />
            </DefineOrgsFormVertical>
          </div>

          <Typography variant="overline">
            <Trans>asset.organizations.addOrg.CAInfo</Trans>
          </Typography>
          <CAContainer className="org-ca-info">
            <DefineOrgsFormVertical>
              <GenericInput
                value={org.caIP}
                variant="standard"
                disabled={disableInputs}
                label={<Trans>asset.organizations.addOrg.CAAddress</Trans>}
                onChange={(e) => handleChange(e.target.value, 'caIP')}
                error={
                  !(ipPatt.test(org.caIP) || fqdnPatt.test(org.caIP)) &&
                  org.caIP.length >= 1
                }
                helperText={
                  <Typography component="span" style={{ fontSize: '12px' }}>
                    <Trans>asset.network.deploy.messages.CAIPAddress</Trans>
                  </Typography>
                }
              />

              <GenericInput
                value={org.user}
                variant="standard"
                disabled={disableInputs}
                onChange={(e) => handleChange(e.target.value, 'user')}
                label={<Trans>common.forms.CAUser</Trans>}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Warning style={{ color: 'var(--warning)' }} />
                    </InputAdornment>
                  ),
                }}
                helperText={
                  <MustSaveThis>
                    <Trans>common.messages.mustSaveThis</Trans>
                  </MustSaveThis>
                }
              />
              <GenericInput
                type="password"
                value={org.passwd}
                variant="standard"
                disabled={disableInputs}
                onChange={(e) => handleChange(e.target.value, 'passwd')}
                label={<Trans>common.forms.CAPassword</Trans>}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Warning style={{ color: 'var(--warning)' }} />
                    </InputAdornment>
                  ),
                }}
                helperText={
                  <MustSaveThis>
                    <Trans>common.messages.mustSaveThis</Trans>
                  </MustSaveThis>
                }
              />
              <GenericInput
                type="password"
                variant="standard"
                disabled={disableInputs}
                value={org.confirmPasswd}
                error={
                  org.confirmPasswd.indexOf(' ') >= 0 ||
                  org.passwd !== org.confirmPasswd
                }
                label={
                  <Trans>asset.organizations.addOrg.repeatCAPassword</Trans>
                }
                onChange={(e) => handleChange(e.target.value, 'confirmPasswd')}
                helperText={
                  <Typography component="span" style={{ fontSize: '12px' }}>
                    <Trans>
                      asset.network.deploy.messages.equalToCAPassword
                    </Trans>
                  </Typography>
                }
              />
            </DefineOrgsFormVertical>

            <Box>
              <Accordion
                style={{
                  boxShadow: 'none',
                  background: 'none',
                  border: '1px solid #ddd',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Advanced</Typography>
                  <Typography className={classes.secondaryHeading}>
                    Configure CA ports
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ flexDirection: 'column', gap: '1.5rem' }}
                >
                  <Box display="flex" alignItems="center">
                    <GenericInput
                      type="number"
                      value={isDefaultPort ? '' : org.caPort}
                      style={{ maxWidth: '256px' }}
                      label="Port"
                      onChange={(e) => handleChange(e.target.value, 'caPort')}
                      disabled={isDefaultPort}
                      helperText={(() => {
                        const isValid = validatePort(
                          org?.caPort as number,
                          org?.orgName,
                          'caPort',
                          org.caIP,
                        );
                        return isValid?.portInUse
                          ? `Port already in use by ${
                              isValid?.info?.org || isValid.info?.peer
                            }'s ${isValid.info?.portName}`
                          : '';
                      })()}
                      error={
                        validatePort(
                          org?.caPort as number,
                          org?.orgName,
                          'caPort',
                          org.caIP,
                        )?.portInUse
                      }
                    />
                    <Checkbox
                      onChange={(e) => {
                        handleChange('', 'caPort');
                        setIsDefaultPort(e.target.checked);
                      }}
                      checked={isDefaultPort}
                    />
                    <Typography>Default port</Typography>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <GenericInput
                      type="number"
                      value={isDefaultOperationPort ? '' : org.caOperationPort}
                      style={{ maxWidth: '256px' }}
                      label="Operation Port"
                      onChange={(e) =>
                        handleChange(e.target.value, 'caOperationPort')
                      }
                      disabled={isDefaultOperationPort}
                      helperText={(() => {
                        const isValid = validatePort(
                          org?.caOperationPort as number,
                          org?.orgName,
                          'caOperationPort',
                          org.caIP,
                        );
                        return isValid?.portInUse
                          ? `Port already in use by ${
                              isValid?.info?.org || isValid.info?.peer
                            }'s ${isValid.info?.portName}`
                          : '';
                      })()}
                      error={
                        validatePort(
                          org?.caOperationPort as number,
                          org?.orgName,
                          'caOperationPort',
                          org.caIP,
                        )?.portInUse
                      }
                    />
                    <Checkbox
                      onChange={(e) => {
                        handleChange('', 'caOperationPort');
                        setIsDefaultOperationPort(e.target.checked);
                      }}
                      checked={isDefaultOperationPort}
                    />
                    <Typography>Default operation port</Typography>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <GenericInput
                      type="number"
                      value={isDefaultNetAPIPort ? '' : org.netAPIPort}
                      style={{ maxWidth: '256px' }}
                      label="NetAPI Port"
                      onChange={(e) =>
                        handleChange(e.target.value, 'netAPIPort')
                      }
                      disabled={isDefaultNetAPIPort}
                      helperText={(() => {
                        const isValid = validatePort(
                          org?.netAPIPort as number,
                          org?.orgName,
                          'netAPIPort',
                          org.caIP,
                        );
                        return isValid?.portInUse
                          ? `Port already in use by ${
                              isValid?.info?.org || isValid.info?.peer
                            }'s ${isValid.info?.portName}`
                          : '';
                      })()}
                      error={
                        validatePort(
                          org?.netAPIPort as number,
                          org?.orgName,
                          'netAPIPort',
                          org.caIP,
                        )?.portInUse
                      }
                    />
                    <Checkbox
                      onChange={(e) => {
                        handleChange('', 'netAPIPort');
                        setIsDefaultNetAPIPort(e.target.checked);
                      }}
                      checked={isDefaultNetAPIPort}
                    />
                    <Typography>Default NetAPI port</Typography>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <GenericInput
                      type="number"
                      value={isDefaultPrometheusPort ? '' : org.prometheusPort}
                      style={{ maxWidth: '256px' }}
                      label="Prometheus Port"
                      onChange={(e) =>
                        handleChange(e.target.value, 'prometheusPort')
                      }
                      disabled={isDefaultPrometheusPort}
                      helperText={(() => {
                        const isValid = validatePort(
                          org?.prometheusPort as number,
                          org?.orgName,
                          'prometheusPort',
                          org.caIP,
                        );
                        return isValid?.portInUse
                          ? `Port already in use by ${
                              isValid?.info?.org || isValid.info?.peer
                            }'s ${isValid.info?.portName}`
                          : '';
                      })()}
                      error={
                        validatePort(
                          org?.prometheusPort as number,
                          org?.orgName,
                          'prometheusPort',
                          org.caIP,
                        )?.portInUse
                      }
                    />
                    <Checkbox
                      onChange={(e) => {
                        handleChange('', 'prometheusPort');
                        setIsDefaultPrometheusPort(e.target.checked);
                      }}
                      checked={isDefaultPrometheusPort}
                    />
                    <Typography>Default Prometheus port</Typography>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <GenericInput
                      type="number"
                      value={isDefaultGrafanaPort ? '' : org.grafanaPort}
                      style={{ maxWidth: '256px' }}
                      label="Grafana Port"
                      onChange={(e) =>
                        handleChange(e.target.value, 'grafanaPort')
                      }
                      disabled={isDefaultGrafanaPort}
                      helperText={(() => {
                        const isValid = validatePort(
                          org?.grafanaPort as number,
                          org?.orgName,
                          'grafanaPort',
                          org.caIP,
                        );
                        return isValid?.portInUse
                          ? `Port already in use by ${
                              isValid?.info?.org || isValid.info?.peer
                            }'s ${isValid.info?.portName}`
                          : '';
                      })()}
                      error={
                        validatePort(
                          org?.grafanaPort as number,
                          org?.orgName,
                          'grafanaPort',
                          org.caIP,
                        )?.portInUse
                      }
                    />
                    <Checkbox
                      onChange={(e) => {
                        handleChange('', 'grafanaPort');
                        setIsDefaultGrafanaPort(e.target.checked);
                      }}
                      checked={isDefaultGrafanaPort}
                    />
                    <Typography>Default Grafana port</Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </CAContainer>
          <div className="org-instances">
            <Typography variant="overline">
              <Trans>common.words.instances</Trans>
            </Typography>
            <PeerAdder
              peers={org.peers}
              hasCCRest={hasCCRest}
              disableInputs={disableInputs}
              handleChange={(value) => handleChange(value, 'peers')}
            />
          </div>
        </Form>
      </CardContent>
    </Card>
  );
};

export default OrganizationForm;
