import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import {
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
} from '@material-ui/core';
import {
  AccountCircle,
  Email,
  Person,
  Close,
  DateRange,
} from '@material-ui/icons';
import { networkApi } from '../../Common/axios';

import { openDialog } from '../../store/Dialog';
import { StoreState } from '../../store/types';

import Tooltip from '../../AppComponents/Tooltip';
import CCDescriptionModal, {
  ITemplates,
} from '../../AppComponents/CCDescriptionModal';

import {
  PageTitle,
  Container,
  Card,
  UserAvatar,
  Field,
  TitleGroup,
  ContentText,
  PlanButton,
  TopicPoint,
} from './styles';

const planNames: { [index: number]: string } = {
  0: 'No Plan',
  1: 'Trial',
  2: 'Standard',
  3: 'Business',
  4: 'Enterprise',
  5: 'Exclusive',
  9: 'Developer',
};

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userName } = useSelector((state: StoreState) => state.userState);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [purchase, setPurchase] = useState('');
  const [expiration, setExpiration] = useState('');
  const [plan, setPlan] = useState(0);
  const [chaincodes, setChaincodes] = useState({} as ITemplates);
  const [selectedChaincodeIndex, setSelectedChaincodeIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openPlanModal, setOpenPlanModal] = useState(false);
  const [openCCModal, setOpenCCModal] = useState(false);

  const handleClose = useCallback(() => {
    setOpenPlanModal(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpenPlanModal(true);
  }, []);

  const renderPlanDetails = useCallback(() => {
    const description = t(`plans.${planNames[plan]}`) as string;
    const topics = description.split(';');

    return topics.map((topic) => (
      <div
        style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}
        key={topic}
      >
        <TopicPoint />
        <ContentText>{topic};</ContentText>
      </div>
    ));
  }, [plan, t]);

  useEffect(() => {
    setLoading(true);

    networkApi
      .get('/getprofile')
      .then((res) => {
        setEmail(res.data.email);
        setName(res.data.name);
        setPlan(res.data.plan);
        setPurchase(res.data.purchase);
        setExpiration(res.data.expiration);
        setChaincodes(res.data.chaincodes);
      })
      .catch((error) => {
        dispatch(
          openDialog({
            type: 'error',
            title: t('common.words.error'),
            content: error.message,
          }),
        );
      })
      .finally(() => setLoading(false));
  }, [dispatch, t]);

  return (
    <div style={{ margin: '0 auto' }}>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress style={{ marginTop: '30px' }} />
        </div>
      ) : (
        <>
          <PageTitle>
            <Trans>title.profile.header</Trans>
          </PageTitle>

          <Container>
            <Card>
              <UserAvatar>{userName.toUpperCase()[0]}</UserAvatar>

              <Tooltip message={t('common.words.details')}>
                <PlanButton type="button" onClick={handleOpen}>
                  <strong>
                    {t('asset.profile.plan')}:{' '}
                    {plan !== 0 ? planNames[plan] : t('plans.withoutPlan')}
                  </strong>
                </PlanButton>
              </Tooltip>

              <Field
                value={name}
                label={t('asset.profile.name')}
                variant="outlined"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person style={{ color: 'var(--primary)' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <Field
                value={userName}
                label={t('common.forms.userName')}
                variant="outlined"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle style={{ color: 'var(--primary)' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <Field
                value={email}
                label={t('asset.profile.email')}
                variant="outlined"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email style={{ color: 'var(--primary)' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <Field
                value={purchase}
                label={t('asset.profile.purchase')}
                variant="outlined"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRange style={{ color: 'var(--primary)' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <Field
                value={expiration}
                label={t('asset.profile.expiration')}
                variant="outlined"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRange style={{ color: 'var(--primary)' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <div style={{ maxWidth: '300px', marginTop: '20px' }}>
                <strong>Chaincodes:</strong>

                {Object.keys(chaincodes).map((item, idx) => (
                  <Button
                    key={idx}
                    onClick={() => {
                      setOpenCCModal(true);
                      setSelectedChaincodeIndex(idx);
                    }}
                  >
                    {chaincodes[item].name}
                  </Button>
                ))}
              </div>
            </Card>
          </Container>
        </>
      )}

      <CCDescriptionModal
        opened={openCCModal}
        closeModal={() => setOpenCCModal(!openCCModal)}
        templates={chaincodes}
        selectedIndex={selectedChaincodeIndex}
      />

      <Dialog
        open={openPlanModal}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
      >
        <DialogTitle
          style={{ color: 'var(--white)', backgroundColor: 'var(--primary)' }}
        >
          <TitleGroup>
            {planNames[plan]}

            <IconButton style={{ color: 'var(--white)' }} onClick={handleClose}>
              <Close />
            </IconButton>
          </TitleGroup>
        </DialogTitle>

        <DialogContent style={{ padding: '30px', maxHeight: 'fit-content' }}>
          <strong style={{ color: 'var(--primaryDark)', fontSize: '18px' }}>
            {t('asset.profile.planBenefits')}:
          </strong>

          {renderPlanDetails()}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Profile;
