import React, { useCallback, useEffect, useMemo } from 'react';
import Axios from 'axios';
import {
  List,
  Button,
  ListItem,
  Checkbox,
  TextField,
  Typography,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  IconButton,
  Box,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { BackspaceOutlined } from '@material-ui/icons';
import history from '../../../history';
import { networkApi } from '../../../Common/axios';

import { openDialog } from '../../../store/Dialog';
import { useAddPeerForm } from '../../../Hooks/addPeer';
import { addOperation, removeOperation } from '../../../store/AppStatus';

import { StoreState } from '../../../store/types';
import { ISelectedNetwork } from '../../../store/Network';
import { clearAddPeerNotifications } from '../../../store/AddPeerNotifications';
import {
  Card,
  Section,
  LoadContainer,
  PageContainer,
  SelectListItem,
} from './styles';

import LoadingScreen from '../../LoadingScreen';
import CAauthForm from '../../../AppComponents/CAauth';
import { ipPatt, fqdnPatt } from '../../../utils/regexPatterns';
import SelectNetwork from '../../../AppComponents/SelectNetwork';
import LoadingContainer from '../../../AppComponents/Notifications';
import cancelWithDialog from '../../../utils/cancelRequestWithModal';
import { mapChannelOrgs } from '../../../utils/network/parseChannelMembers';
import { useNetworks } from '../../../Contexts/Networks';
import { OperationStateCard } from '../../../AppComponents/OperationStateCard';
import { useOperation } from '../../../Contexts/Operation';

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const AddPeer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { selectedNetwork, fetchNetworkState, getAllNetworks } = useNetworks();

  const { addPeerState } = useOperation();

  const {
    loading,
    setLoading,
    peers,
    setPeers,
    sendingData,
    setSendingData,
    CAauth,
    port,
    setPort,
    operationPort,
    setOperationPort,
    setCAauth,
    selectedOrg,
    setOrgsPeers,
    setSelectedOrg,
    peerAlreadyExists,
    channelDefs,
    setChannelDefs,
    netStateOrgs,
    setNetStateOrgs,
    channelName,
    setChannelName,
    setOrgs,
    clearFormData,
  } = useAddPeerForm();

  const changeCAauth = useCallback((caAuth) => setCAauth(caAuth), [setCAauth]);

  //   useEffect(() => {
  //     if (selectedNetwork?.networkName && !sendingData) {
  //       clearFormData();
  //       setLoading(true);

  //       const config = { params: { networkName: selectedNetwork?.networkName } };

  //       networkApi
  //         .get(`/networkstate`, config)
  //         .then((res) => {
  //           const newOrgsPeers: Record<string, boolean> = {};
  //           const { organizations, networkDefs } = res.data.states[0];

  //           setNetStateOrgs(organizations);
  //           setChannelDefs(networkDefs.channelDefs);

  //           organizations.forEach((org: IOrg) => {
  //             Object.values(org.peers).forEach((item) => {
  //               newOrgsPeers[item.host] = true;
  //             });
  //           });

  //           setOrgsPeers(newOrgsPeers);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           dispatch(
  //             openDialog({
  //               type: 'error',
  //               title: t('common.words.error'),
  //               content: error.message.includes('Request failed with status code')
  //                 ? t('common.messages.failedToSelectNet')
  //                 : error.message,
  //             }),
  //           );
  //         })
  //         .finally(() => setLoading(false));
  //     }
  //     // eslint-disable-next-line
  //   }, [t, dispatch, selectedNetwork?.networkName, setLoading]);

  useEffect(() => {
    const key =
      selectedNetwork?.peers &&
      `peer${Object?.keys(selectedNetwork?.peers)?.length}.${
        selectedOrg?.name
      }.${selectedNetwork?.organizations?.[selectedOrg?.name]?.domain}`;

    if (key) setPeers({ [key]: { host: '' } });
  }, [operationPort, port, selectedNetwork, selectedOrg, setPeers]);

  const isHostInvalid = (host: string) =>
    !host ||
    (!(ipPatt.test(host) || fqdnPatt.test(host)) && host.length >= 1) ||
    peerAlreadyExists;

  const isPortInvalid = useCallback((currentPort: number) => {
    return Boolean(currentPort.toString().length !== 4);
  }, []);

  const sendData = () => {
    setSendingData(true);

    try {
      dispatch(clearAddPeerNotifications());

      if (!channelName) throw Error(t('common.messages.noChannelSelected'));
      if (!selectedOrg.name) throw Error(t('common.messages.noOrgSelected'));
      if (peerAlreadyExists)
        throw Error(t('common.messages.alreadyUsedPeerIP'));
      //   if (isHostInvalid()) throw Error(t('common.messages.invalidIP'));

      if (!CAauth.user || !CAauth.passwd)
        throw Error(t('common.messages.addCredentials'));

      if (isPortInvalid(port)) {
        throw Error(t('Invalid Port'));
      }

      if (isPortInvalid(operationPort)) {
        throw Error(t('Invalid Operation Port'));
      }

      console.log('CAauth', CAauth);

      const formData = new FormData();
      formData.append('payload', JSON.stringify({ peers }));
      formData.append('networkName', selectedNetwork?.networkName as string);
      formData.append('orgName', selectedOrg?.name as string);
      formData.append('channelName', channelName);
      formData.append('caUser', CAauth.user);
      formData.append('caPassword', CAauth.passwd);
      //   formData.append(
      //     'couchs',
      //     JSON.stringify({
      //       [`couch${Object.keys(peers)?.length}`]: {
      //         username: CAauth.user,
      //         password: CAauth.passwd,
      //         port,
      //       },
      //     }),
      //   );

      networkApi
        .post('/addPeer', formData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(c, t('title.nodes.peers.addPeer'), hasDialog);
            };

            cancelRequest = withDialogCancel;
            dispatch(
              addOperation({
                title: t('title.nodes.peers.addPeer'),
                cancel: withDialogCancel,
                name: 'addpeer',
                pathname: window.location.pathname,
              }),
            );
          }),
        })
        .then(async () => {
          await getAllNetworks();

          await fetchNetworkState();
          dispatch(removeOperation('addpeer', true));
          clearFormData();

          dispatch(
            openDialog({
              title: t('common.words.success'),
              type: 'success',
              content: 'Peer added successfully',
            }),
          );

          if (window.location.pathname === '/node/peer/add') {
            history.push('/dashboard');
          }
        })
        .catch((error) => {
          dispatch(removeOperation('addpeer', false));
        })
        .finally(() => setSendingData(false));
    } catch (error) {
      setSendingData(false);
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error.message,
        }),
      );
    }
  };

  const handleAddPeer = useCallback(() => {
    if (!selectedNetwork) return;
    const peerIndex =
      Object?.keys(peers)?.length +
      (Object?.keys(selectedNetwork?.peers)?.length || 0); // get next peerIndex and set as key in peers object
    const key =
      selectedNetwork?.peers &&
      `peer${peerIndex}.${selectedOrg?.name}.${
        selectedNetwork?.organizations?.[selectedOrg?.name]?.domain
      }`;
    if (key) setPeers({ ...peers, [key]: { host: '' } });
  }, [peers, selectedNetwork, selectedOrg, setPeers]);

  const handleRemovePeer = useCallback(
    (peerToRemove: string) => () => {
      delete peers[peerToRemove];

      setPeers({ ...peers });
    },
    [peers, setPeers],
  );

  const getOrgPeerObj = (
    stateOrg: INetStateOrg,
    chPeer: INetStateChannelDefOrg['Peers'][0],
  ) => ({
    value: stateOrg.peers[chPeer].host,
  });

  const getOrgObj = (stateOrg: INetStateOrg) => ({
    ip: stateOrg.restHost,
    name: stateOrg.orgName,
  });

  const getChannelMembers = useCallback(() => {
    const channelDef = channelDefs.find(
      (chDef) => chDef.channelName === channelName,
    );

    if (channelDef) {
      const { mappedOrgs } = mapChannelOrgs({
        getOrgObj,
        getOrgPeerObj,
        chOrgs: channelDef.orgs,
        separateNodesTypes: true,
        organizations: netStateOrgs,
      });

      setOrgs(mappedOrgs);
    }
  }, [channelName, netStateOrgs, channelDefs, setOrgs]);

  useEffect(() => {
    getChannelMembers();
  }, [channelName, getChannelMembers]);

  if (!selectedNetwork?.networkName) return <SelectNetwork />;

  if (loading)
    return (
      <div style={{ margin: '0 auto', textAlign: 'center' }}>
        <CircularProgress style={{ marginTop: '30px' }} />
      </div>
    );

  return (
    <div style={{ margin: '0 auto', textAlign: 'center' }}>
      <Typography variant="overline" style={{ fontSize: '25px' }}>
        <Trans>title.nodes.peers.addPeer</Trans>
      </Typography>

      <PageContainer>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Card>
            <div style={{ width: '100%', display: 'flex' }}>
              <Section>
                <Typography>
                  <Trans>common.forms.channelsToJoin</Trans>
                </Typography>

                <List>
                  {Object.keys(selectedNetwork.channels).map((channel) => (
                    <ListItem
                      button
                      divider
                      key={channel}
                      onClick={() => setChannelName(channel)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          disabled={sendingData}
                          checked={channel === channelName}
                        />
                      </ListItemIcon>

                      <ListItemText primary={channel} />
                    </ListItem>
                  ))}
                </List>
              </Section>

              <Section>
                <Typography>
                  <Trans>common.words.organizations</Trans>
                </Typography>

                {!channelName ? (
                  <Typography
                    variant="body1"
                    style={{ marginTop: 32, color: 'var(--darkGray)' }}
                  >
                    <Trans>common.messages.selectChannelFirst</Trans>
                  </Typography>
                ) : (
                  <List>
                    {Object.entries(selectedNetwork?.organizations)?.map(
                      ([orgName, orgData]) => (
                        <SelectListItem
                          key={orgName}
                          disabled={sendingData}
                          selected={selectedOrg.name === orgName}
                          onClick={() =>
                            setSelectedOrg({
                              ip: orgData?.netapi?.host,
                              name: orgName,
                            })
                          }
                        >
                          {`${orgName}-${orgData?.netapi?.host}`}
                        </SelectListItem>
                      ),
                    )}
                  </List>
                )}
              </Section>

              <Section>
                <Typography style={{ textAlign: 'left', marginBottom: 16 }}>
                  <Trans>common.forms.peerIP</Trans>
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ gap: '0.5rem' }}
                >
                  {Object.entries(peers)?.map(([peer, peerData]) => {
                    return (
                      <Box display="flex" alignItems="center">
                        <TextField
                          name="host"
                          value={peerData?.host}
                          style={{ width: '100%' }}
                          label={t('common.forms.peerAddress')}
                          error={
                            peerData?.host !== '' &&
                            isHostInvalid(peerData?.host)
                          }
                          disabled={sendingData || !selectedOrg.name}
                          InputLabelProps={{ shrink: true }}
                          onChange={({ target: { value } }) =>
                            setPeers({
                              ...peers,
                              [peer]: {
                                host: value,
                              },
                            })
                          }
                        />
                        <IconButton
                          onClick={handleRemovePeer(peer)}
                          size="small"
                          color="secondary"
                          edge="end"
                        >
                          <BackspaceOutlined />
                        </IconButton>
                      </Box>
                    );
                  })}

                  <IconButton
                    size="small"
                    color="primary"
                    edge="end"
                    onClick={handleAddPeer}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>

                <Typography style={{ textAlign: 'left', marginTop: '16px' }}>
                  <Trans>common.forms.CAauth</Trans>
                </Typography>

                <CAauthForm
                  initialState={CAauth}
                  callback={changeCAauth}
                  disabled={sendingData || !selectedOrg.name}
                  containerStyles={{ marginBottom: '16px' }}
                  userInputStyles={{ width: '100%', margin: '3px 0' }}
                  passwordInputStyles={{ width: '100%', margin: '3px 0' }}
                />
                {/*
                <TextField
                  name="port"
                  value={port}
                  type="number"
                  style={{ width: '100%' }}
                  label={t('common.forms.port')}
                  error={isPortInvalid(port)}
                  disabled={sendingData || !selectedOrg.name}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target: { value } }) => {
                    const number = parseInt(value, 10);

                    setPort(number);
                  }}
                />
                <TextField
                  name="operationPort"
                  value={operationPort}
                  type="number"
                  style={{ width: '100%' }}
                  label={t('common.forms.operationPort')}
                  error={isPortInvalid(operationPort)}
                  disabled={sendingData || !selectedOrg.name}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target: { value } }) => {
                    const number = parseInt(value, 10);

                    setOperationPort(number);
                  }}
                /> */}
              </Section>
            </div>

            {sendingData ? (
              <Box>
                <LoadContainer>
                  <LoadingScreen
                    content={t('asset.nodes.addPeer.loadingMessage')}
                  />

                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    onClick={() => cancelRequest()}
                  >
                    <Trans>button.cancel</Trans>
                  </Button>
                </LoadContainer>
              </Box>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={sendData}
                disabled={!selectedOrg}
              >
                <Trans>button.send</Trans>
              </Button>
            )}

            <OperationStateCard taskOperation={addPeerState} />
          </Card>
          {selectedOrg ? (
            <div>
              <LoadingContainer
                cardInfo={{
                  type: 'addpeer',
                  running: sendingData,
                  title: t('title.nodes.peers.addPeer'),
                  attributes: {
                    channelName,
                    peerIP: Object?.values(peers)?.[0]?.host,
                    orgName: selectedOrg.name,
                  },
                }}
              />
            </div>
          ) : null}
        </Box>
      </PageContainer>
    </div>
  );
};

export default AddPeer;
