import { Button, Box } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { networkApi } from '../../Common/axios';
import { useDeployForm } from '../../Hooks/deploy';
import { DeployAwsBucketOption } from './forms/AwsBucketOption';
import { DeployTemplateOption } from './forms/TemplateOption';
import { useNetworks } from '../../Contexts/Networks';
import { CCFileUpload } from '../../Forms/CCFileForm';
import { getTemplateList, ITemplate } from '../../store/TemplateCC';
import { ITemplateResponse } from '../Template/RenderOption';
import { Org, useUpgradeChaincodeForm } from '../../Hooks/UpgradeChaincode';

interface IOption {
  name: string;
  option: ChaincodeType;
}

type CCTypeOperation = 'upgradechaincode' | 'startnetwork';
export interface CCTypesProps {
  chaincode: IChaincodes;
  orgs: { orgName: string }[];
  // channelIndex: number;
  currentChaincode: string;
  handleCCTypeChange: (opt: ChaincodeType) => void;
  handleFileUpload: (file: FileList | null) => void;
  operation: CCTypeOperation;
  file: File | null;
  //   selectedTemplate?: string;
  handleSelectTemplate: (
    ccName: string,
    item: ITemplateResponse['key'],
  ) => void;
  handleSelectFromCloud: (
    ccName: string,
    item: ITemplateResponse['key'],
  ) => void;
  handleSelectTemplateDefinition: (v: any) => void;
}

export const CCTypes = ({
  chaincode,
  orgs,
  // channelIndex,
  currentChaincode,
  handleCCTypeChange,
  handleFileUpload,
  operation = 'startnetwork',
  file,
  handleSelectTemplate,
  handleSelectFromCloud,
  handleSelectTemplateDefinition,
}: CCTypesProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [loadingCloud, setLoadingCloud] = useState(false);

  const [
    preLoadedChaincodes,
    setPreLoadedChaincodes,
  ] = useState<ITemplateResponse>({} as ITemplateResponse);
  const [
    preLoadedTemplates,
    setPreLoadedTemplates,
  ] = useState<ITemplateResponse>({} as ITemplateResponse);

  const getPreLoadedChaincodes = () => {
    setLoadingTemplate(true);
    networkApi
      .get('/listAllFromBucket', { params: { bucket: 'cloud' } })
      .then((response) => {
        setPreLoadedChaincodes(response.data);
      })
      .catch(() => {
        setPreLoadedChaincodes({} as ITemplateResponse);
      })
      .finally(() => setLoadingTemplate(false));
  };

  const getPreLoadedTemplates = () => {
    setLoadingCloud(true);
    networkApi
      .get('/listAllFromBucket', { params: { bucket: 'template' } })
      .then((response) => {
        setPreLoadedTemplates(response.data);
      })
      .catch(() => {
        setPreLoadedTemplates({} as ITemplateResponse);
      })
      .finally(() => setLoadingCloud(false));
  };

  useEffect(() => {
    dispatch(getTemplateList());
    getPreLoadedChaincodes();
    getPreLoadedTemplates();
  }, [dispatch]);

  // const { channels, chaincodeIndex, orgs: deployOrgs } = useDeployForm();

  // const {
  //   orgs: upgradeCCOrgs,
  //   setOrgs: setUpgradeCCOrgs,
  // } = useUpgradeChaincodeForm();

  const { selectedNetwork, selectedChannel } = useNetworks();

  useEffect(() => {
    // if (operation !== 'upgradechaincode') return;
    // const orgs = selectedNetwork?.channels?.[selectedChannel]?.peers
    //   ? Object.keys(selectedNetwork?.channels?.[selectedChannel]?.peers)
    //   : [];
    // const orgNames = orgs.map((orgName) => ({ orgName }));
    // setUpgradeCCOrgs(orgNames as Org[]);
  }, []);

  const chaincodeOptions: IOption[] = [
    { option: 'template', name: t('common.words.template') },
    { option: 'aws-bucket', name: t('common.chaincode.cloudChaincode') },
    { option: 'form', name: t('common.chaincode.chaincodeFile') },
  ];

  //   const handleSelectFromCloud = (
  //     ccName: string,
  //     item: ITemplateResponse['key'],
  //   ) => {
  //     console.log(ccName, item);
  //   };

  const variantCCType = useCallback(
    (option: ChaincodeType) => {
      console.log('operation', option, chaincode?.ccType);

      if (operation === 'startnetwork' && option === chaincode?.ccType)
        return 'contained';

      if (
        operation === 'upgradechaincode' &&
        option === selectedNetwork?.chaincodes?.[currentChaincode]?.ccType
      )
        return 'contained';
      return 'outlined';
    },
    [chaincode, currentChaincode, operation, selectedNetwork],
  );

  const chaincodeInOperation = useMemo(() => {
    if (operation === 'startnetwork') return chaincode as IChaincodes;
    return selectedNetwork?.chaincodes[
      currentChaincode
    ] as ChaincodesInNetwork[''];
  }, [chaincode, currentChaincode, operation, selectedNetwork]);

  return (
    <Box>
      {operation !== 'upgradechaincode' && (
        <Box
          display="flex"
          style={{ gap: '2rem' }}
          justifyContent="center"
          margin="2rem 0"
        >
          {chaincodeOptions.map((opt: IOption) => (
            <Button
              key={opt.option}
              onClick={() => handleCCTypeChange(opt.option)}
              fullWidth
              variant={variantCCType(opt.option)}
              color="primary"
            >
              {opt.name}
            </Button>
          ))}
        </Box>
      )}

      {
        {
          template: (
            <DeployTemplateOption
              loading={loadingTemplate}
              preLoadedTemplates={preLoadedTemplates}
              handleSelectTemplate={handleSelectTemplate}
              // chaincode={chaincodeInOperation}
              chaincode={chaincode as IChaincodes}
              handleSelectTemplateDefinition={handleSelectTemplateDefinition}
              operation={operation}
              orgs={orgs as IOrg[]}
            />
          ),
          form: (
            <CCFileUpload
              handleFileUpload={handleFileUpload}
              file={file}
              //   channelIndex={channelIndex}
              // channelValue={channelIndex}
            />
          ),
          'aws-bucket': (
            <DeployAwsBucketOption
              loading={loadingCloud}
              chaincode={chaincodeInOperation}
              preLoadedChaincodes={preLoadedChaincodes}
              handleSelectFromCloud={handleSelectFromCloud}
            />
          ),
        }[
          operation === 'startnetwork' && chaincode
            ? chaincode.ccType
            : (selectedNetwork?.chaincodes?.[currentChaincode]
                ?.ccType as ChaincodeType)
        ]
      }
    </Box>
  );
};
