import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';

type SelectedPeers = Record<
  string,
  {
    ca: {
      caUser: string;
      caPassword: string;
    };
    ccApis: {
      host: string;
      restPort: number;
      TLSPort: number;
      chaincodeName: string;
      channelName: string;
      goinitus?: {
        port?: number;
      };
    }[];
  }
>;

interface AddChaincodeApiContextData {
  selectedPeers: SelectedPeers;
  setSelectedPeers: Dispatch<SetStateAction<SelectedPeers>>;
  addPeer: (orgName: string, host: string) => void;
  removePeer: (orgName: string, host: string) => void;
  setCACredentials: (
    orgName: string,
    credentials: { username?: string; password?: string },
  ) => void;
}

const AddChaincodeApiContext = createContext<AddChaincodeApiContextData>(
  {} as AddChaincodeApiContextData,
);

const AddChaincodeApiProvider: React.FC = ({ children }) => {
  const [selectedPeers, setSelectedPeers] = useState<SelectedPeers>({});

  console.log('selectedPeers: ', selectedPeers);

  const addPeer = useCallback(
    (orgName: string, host: string) => {
      // if peer is already selected, do nothing
      if (selectedPeers[orgName]?.ccApis?.find?.((peer) => peer.host === host))
        return;

      console.log('addPeer: ', orgName, host);

      setSelectedPeers((state) => ({
        ...state,
        [orgName]: {
          ...state[orgName],
          ca: {
            caUser: state[orgName]?.ca?.caUser || '',
            caPassword: state[orgName]?.ca?.caPassword || '',
          },
          ccApis: [
            ...(state?.[orgName]
              ? state[orgName]?.ccApis?.filter?.((peer) => peer.host !== host)
              : []),
            {
              host,
              restPort: 80,
              TLSPort: 443,
              chaincodeName: '',
              channelName: '',
              // goinitus: {
              //   port: 8080,
              // },
            },
          ],
        },
      }));
    },
    [selectedPeers],
  );

  const removePeer = useCallback(
    (orgName: string, host: string) => {
      const newPeers = selectedPeers[orgName]?.ccApis?.filter?.(
        (peer) => peer.host !== host,
      );

      // if newPeers is empty, remove it from the object
      if (newPeers.length === 0) {
        const { [orgName]: _, ...rest } = selectedPeers;
        setSelectedPeers(rest);

        return;
      }

      setSelectedPeers((state) => ({
        ...state,
        [orgName]: {
          ...state[orgName],
          ccApis: state[orgName]?.ccApis?.filter?.(
            (peer) => peer.host !== host,
          ),
        },
      }));
    },
    [selectedPeers],
  );

  const setCACredentials = useCallback(
    (
      orgName: string,
      credentials: { username?: string; password?: string },
    ) => {
      setSelectedPeers((state) => ({
        ...state,
        [orgName]: {
          ...state[orgName],
          ca: {
            caUser: credentials?.username || state[orgName]?.ca?.caUser,
            caPassword: credentials?.password || state[orgName]?.ca?.caPassword,
          },
        },
      }));
    },
    [],
  );

  return (
    <AddChaincodeApiContext.Provider
      value={{
        selectedPeers,
        setSelectedPeers,
        addPeer,
        removePeer,
        setCACredentials,
      }}
    >
      {children}
    </AddChaincodeApiContext.Provider>
  );
};

function useAddChaincodeApiForm(): AddChaincodeApiContextData {
  const context = useContext(AddChaincodeApiContext);

  if (!context) {
    throw new Error('Hook must be used within a Provider');
  }

  return context;
}

export { AddChaincodeApiProvider, useAddChaincodeApiForm };
