import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import Axios from 'axios';
import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import cancelWithDialog from '../../../utils/cancelRequestWithModal';
import { networkApi } from '../../../Common/axios';
import { useNetworks } from '../../../Contexts/Networks';
import { addOperation, removeOperation } from '../../../store/AppStatus';
import { openDialog } from '../../../store/Dialog';
import GenericInput from '../../Deploy/Components/GenericInput';
import { OperationStateCard } from '../../../AppComponents/OperationStateCard';
import { useOperation } from '../../../Contexts/Operation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRight: '1px solid #e0e0e0',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      height: 500,
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
  }),
);

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

export const Certificate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [credentials, setCredentials] = React.useState({
    caUser: '',
    caPassword: '',
  });

  const { selectedNetwork, setSelectedNetwork, networks } = useNetworks();
  if (!selectedNetwork && networks.length > 0) {
    setSelectedNetwork(networks[0]);
  }

  const [selectedOrg, setSelectedOrg] = React.useState('');

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { getClientCertState } = useOperation();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCredentials({
        caUser: '',
        caPassword: '',
      });
      setSelectedOrg(event.target.value);
    },
    [],
  );

  const [files, setFiles] = React.useState<Blob | null>(null);
  console.log('FILES: ', files);

  const handleDownload = useCallback(
    (data: any = null) => {
      console.log('handleDownload', data);

      const link = document.createElement('a');
      if (data) link.href = window.URL.createObjectURL(data);
      else if (files) link.href = window.URL.createObjectURL(files);

      link.download = 'certificate.tar';
      link.click();
    },
    [files],
  );

  const handleSubmit = useCallback(() => {
    if (!selectedNetwork) return;
    console.log('submit: ', credentials, selectedOrg);

    setIsSubmitting(true);
    try {
      const formData = new FormData();

      formData.append('networkName', selectedNetwork?.networkName);

      formData.append(
        'payload',
        JSON.stringify({ [selectedOrg]: credentials }),
      );

      networkApi
        .post('/getClientCert', formData, {
          responseType: 'blob',
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(c, 'Get certificate', hasDialog);
            };

            cancelRequest = withDialogCancel;
          }),
        })
        .then((res) => {
          setFiles(res.data);
          handleDownload(res.data);
          dispatch(
            openDialog({
              title: 'Success',
              type: 'success',
              content: 'Certificate',
            }),
          );
        })
        .catch((error) => {
          console.log('certificate error: ', error);
          dispatch(
            openDialog({
              title: 'Error',
              type: 'error',
              content: error,
            }),
          );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } catch (error) {
      console.log('error: ', error);
      dispatch(
        openDialog({
          title: 'Error',
          type: 'error',
          content: error?.message,
        }),
      );
    } finally {
      setIsSubmitting(false);
    }
  }, [credentials, dispatch, handleDownload, selectedNetwork, selectedOrg]);

  return (
    <Box
      margin="0 auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography variant="overline" style={{ fontSize: '25px' }}>
        Get client certificate
      </Typography>

      <Box
        display="grid"
        style={{ gridTemplateColumns: '1fr 1fr', gap: '2rem' }}
      >
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{
              display: 'flex',
              alignItems: 'end',
              height: '24px',
            }}
          >
            Organization
            {!selectedOrg && <Warning style={{ color: 'var(--warning)' }} />}
          </FormLabel>

          <RadioGroup
            aria-label="org"
            name="org"
            value={selectedOrg || ''}
            onChange={handleChange}
          >
            {selectedNetwork?.channels &&
              Object.keys(selectedNetwork?.organizations).map((orgName) => {
                console.log('orgName: ', orgName);
                return (
                  <FormControlLabel
                    value={orgName}
                    control={<Radio />}
                    label={orgName}
                    //   checked={}
                  />
                );
              })}
          </RadioGroup>
        </FormControl>

        <Box
          display="flex"
          flexDirection="column"
          style={{
            gap: '1rem',
          }}
        >
          <FormLabel
            style={{
              marginBottom: '0.25rem',
              display: 'flex',
              alignItems: 'end',
              height: '24px',
            }}
          >
            CA Credentials
            {!(credentials.caUser && credentials.caPassword) && (
              <Warning
                style={{ color: 'var(--warning)', marginLeft: '0.25rem' }}
              />
            )}
          </FormLabel>
          <GenericInput
            value={credentials.caUser}
            variant="standard"
            onChange={(e) =>
              setCredentials({ ...credentials, caUser: e.target.value })
            }
            label={<Trans>common.forms.CAUser</Trans>}
            style={{
              margin: '0',
              width: '256px',
            }}
          />
          <GenericInput
            type="password"
            value={credentials.caPassword}
            variant="standard"
            onChange={(e) =>
              setCredentials({ ...credentials, caPassword: e.target.value })
            }
            label={<Trans>common.forms.CAPassword</Trans>}
            style={{
              margin: '0',
              width: '256px',
            }}
          />
        </Box>
      </Box>

      {files && (
        <Button
          color="primary"
          variant="contained"
          className="deploy-start-btn"
          disabled={!files}
          style={{ width: '40%', margin: '2rem auto' }}
          onClick={() => handleDownload(files)}
        >
          Download certificate
        </Button>
      )}

      <Button
        color="primary"
        variant="contained"
        className="deploy-start-btn"
        disabled={
          isSubmitting ||
          !selectedNetwork ||
          !selectedOrg ||
          !credentials.caUser ||
          !credentials.caPassword ||
          Boolean(files)
        }
        style={{ width: '40%', margin: '2rem auto' }}
        onClick={handleSubmit}
      >
        Get certificate
      </Button>

      {!files && <OperationStateCard taskOperation={getClientCertState} />}
    </Box>
  );
};

export default Certificate;
