import Axios from 'axios';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Button, Typography } from '@material-ui/core';
import history from '../../history';
import { networkApi } from '../../Common/axios';

import { StoreState } from '../../store/types';
import { openDialog } from '../../store/Dialog';
import { ITemplate } from '../../store/TemplateCC';
import CustomTimeoutModal from '../../AppComponents/CustomTimeoutModal';
import {
  addOperation,
  Operation,
  removeOperation,
} from '../../store/AppStatus';
import {
  Org,
  Peer,
  useUpgradeChaincodeForm,
} from '../../Hooks/UpgradeChaincode';
import { clearChaincodeNotifications } from '../../store/ChaincodeNotifications';

import SelectNetwork from '../../AppComponents/SelectNetwork';
import cancelWithDialog from '../../utils/cancelRequestWithModal';
import { UpgradeCCForm } from '../../Forms/UpgradeCCForm';
import { CCTypes } from '../../AppComponents/CCTypes';
import { useNetworks } from '../../Contexts/Networks';
import DefineEndorsement from '../../AppComponents/Endorsement/DefineEndorsement';
import LoadingScreen from '../LoadingScreen';
import Tooltip from '../../AppComponents/Tooltip';

import canRunOperation from '../../utils/canRunOperation';
import { OperationStateCard } from '../../AppComponents/OperationStateCard';
import { useOperation } from '../../Contexts/Operation';
import { parseNetStateTemplate } from '../../utils/template';
import { useOPHistory } from '../../Contexts/OPHistory';

type RequestPeer = {
  ip: string;
  name: string;
  ccApi: boolean;
  webClient: boolean;
  grpcTimeout: number;
};

type TargetValue = {
  netApiIP: string;
  peers: RequestPeer[];
};

type Targets = Record<string, TargetValue>;

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const UpgradeChaincode = () => {
  const { t } = useTranslation();
  const dispatch = useCallback(useDispatch(), []);

  const {
    sending,
    setSending,
    version,
    clearFormData,
  } = useUpgradeChaincodeForm();

  const {
    selectedNetwork: originalSelectNetwork,
    // setSelectedNetwork,
    selectedChannel,

    getAllNetworks,
    fetchNetworkState,
  } = useNetworks();

  const { templateOption } = useSelector(
    (state: StoreState) => state.templateCCState,
  );
  const {
    orgs,
    setOrgs,
    selectedChaincode,
    peers,
    ccFile,
    setCcFile,
    grpcTimeout,
    customTimeoutModalOpened,
    setCustomTimeoutModalOpened,
    setGrpcTimeout,
    endorsement,
    setEndorsement,
    endorsementGUI,
    setEndorsementGUI,
    setNetDefTemplate,
    netDefTemplate,
    selectedNetwork,
    setSelectedNetwork,
  } = useUpgradeChaincodeForm();

  useEffect(() => {
    const newOrgs = selectedNetwork?.channels?.[selectedChannel]?.peers
      ? Object.keys(selectedNetwork?.channels?.[selectedChannel]?.peers)
      : [];
    const orgNames = newOrgs.map((orgName) => ({ orgName }));
    setOrgs(orgNames as Org[]);
  }, [selectedChannel, selectedNetwork, setOrgs]);

  useEffect(() => {
    console.log('RODOU!');
    if (originalSelectNetwork) {
      setSelectedNetwork(originalSelectNetwork);
    }
  }, [originalSelectNetwork, setSelectedNetwork]);

  const { getHistory } = useOPHistory();

  const validatePeers = (peersValidation: Peer) => {
    if (Object.values(peersValidation)?.length < 1)
      throw Error('Check at least one peer to be upgraded');
  };

  const validateVersion = useCallback(() => {
    if (
      selectedNetwork?.chaincodes[selectedChaincode]?.version === version ||
      !version
    )
      throw Error('Upgrade the chaincode version');
  }, [selectedChaincode, selectedNetwork, version]);

  const { selectedTemplate }: { selectedTemplate: ITemplate } = templateOption;

  const sendData = useCallback(() => {
    console.log('selectedTemplate', selectedTemplate);

    try {
      setSending(true);
      dispatch(clearChaincodeNotifications());
      const formData = new FormData();
      const templateDef = selectedTemplate;
      const ccBaseName =
        selectedNetwork?.chaincodes[selectedChaincode]?.ccBaseName;
      const chaincode = {
        [selectedChaincode]: {
          ccType: selectedNetwork?.chaincodes[selectedChaincode]?.ccType,
          tarName: selectedNetwork?.chaincodes[selectedChaincode]?.tarName,
          ...(ccBaseName ? { ccBaseName } : {}),
          ...(templateDef ? { templateDef } : {}),
          version,
          label: `${selectedChaincode}_${version}`,
          peers,
        },
      };

      formData.append('payload', JSON.stringify(chaincode));
      if (ccFile) formData.append('ccFiles', ccFile);

      formData.append('networkName', selectedNetwork?.networkName as string);
      formData.append('channelName', selectedChannel);

      validateVersion();
      validatePeers(peers);
      setSending(true);
      networkApi
        .post('/upgradechaincode', formData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(
                c,
                t('title.chaincodes.upgradeChaincode'),
                hasDialog,
              );
            };

            cancelRequest = withDialogCancel;
            dispatch(
              addOperation({
                title: t('title.chaincodes.upgradeChaincode'),
                pathname: window.location.pathname,
                name: 'upgradechaincode',
                cancel: withDialogCancel,
              }),
            );
          }),
        })
        .then(async () => {
          await getAllNetworks();

          await fetchNetworkState();
          dispatch(removeOperation('upgradechaincode', true));
          clearFormData();

          dispatch(
            openDialog({
              title: t('common.words.success'),
              type: 'success',
              content: 'Chaincode upgraded successfully',
            }),
          );

          if (window.location.pathname === '/chaincode/upgrade') {
            history.push('/dashboard');
          }
        })
        .catch(() => {
          dispatch(removeOperation('upgradechaincode', false));
          setSending(false);
        });
    } catch (error) {
      dispatch(removeOperation('upgradechaincode', false));
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error?.message,
        }),
      );
      console.log(error);
      setSending(false);
    } finally {
      getHistory();
    }
  }, [
    ccFile,
    clearFormData,
    dispatch,
    fetchNetworkState,
    getAllNetworks,
    getHistory,
    peers,
    selectedChaincode,
    selectedChannel,
    selectedNetwork,
    selectedTemplate,
    setSending,
    t,
    validateVersion,
    version,
  ]);

  const handleCCTypeChange = useCallback(
    (opt: ChaincodeType) => {
      if (selectedNetwork?.chaincodes?.[selectedChaincode]) {
        selectedNetwork.chaincodes[selectedChaincode].ccType = opt;
        setSelectedNetwork({ ...selectedNetwork });
      }
    },
    [selectedChaincode, selectedNetwork, setSelectedNetwork],
  );

  const handleFileUpload = useCallback(
    (file: FileList | null) => {
      setCcFile(file?.[0] as File);
    },
    [setCcFile],
  );

  const handleSelectTemplate = (ccName: string) => {
    if (selectedNetwork?.chaincodes?.[selectedChaincode]) {
      selectedNetwork.chaincodes[selectedChaincode].ccBaseName = ccName;

      setSelectedNetwork({ ...selectedNetwork });
    }
  };

  const handleSelectFromCloud = (ccName: string) => {
    if (selectedNetwork?.chaincodes?.[selectedChaincode]) {
      console.log('ccName', ccName);
      selectedNetwork.chaincodes[selectedChaincode].ccBaseName = ccName;

      setSelectedNetwork({ ...selectedNetwork });
    }
  };

  const handleSelectTemplateDefinition = (templateDefinition: {
    value: ITemplate;
    label: string;
  }) => {
    if (selectedNetwork?.chaincodes?.[selectedChaincode]) {
      selectedNetwork.chaincodes[selectedChaincode].templateDefinition =
        templateDefinition?.value;

      setSelectedNetwork({ ...selectedNetwork });
    }
  };

  const { operations }: { operations: Operation[] } = useSelector(
    (state: StoreState) => state.appStatusState,
  );

  const cantRunOperation = useCallback(() => !canRunOperation(operations), [
    operations,
  ]);

  const canShowForm = useMemo(() => selectedChannel && selectedChaincode, [
    selectedChaincode,
    selectedChannel,
  ]);

  const { upgradeCCState } = useOperation();

  if (!selectedNetwork || !selectedChannel) {
    console.log('AQUI!: ', selectedNetwork);
    return <SelectNetwork />;
  }

  return (
    <Box
      padding="2rem"
      display="flex"
      margin="0 auto"
      flexDirection="column"
      maxWidth="800px"
    >
      <Typography variant="overline" style={{ fontSize: '25px' }}>
        <Trans>title.chaincodes.upgradeChaincode</Trans>
      </Typography>

      {canShowForm ? (
        <></>
      ) : (
        <Typography variant="overline" style={{ fontSize: '20px' }}>
          Select a chaincode
        </Typography>
      )}

      <UpgradeCCForm />

      {canShowForm && (
        <>
          <CCTypes
            // channelIndex={0}
            chaincode={
              ({
                ...selectedNetwork?.chaincodes?.[selectedChaincode],
                chaincodeName: selectedChaincode,
              } as unknown) as IChaincodes
            }
            orgs={orgs}
            currentChaincode={selectedChaincode}
            handleCCTypeChange={handleCCTypeChange}
            file={ccFile}
            handleFileUpload={handleFileUpload}
            operation="upgradechaincode"
            handleSelectTemplate={handleSelectTemplate}
            handleSelectFromCloud={handleSelectFromCloud}
            handleSelectTemplateDefinition={handleSelectTemplateDefinition}
          />
          <CustomTimeoutModal
            open={customTimeoutModalOpened}
            setOpen={setCustomTimeoutModalOpened}
            onSave={(v) => setGrpcTimeout(v.grpcTimeout)}
          />
        </>
      )}

      <h3 style={{ margin: '24px 0' }}>
        <Trans>common.words.endorsement</Trans>
      </h3>

      <DefineEndorsement
        onFinish={(value: IEndorsement) => {
          setEndorsement(value.endorsement);
          setEndorsementGUI(value.endorsementGUI);
        }}
        endorsement={endorsement as IEndorsement}
        disabled={sending}
      />

      <div style={{ marginBottom: '24px' }}>
        <h3 style={{ margin: '24px 0' }}>
          <Trans>common.words.advanced</Trans>
        </h3>

        <Button
          variant="outlined"
          disabled={sending}
          onClick={() => setCustomTimeoutModalOpened(true)}
        >
          <Trans>asset.network.deploy.timeoutSettings</Trans>
        </Button>
      </div>
      <div style={{ marginTop: '20px' }}>
        {sending ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ gap: '2rem' }}
          >
            <LoadingScreen
              content={t('asset.chaincodes.upgradeChaincode.loadingMessage')}
            />

            <Button
              fullWidth
              color="secondary"
              variant="contained"
              style={{ display: 'block' }}
              onClick={() => cancelRequest()}
            >
              <Trans>button.cancel</Trans>
            </Button>
            <OperationStateCard taskOperation={upgradeCCState} />
          </Box>
        ) : (
          <Tooltip
            canShow={cantRunOperation()}
            message={t('common.messages.onlyOneOperation')}
          >
            <Button
              fullWidth
              color="primary"
              variant="contained"
              disabled={sending || cantRunOperation() || !canShowForm}
              style={{ display: 'block', pointerEvents: 'all' }}
              onClick={() => {
                if (!sending || !cantRunOperation()) sendData();
              }}
            >
              <Trans>button.send</Trans>
            </Button>
          </Tooltip>
        )}
      </div>
    </Box>
  );
};

export default UpgradeChaincode;
