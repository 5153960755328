import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ChangeEventHandler,
} from 'react';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { Clear } from '@material-ui/icons';
import { canShowCards } from '../utils';
import { useDeployForm } from '../../../Hooks/deploy';
import { NetDefsStepContainer } from '../styles';

import { changeMountedComponents } from '../../../store/InitialTour';

import CustomTooltip from '../../../AppComponents/Tooltip';
import canRunOperation from '../../../utils/canRunOperation';
import DefineEndorsement from '../../../AppComponents/Endorsement/DefineEndorsement';

import { StoreState } from '../../../store/types';
import { Operation } from '../../../store/AppStatus';
import { IEndorsementEvent } from '../../../AppComponents/Endorsement/types';
import { AvailableNodePeers } from '../../../AppComponents/AvailableNodePeers';
import { StyledTab, StyledTabs } from '../../../AppComponents/StyledTabs';
import { CCTypes } from '../../../AppComponents/CCTypes';
import { ChaincodeNameInput } from '../../../AppComponents/ChaincodeNameInput';
import { ITemplate } from '../../../store/TemplateCC';

interface IOption {
  name: string;
  option: string;
}

const DefineChaincodes: React.FC<{
  sendData: () => void;
  handleStepChange: (type?: string) => void;
}> = ({ sendData, handleStepChange }) => {
  const { t } = useTranslation();
  const deployStart = useDeployForm();
  const [channelIndex, setChannelIndex] = useState(0);

  const {
    orgs,
    setOrgs,
    channels,
    setChannels,
    setChaincodeIndex,
    started,
    template,
    activeCCOption,
    chaincodeIndex,
    setEndorsement,
    setActiveCCOption,
    setEndorsementGUI,
    updateCCTypeControlVars,
    setCustomTimeoutModalOpened,
  } = deployStart;

  const dispatch = useDispatch();
  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);

  const { chapterToChange } = useSelector(
    (state: StoreState) => state.initialTourState,
  );

  const { operations }: { operations: Operation[] } = useSelector(
    (state: StoreState) => state.appStatusState,
  );

  const handleChaincodeOptChange = useCallback(
    (option: string) => {
      setActiveCCOption(option);
      updateCCTypeControlVars(option);
    },
    [setActiveCCOption, updateCCTypeControlVars],
  );

  const cantRunOperation = useCallback(() => !canRunOperation(operations), [
    operations,
  ]);

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode) dispatch(changeMountedComponents('deploySecondStep'));
    // eslint-disable-next-line
  }, [chapterToChange]);

  useEffect(() => {
    if (activeCCOption) handleChaincodeOptChange(activeCCOption);
  }, [activeCCOption, handleChaincodeOptChange]);

  useEffect(() => {
    if (template?.assets.some((asset) => asset.privateData === true)) {
      setEndorsement(null);
    }
  }, [template, setEndorsement]);

  const chaincode = useMemo(
    () => channels?.[channelIndex].chaincodes?.[chaincodeIndex],
    [chaincodeIndex, channelIndex, channels],
  );

  const addChaincode = useCallback(() => {
    if (channelIndex === undefined) return;
    const id = `${channelIndex}${channels[channelIndex].chaincodes.length + 1}`;
    const name = '';
    const newChaincode = {
      id,
      chaincodeName: name,
      ccType: 'form' as ChaincodeType,
      chaincodeFile: null,
      tarName: '',
    };

    channels[channelIndex].chaincodes.push(newChaincode);

    setChannels([...channels]);
  }, [channels, channelIndex, setChannels]);

  const removeChaincode = useCallback(
    (chaincodeName) => {
      if (channelIndex === undefined) return;

      channels[channelIndex].chaincodes = channels[
        channelIndex
      ].chaincodes.filter((c) => c.chaincodeName !== chaincodeName);

      setChannels([...channels]);
    },
    [channelIndex, channels, setChannels],
  );

  const renderTabLabel = useCallback(
    (channelName: string) => (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>{channelName || 'unnamedChannel'}</Typography>
      </Box>
    ),
    [],
  );

  const renderChaincodeTabLabel = useCallback(
    (chaincodeName: string) => (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>{chaincodeName || 'unnamedChaincode'}</Typography>
        {channels[channelIndex].chaincodes.length > 1 && (
          <Tooltip
            disableHoverListener={
              channels[channelIndex].chaincodes.length === 1
            }
            title={<Trans>asset.network.deploy.deleteOrgTooltip</Trans>}
          >
            <Clear
              onDoubleClick={() => {
                if (!started) {
                  removeChaincode(chaincodeName);
                  if (chaincodeIndex >= 1)
                    setChaincodeIndex(chaincodeIndex - 1);
                }
              }}
              style={{ fontSize: '15px', opacity: '0.5', marginLeft: '15px' }}
            />
          </Tooltip>
        )}
      </Box>
    ),
    [
      chaincodeIndex,
      channelIndex,
      channels,
      removeChaincode,
      setChaincodeIndex,
      started,
    ],
  );

  const chaincodesName = useMemo(
    () => channels[channelIndex]?.chaincodes || [],
    [channelIndex, channels],
  );

  const onChaincodeNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { value } = event.target;

      orgs.forEach((org) => {
        org.ccapi.forEach((ccapi) => {
          if (ccapi?.chaincodeName === chaincode?.chaincodeName) {
            // eslint-disable-next-line no-param-reassign
            ccapi.chaincodeName = value;
          }
        });
      });

      channels[channelIndex].chaincodes[chaincodeIndex] = {
        ...channels[channelIndex].chaincodes[chaincodeIndex],
        chaincodeName: value,
      };

      setChannels([...channels]);
      setOrgs([...orgs]);
    },
    [
      chaincode,
      chaincodeIndex,
      channelIndex,
      channels,
      orgs,
      setChannels,
      setOrgs,
    ],
  );

  const handleCCTypeChange = useCallback(
    (opt: ChaincodeType) => {
      if (!chaincode || channelIndex === -1 || chaincodeIndex === -1) return;
      chaincode.ccType = opt;

      if (opt === 'template' || opt === 'aws-bucket') {
        orgs.forEach((org) => {
          org.ccapi.forEach((ccapi) => {
            if (ccapi.chaincodeName === chaincode?.chaincodeName) {
              // eslint-disable-next-line no-param-reassign
              ccapi.chaincodeName = chaincode?.ccBaseName?.includes('/')
                ? chaincode?.ccBaseName?.split('/')?.[1]
                : (chaincode.ccBaseName as string);
            }
          });
        });
        channels[channelIndex].chaincodes[chaincodeIndex] = {
          ...channels[channelIndex].chaincodes[chaincodeIndex],
          chaincodeName: chaincode?.ccBaseName as string,
          ccBaseName: chaincode?.ccBaseName,
        };

        setOrgs([...orgs]);
      }

      setChannels([...channels]);
    },
    [
      chaincode,
      channelIndex,
      chaincodeIndex,
      setChannels,
      channels,
      orgs,
      setOrgs,
    ],
  );

  const handleFileUpload = useCallback(
    (files: FileList | null) => {
      if (files && files.length > 0) {
        channels[channelIndex].chaincodes[chaincodeIndex].chaincodeFile =
          files?.[0];
        channels[channelIndex].chaincodes[chaincodeIndex].tarName =
          files[0].name;
      }

      setChannels([...channels]);
    },
    [chaincodeIndex, channelIndex, channels, setChannels],
  );

  const handleSelectTemplate = (ccName: string) => {
    if (
      channels[channelIndex].chaincodes[chaincodeIndex]?.ccType === 'template'
    ) {
      orgs.forEach((org) => {
        org.ccapi.forEach((ccapi) => {
          if (ccapi.chaincodeName === chaincode?.chaincodeName) {
            // eslint-disable-next-line no-param-reassign
            ccapi.chaincodeName = ccName;
          }
        });
      });
      channels[channelIndex].chaincodes[chaincodeIndex].ccBaseName = ccName;
    }

    setChannels([...channels]);
    setOrgs([...orgs]);
  };

  const handleSelectFromCloud = (ccName: string) => {
    if (
      channels[channelIndex].chaincodes[chaincodeIndex]?.ccType === 'aws-bucket'
    ) {
      orgs.forEach((org) => {
        org.ccapi.forEach((ccapi) => {
          if (ccapi.chaincodeName === chaincode?.chaincodeName) {
            // eslint-disable-next-line no-param-reassign
            ccapi.chaincodeName = ccName;
          }
        });
      });
      channels[channelIndex].chaincodes[chaincodeIndex].ccBaseName = ccName;

      // handle user clloud options to get only ccName and remove userID
      channels[channelIndex].chaincodes[
        chaincodeIndex
      ].chaincodeName = ccName.includes('/') ? ccName?.split('/')?.[1] : ccName;
    }
    setOrgs([...orgs]);
    setChannels([...channels]);
  };

  const handleSelectTemplateDefinition = (templateDefinition: {
    value: ITemplate;
    label: string;
  }) => {
    channels[channelIndex].chaincodes[chaincodeIndex].templateDefinition =
      templateDefinition?.value;
    if (
      channels[channelIndex].chaincodes[chaincodeIndex]?.ccType === 'template'
    ) {
      orgs.forEach((org) => {
        org.ccapi.forEach((ccapi) => {
          if (ccapi.chaincodeName === chaincode?.chaincodeName) {
            // handle user templates to get only ccName and remove userID
            // eslint-disable-next-line no-param-reassign
            ccapi.chaincodeName = templateDefinition?.label?.includes('/')
              ? templateDefinition?.label?.split('/')?.[1]
              : templateDefinition?.label;
          }
        });
      });
      channels[channelIndex].chaincodes[chaincodeIndex] = {
        ...channels[channelIndex].chaincodes[chaincodeIndex],
        // handle user templates to get only ccName and remove userID
        chaincodeName: templateDefinition?.label?.includes('/')
          ? templateDefinition?.label?.split('/')?.[1]
          : templateDefinition?.label,
      };
    }
    setOrgs([...orgs]);
    setChannels([...channels]);
  };

  return (
    <NetDefsStepContainer hasCards={canShowCards(deployStart.orgs)}>
      <Typography variant="overline" style={{ fontSize: '25px' }}>
        <Trans>asset.network.deploy.step3</Trans>
      </Typography>
      <AppBar
        style={{
          backgroundColor: 'var(--primary)',
          marginBottom: '1rem',
          marginTop: '3rem',
        }}
        position="static"
      >
        <StyledTabs
          value={channelIndex}
          scrollButtons="on"
          variant="scrollable"
          onChange={(_: any, v: any) => {
            setChaincodeIndex(0);
            setChannelIndex(v);
          }}
        >
          {channels.map(({ channelName }, index) => (
            <StyledTab key={index} label={renderTabLabel(channelName)} />
          ))}
        </StyledTabs>
      </AppBar>
      <AppBar
        style={{ backgroundColor: 'var(--primary)', marginBottom: '1rem' }}
        position="static"
      >
        <StyledTabs
          value={chaincodeIndex}
          scrollButtons="on"
          variant="scrollable"
          onChange={(_: any, v: any) => setChaincodeIndex(v)}
        >
          {chaincodesName.map(({ chaincodeName }, index) => (
            <StyledTab
              key={index}
              label={renderChaincodeTabLabel(chaincodeName)}
            />
          ))}
        </StyledTabs>
        <Button
          variant="outlined"
          onClick={addChaincode}
          className="add-new-org-tab"
          disabled={deployStart.started}
          style={{ color: 'var(--white)' }}
        >
          <Trans>button.addChaincode</Trans>
        </Button>
      </AppBar>

      <ChaincodeNameInput
        channelIndex={channelIndex}
        onNameChange={onChaincodeNameChange}
        value={chaincode?.chaincodeName}
        disabled={
          channels?.[channelIndex]?.chaincodes?.[chaincodeIndex]?.ccType ===
            'template' ||
          channels?.[channelIndex]?.chaincodes?.[chaincodeIndex]?.ccType ===
            'aws-bucket'
        }
      />
      <CCTypes
        // channelIndex={channelIndex}
        chaincode={chaincode}
        orgs={orgs}
        currentChaincode={chaincode?.chaincodeName}
        file={chaincode?.chaincodeFile}
        handleCCTypeChange={handleCCTypeChange}
        handleFileUpload={handleFileUpload}
        operation="startnetwork"
        handleSelectTemplate={handleSelectTemplate}
        handleSelectFromCloud={handleSelectFromCloud}
        handleSelectTemplateDefinition={handleSelectTemplateDefinition}
      />

      <Divider />
      <div style={{ display: 'flex', gap: '2rem', marginTop: '3rem' }}>
        <div
          style={{ width: 'fit-content', marginLeft: '20px' }}
          className="define-endorsement"
        >
          <Typography variant="h6" style={{ marginBottom: '10px' }}>
            <Trans>common.words.endorsement</Trans>
          </Typography>

          <DefineEndorsement
            endorsement={deployStart.endorsement as IEndorsement}
            onFinish={(value: IEndorsementEvent) => {
              setEndorsement(value.endorsement);
              setEndorsementGUI(value.endorsementGUI);
            }}
            disabled={template?.assets.some(
              (asset) => asset.privateData === true,
            )}
          />

          <div>
            <Typography variant="h6" style={{ margin: '16px 0' }}>
              <Trans>common.words.advanced</Trans>
            </Typography>

            <Button
              variant="outlined"
              disabled={started}
              onClick={() => setCustomTimeoutModalOpened(true)}
            >
              <Trans>asset.network.deploy.timeoutSettings</Trans>
            </Button>
          </div>
        </div>
        <AvailableNodePeers
          channelIndex={channelIndex}
          currentChaincode={chaincode}
        />
      </div>

      <div style={{ display: 'flex' }}>
        <Button
          color="secondary"
          variant="contained"
          style={{ width: '45%', margin: '50px auto' }}
          onClick={() => handleStepChange('back')}
        >
          <Trans>button.back</Trans>
        </Button>

        <CustomTooltip
          canShow={cantRunOperation()}
          message={t('common.messages.onlyOneOperation')}
        >
          <Button
            color="primary"
            variant="contained"
            className="deploy-start-btn"
            disabled={deployStart.started || cantRunOperation()}
            style={{ width: '45%', margin: '50px auto', pointerEvents: 'all' }}
            onClick={() => {
              if (!deployStart.started || !cantRunOperation()) sendData();
            }}
          >
            <Trans>button.start</Trans>
          </Button>
        </CustomTooltip>
      </div>
    </NetDefsStepContainer>
  );
};

export default DefineChaincodes;
