import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SocketHandler } from '../Hooks/socketHandler';

export type OperationContextType = {
  deployState: Task;
  setDeployState: React.Dispatch<React.SetStateAction<Task>>;
  addPeerState: Task;
  setAddPeerState: React.Dispatch<React.SetStateAction<Task>>;
  upgradeCCState: Task;
  setUpgradeCCState: React.Dispatch<React.SetStateAction<Task>>;
  setupMachinesState: Task;
  setSetupMachinesState: React.Dispatch<React.SetStateAction<Task>>;
  addCCAPIState: Task;
  addOrgState: Task;
  setAddOrgState: React.Dispatch<React.SetStateAction<Task>>;
  addChannelState: Task;
  setAddChannelState: React.Dispatch<React.SetStateAction<Task>>;
  clearOperationsState: () => void;
  addChaincodeState: Task;
  getClientCertState: Task;
  upgradeAPIState: Task;
  setUpgradeAPIState: React.Dispatch<React.SetStateAction<Task>>;
};

export const OperationContextDefaultValues: OperationContextType = {
  deployState: {} as Task,
  setDeployState: () => ({}),
  addPeerState: {} as Task,
  setAddPeerState: () => ({}),
  upgradeCCState: {} as Task,
  setUpgradeCCState: () => ({}),
  setupMachinesState: {} as Task,
  setSetupMachinesState: () => ({}),
  addCCAPIState: {} as Task,
  addOrgState: {} as Task,
  setAddOrgState: () => ({}),
  addChannelState: {} as Task,
  setAddChannelState: () => ({}),
  clearOperationsState: () => ({}),
  addChaincodeState: {} as Task,
  getClientCertState: {} as Task,
  upgradeAPIState: {} as Task,
  setUpgradeAPIState: () => ({}),
};

export const OperationContext = createContext<OperationContextType>(
  OperationContextDefaultValues,
);

export const OperationProvider: React.FC = ({ children }) => {
  const [setupMachinesState, setSetupMachinesState] = useState<Task>(
    {} as Task,
  );
  const [deployState, setDeployState] = useState<Task>({} as Task);
  const [addPeerState, setAddPeerState] = useState<Task>({} as Task);
  const [upgradeCCState, setUpgradeCCState] = useState<Task>({} as Task);
  const [addCCAPIState, setAddCCAPIState] = useState<Task>({} as Task);
  const [addOrgState, setAddOrgState] = useState<Task>({} as Task);
  const [addChannelState, setAddChannelState] = useState<Task>({} as Task);
  const [addChaincodeState, setAddChaincodeState] = useState<Task>({} as Task);
  const [getClientCertState, setGetClientCertState] = useState<Task>(
    {} as Task,
  );
  const [upgradeAPIState, setUpgradeAPIState] = useState<Task>({} as Task);

  const clearOperationsState = useCallback(() => {
    setSetupMachinesState({} as Task);
    setDeployState({} as Task);
    setAddPeerState({} as Task);
    setUpgradeCCState({} as Task);
    setAddCCAPIState({} as Task);
    setAddOrgState({} as Task);
    setAddChannelState({} as Task);
    setAddChaincodeState({} as Task);
    setGetClientCertState({} as Task);
    setUpgradeAPIState({} as Task);
  }, []);

  const { lastJsonMessage } = SocketHandler();

  useEffect(() => {
    if (!lastJsonMessage) {
      // console.log('clear state', lastJsonMessage);
      // this may cause infinite loop
      // setDeployState({} as Task);
      return;
    }

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line
      console.log('Message: ', lastJsonMessage);
    }

    switch (Object.keys(lastJsonMessage)?.[0]) {
      case 'StartFromScratch': {
        setDeployState(lastJsonMessage?.StartFromScratch);
        break;
      }
      case 'UpgradeChaincode': {
        setUpgradeCCState(lastJsonMessage?.UpgradeChaincode);
        break;
      }
      case 'SetupMachines': {
        setSetupMachinesState(lastJsonMessage?.SetupMachines);
        break;
      }
      case 'AddPeer': {
        setAddPeerState(lastJsonMessage?.AddPeer);
        break;
      }
      case 'AddCCApi': {
        setAddCCAPIState(lastJsonMessage?.AddCCApi);
        break;
      }
      case 'AddOrg': {
        setAddOrgState(lastJsonMessage?.AddOrg);
        break;
      }
      case 'AddChannel': {
        setAddChannelState(lastJsonMessage?.AddChannel);
        break;
      }
      case 'AddCCToChannel': {
        setAddChaincodeState(lastJsonMessage?.AddCCToChannel);
        break;
      }
      case 'GetClientCert': {
        setGetClientCertState(lastJsonMessage?.GetClientCert);
        break;
      }
      case 'UpdateCCApi': {
        setUpgradeAPIState(lastJsonMessage?.UpdateCCApi);
        break;
      }
      default:
        console.log('none');
        break;
    }
  }, [
    lastJsonMessage,
    deployState,
    addPeerState,
    upgradeCCState,
    setAddPeerState,
    setDeployState,
    setUpgradeCCState,
    setupMachinesState,
    setSetupMachinesState,
    upgradeAPIState,
    setUpgradeAPIState,
  ]);

  const values = useMemo(
    () => ({
      deployState,
      setDeployState,
      addPeerState,
      setAddPeerState,
      upgradeCCState,
      setUpgradeCCState,
      setupMachinesState,
      setSetupMachinesState,
      addCCAPIState,
      addOrgState,
      setAddOrgState,
      clearOperationsState,
      addChannelState,
      setAddChannelState,
      addChaincodeState,
      getClientCertState,
      upgradeAPIState,
      setUpgradeAPIState,
    }),
    [
      deployState,
      addPeerState,
      upgradeCCState,
      setupMachinesState,
      addCCAPIState,
      addOrgState,
      clearOperationsState,
      addChannelState,
      addChaincodeState,
      getClientCertState,
      upgradeAPIState,
    ],
  );

  return (
    <OperationContext.Provider value={values}>
      {children}
    </OperationContext.Provider>
  );
};

export function useOperation() {
  const context = useContext(OperationContext);

  if (!context) {
    throw new Error('useOperation must be used within a OperationProvider');
  }

  return context;
}
