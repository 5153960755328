import React, { useEffect, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  AppBar,
  Tooltip,
  Card,
  CardContent,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { canShowCards } from '../utils';
import { useDeployForm } from '../../../Hooks/deploy';
import { NetDefsStepContainer } from '../styles';

import { changeMountedComponents } from '../../../store/InitialTour';

import canRunOperation from '../../../utils/canRunOperation';
import NetworkDefinitionsTab from './Contents/NetworkDefinitions';
import DefineEndorsement from '../../../AppComponents/Endorsement/DefineEndorsement';

import { StoreState } from '../../../store/types';
import { Operation } from '../../../store/AppStatus';
import { IEndorsementEvent } from '../../../AppComponents/Endorsement/types';
import { StyledTab, StyledTabs } from '../../../AppComponents/StyledTabs';
import { default as CustomTooltip } from '../../../AppComponents/Tooltip';
import { ChannelForm } from '../../../Forms/ChannelForm';
import { IDialogInfo, openDialog } from '../../../store/Dialog';

interface IOption {
  name: string;
  option: string;
}

export const DefineChannels: React.FC<{
  handleStepChange: (type?: string) => void;
}> = ({ handleStepChange }) => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const {
    channels,
    setChannels,
    started,
    template,
    activeCCOption,
    setEndorsement,
    setActiveCCOption,
    setEndorsementGUI,
    updateCCTypeControlVars,
    setCustomTimeoutModalOpened,
    orgs,
  } = useDeployForm();

  const addChannel = useCallback(() => {
    const id = channels.length + 1;
    const name = '';

    channels.push({
      channelName: name,
      peers: {},
      chaincodes: [
        {
          id: `${id}1`,
          chaincodeFile: null,
          chaincodeName: '',
          tarName: '',
          ccType: 'template',
        },
      ],
    });

    setChannels(channels);

    setValue(channels.length - 1);
  }, [channels, setChannels]);

  const removeChannel = useCallback(
    (channelName: string) => {
      if (channels.length === 1) return;

      setChannels(
        channels.filter((channel) => channel.channelName !== channelName),
      );
    },
    [channels, setChannels],
  );

  const dispatch = useDispatch();
  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);

  const { chapterToChange } = useSelector(
    (state: StoreState) => state.initialTourState,
  );

  const { operations }: { operations: Operation[] } = useSelector(
    (state: StoreState) => state.appStatusState,
  );

  const handleChaincodeOptChange = useCallback(
    (option: string) => {
      setActiveCCOption(option);
      updateCCTypeControlVars(option);
    },
    [setActiveCCOption, updateCCTypeControlVars],
  );

  const cantRunOperation = useCallback(() => !canRunOperation(operations), [
    operations,
  ]);

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode) dispatch(changeMountedComponents('deploySecondStep'));
    // eslint-disable-next-line
  }, [chapterToChange]);

  useEffect(() => {
    if (activeCCOption) handleChaincodeOptChange(activeCCOption);
  }, [activeCCOption, handleChaincodeOptChange]);

  useEffect(() => {
    if (template?.assets.some((asset) => asset.privateData === true)) {
      setEndorsement(null);
    }
  }, [template, setEndorsement]);

  // ---------------------
  const renderTabLabel = useCallback(
    (channelName: string) => (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>{channelName || 'unnamedChannel'}</Typography>
        <Tooltip
          disableHoverListener={channels.length === 1}
          title={<Trans>asset.network.deploy.deleteOrgTooltip</Trans>}
        >
          <Clear
            onDoubleClick={() => {
              if (!started) {
                removeChannel(channelName);
                if (value >= 1) setValue(value - 1);
              }
            }}
            style={{ fontSize: '15px', opacity: '0.5', marginLeft: '15px' }}
          />
        </Tooltip>
      </Box>
    ),
    [channels, removeChannel, started, value],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="1024px"
      margin="0 auto"
      marginTop="2rem"
    >
      <Typography variant="overline" style={{ fontSize: '25px' }}>
        Channels
      </Typography>
      <AppBar style={{ backgroundColor: 'var(--primary)' }} position="static">
        <StyledTabs
          value={value}
          scrollButtons="on"
          variant="scrollable"
          onChange={(_: any, v: any) => setValue(v)}
        >
          {channels.map(({ channelName }, index) => (
            <StyledTab key={index} label={renderTabLabel(channelName)} />
          ))}
        </StyledTabs>

        <Button
          variant="outlined"
          onClick={addChannel}
          className="add-new-org-tab"
          disabled={started}
          style={{ color: 'var(--white)' }}
        >
          <Trans>button.addChannel</Trans>
        </Button>
      </AppBar>

      <Card style={{ background: 'linear-gradient(45deg, whitesmoke, snow)' }}>
        <CardContent style={{ display: 'flex', margin: '20px' }}>
          {channels.map((channel, index) => {
            return (
              <div
                key={index}
                style={{
                  width: '100%',
                  display:
                    value === channels.indexOf(channel) ? 'block' : 'none',
                }}
              >
                <ChannelForm channelIndex={index} />
              </div>
            );
          })}
        </CardContent>
      </Card>

      <NetDefsStepContainer hasCards={canShowCards(orgs)}>
        <div style={{ display: 'flex', width: '100%' }}>
          <Button
            color="secondary"
            variant="contained"
            style={{ width: '45%', margin: '50px auto' }}
            onClick={() => handleStepChange('back')}
          >
            <Trans>button.back</Trans>
          </Button>

          <Button
            className="deploy-next-step-btn"
            fullWidth
            color="primary"
            variant="contained"
            style={{ width: '45%', margin: '50px auto', pointerEvents: 'all' }}
            onClick={() => {
              handleStepChange();
            }}
          >
            <Trans>button.nextStep</Trans>
          </Button>
        </div>
      </NetDefsStepContainer>
    </Box>
  );
};
